export const NO_AVERAGE = {
  motion_detection: true,
  door_open_close: true,
  panic_button: true,
};

export const INPROGRESS_FEATURE = {
  motion_detection: false,
  panic_button: false,
};

export const ENTITY_KEY_MAP = {
  light_detection: 'light',
  light: 'light',
  battery: 'batteryVoltage',
  batteryVoltage: 'battery',
  temperature: 'temperature',
  pressure: 'pressure',
  humidity: 'humidity',
  door_open_close: 'currentDoorState',
  panic_button: 'currentPanicState',
  motion_detection: 'motionAlert',
  temp_environment: 'temperature'
};

export const TIME_IN_MILLI = {
  sevenDays: 6.048e8,
  oneDay: 8.64e7,
  oneHour: 3.6e6,
};

export const SNAPSHOT_HIGH_LOW_WORD = {
  hour: "hour's",
  day: "today's",
  week: '7 day',
};

export const NAME_MAP = {
  Battery: 'battery',
  temperature: 'temp_environment',
  Temperature: 'temp_environment',
  'Impact Detection': 'motionDetection',
  Pressure: 'pressure',
  'Light Detection': 'light',
  'Door Open/Close': 'doorOpen',
  Humidity: 'humidity',
  'Multi-Function Button': 'panicButton',
  'Co2': 'eco2',
  'PCB Temp': 'temp_pcb',
  'VOC': 'voc',
  'IAQ': 'iaq',
  'Water': 'water',
  openDoor: 'openDoor'
};

export const HARDWARE_NAME_MAP = {
  temp_environment: 'temp_environment',
  temp_pcb: 'temp_pcb',
  water: 'water',
  iaq: 'iaq',
  voc: 'voc',
  eco2: 'eco2',
  motion: 'motion_detection',
  humidity: 'humidity',
  door: 'door_open_close',
  pressure: 'pressure',
  panic: 'panic_button',
  light: 'light_detection'
}

export const SENSOR_NAME_REGEX = /^([.a-zA-Z0-9_ -()[\]]+)$/;
