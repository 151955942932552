import clsx from 'clsx';
import { A, navigate } from 'hookrouter';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';

// Header imports
import HeaderAccount from './HeaderAccount';
import HeaderAlerts from './HeaderAlerts';
import HeaderOnboard from './HeaderOnboard';
import HeaderProfile from './HeaderProfile';

const drawerWidth = 240;

export default function Header(props) {
  const log = window.log('Header');
  const theme = useTheme();

  const {
    drawer,
    setOpen,
    darkMode,
    setDrawer,
    setDarkMode,
    setTimeFormat,
    setThemeLoaded,
    setPresetThemeChoice,
    setFaviconImageLocation,
    handlePrimaryColorChange,
    setPlatformImageLocation,
  } = props;

  const {
    snack,
    appState,
    alertCount,
    setAppState,
    featureCount,
    getAlertCount,
  } = useContext(AppContext);

  const [platformAlias, setPlatformAlias] = useState('');

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  let windowSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let isCancelled = false;

    if (appState.auth.loggedIn !== false) {
      if (!isCancelled) {
        getAlertCount();
        fetchTheme();
      }
    }

    // appState.auth.loggedIn ? setDrawer(true) : setDrawer(false);
    if (appState.auth.loggedIn && windowSizeSmall === true) {
      setDrawer(false);
    }
    if (appState.auth.loggedIn && windowSizeSmall === false) {
      setDrawer(false);
    }
    // log("UE 1");
    return () => {
      isCancelled = true;
    };
  }, [appState.auth.loggedIn]);

  const returnThemeToDefault = () => {
    setPlatformAlias('');
    setPlatformImageLocation('');
    setFaviconImageLocation('');
    setPresetThemeChoice('0');
    setTimeFormat('12hr');
    handlePrimaryColorChange({
      hex: '#08b3ff',
    });
    setDarkMode(false);
  };

  log('isSmallDevice: ', isSmallDevice);

  const fetchTheme = async () => {
    try {
      // log("inside of fetchTheme " + appState.auth.loggedIn);
      if (appState.auth.loggedIn) {
        log('About to run a fetch');
        //TODO: Add try catch block
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/company/get',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: appState.auth.token,
            },
          },
        );
        // log(process.env.REACT_APP_API_URL);
        log('Looking at company login reponse ', response);
        // window.response = response;
        // window.jsonTest = await response.json();
        // log("parsed json")

        const json = await response.json();
        // log(json);

        if (json.success) {
          if (!json.data.company[0].is_enabled) {
            setAppState({
              auth: {
                loggedIn: false,
              },
            });
            snack('Account Is Disabled', 'error');

            navigate('/');
          } else {
            if (json.success) {
              const themePreferences = json.data.company[0].theme_preferences;

              if (themePreferences) {
                // Company specfic theme preferences
                if (themePreferences.presetThemeChoice) {
                  setPresetThemeChoice(themePreferences.presetThemeChoice);
                }
                if (themePreferences.platformAlias) {
                  setPlatformAlias(themePreferences.platformAlias);
                }
                if (themePreferences.platformImageLocation) {
                  setPlatformImageLocation(
                    themePreferences.platformImageLocation,
                  );
                }
                if (themePreferences.faviconImageLocation) {
                  setFaviconImageLocation(
                    themePreferences.faviconImageLocation,
                  );
                }
                // User specfic settings
                if (appState?.userSettings?.timeFormat) {
                  setTimeFormat(appState.userSettings.timeFormat);
                }

                if (appState?.userSettings?.primaryColor) {
                  handlePrimaryColorChange({
                    hex: appState.userSettings.primaryColor,
                  });
                }
                if (appState?.userSettings?.darkMode) {
                  setDarkMode(appState.userSettings.darkMode);
                }
              } else {
                returnThemeToDefault();
              }
            }
            setThemeLoaded(true);
          }
        } else {
          log('Json failure');
          if (json.errors.length) {
            for (const error of json.errors) {
              if (error.type === 'token') {
                snack('Login Failure', 'error');
                setAppState({
                  auth: {
                    loggedIn: false,
                  },
                });
                navigate('/');
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      log('------: fetchTheme -> err', err);
      snack('Network Error', 'error');
    }
  };

  const useStyles = makeStyles(theme => ({
    appBar: {
      width: `calc(100% - 65px)`,
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up('sm')]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: `calc(100%)`,
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,

      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },

      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    accountIcon: {
      marginLeft: 'auto',
      marginRight: 5,
      color: theme.palette.primary.contrastText,
    },
    avatarMenuImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    text: {
      color: darkMode ? theme.palette.text.primary : '#FFF',
      fontSize: 14,
    },
    navbarMenu: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    divider: {
      backgroundColor: '#ffffff',
      width: '1px',
      height: '1.75rem',
      marginLeft: 10,
      marginRight: 10,
    },
  }));
  const classes = useStyles();

  const logout = async () => {
    // clear local storage token
    await setAppState({
      auth: { loggedIn: false },
    });
    // close drawer
    handleDrawerClose();
    // set default theme settings
    returnThemeToDefault();
    setThemeLoaded(false);
    // redirect to root (will be log in page since token is now gone)
    navigate('/');
  };

  const handleDrawerClose = () => {
    setDrawer(false);
    setOpen(false);
  };

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawer,
      })}
      // position={!isDownXS ? "sticky" : 'fixed'}
      style={{
        display: appState.auth.loggedIn === false ? 'none' : '',
        transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        // width: isDownSM ? "100%" : "calc(100% - 64px)",
      }}
    >
      <Toolbar style={{ minHeight: 55 }} variant='dense'>
        {!drawer ? (
          <IconButton
            className={clsx(classes.menuButton, drawer)}
            disabled={!appState.auth.loggedIn}
            onClick={() => setDrawer(true)}
            edge='start'
            color='inherit'
            aria-label='menu'
            id='drawer_open'
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton
            className={clsx(classes.menuButton, drawer)}
            disabled={!appState.auth.loggedIn}
            onClick={handleDrawerClose}
            edge='start'
            color='inherit'
            aria-label='menu'
            id='drawer_close'
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        <A className={classes.navbarMenu} href='/'>
          <Typography variant='subtitle2' className={classes.text}>
            {platformAlias ? `${platformAlias}` : 'MiSensors'}
          </Typography>
        </A>
        {appState.auth.loggedIn && <HeaderAccount parentClasses={classes} />}
        {appState.auth.loggedIn &&
          featureCount > 0 &&
          appState.auth.userInfo.perm !== 1 &&
          appState.auth.userInfo.perm !== 53 && (
            <HeaderOnboard dividerStyle={classes.divider} />
          )}
        {appState.auth.loggedIn && alertCount > 0 && (
          <HeaderAlerts
            dividerStyle={classes.divider}
            isSmallDevice={isSmallDevice}
          />
        )}
        {appState.auth.loggedIn && (
          <HeaderProfile logout={logout} dividerStyle={classes.divider} />
        )}
      </Toolbar>
    </AppBar>
  );
}