import React from "react";
import { SvgIcon } from "@material-ui/core";

const IaqSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="323.522"
      height="132.213"
      viewBox="0 0 323.522 132.213"
      {...props}
    >
        <g id="Group_1962" data-name="Group 1962" transform="translate(236.863 31.637)">
            <path id="Path_640" data-name="Path 640" d="M350.441,106.991a46.669,46.669,0,0,1-2.614,16.676,20.41,20.41,0,0,1-7.728,10.146L350.675,149.5H338.328l-7.946-12.9h-.588q-9.986,0-15.337-7.647t-5.348-22.041q0-14.279,5.348-21.888t15.418-7.609q9.948,0,15.256,7.647T350.441,106.991Zm-30.4,0q0,9.833,2.478,14.983t7.276,5.152q9.673,0,9.673-20.135t-9.592-20.135q-4.881,0-7.357,5.131T320.04,106.991Z" transform="translate(-309.109 -77.418)" fill={filledcolor}/>
        </g>
        <g id="Group_1963" data-name="Group 1963" transform="translate(142.1 32.307)">
            <path id="Path_641" data-name="Path 641" d="M319.18,135.305,315.875,120.8H300.892l-3.305,14.509h-10.7l14.236-57.73H315.64l14.275,57.73Zm-5.31-23.99L310.8,97.944q-.275-1.215-1.241-6.116t-1.16-6.547q-.512,3.384-1.3,7.455t-4.09,18.578Z" transform="translate(-286.89 -77.575)" fill={filledcolor}/>
        </g>
        <g id="Group_1964" data-name="Group 1964">
            <path id="Path_642" data-name="Path 642" d="M318.3,130.782A21.373,21.373,0,0,1,312,145.99l-7.933,7.929,31.748,31.753a14.554,14.554,0,0,0,20.578,0l44.6-44.6a14.539,14.539,0,0,0,0-20.574l-44.6-44.6a14.554,14.554,0,0,0-20.578,0l-31.748,31.753L312,115.573A21.373,21.373,0,0,1,318.3,130.782Z" transform="translate(-88.695 -64.675)" fill="none"/>
            <path id="Path_643" data-name="Path 643" d="M409.682,120.9l-44.6-44.6a21.5,21.5,0,0,0-30.413,0l-31.748,31.748,4.918,4.922,31.748-31.753a14.554,14.554,0,0,1,20.578,0l44.6,44.6a14.539,14.539,0,0,1,0,20.574l-44.6,44.6a14.554,14.554,0,0,1-20.578,0l-31.748-31.753-4.918,4.922,31.748,31.748a21.5,21.5,0,0,0,30.413,0l44.6-44.6a21.509,21.509,0,0,0,0-30.418Z" transform="translate(-92.46 -70)" fill={outlinecolor}/>
            <path id="Path_644" data-name="Path 644" d="M310.847,92.925a14.539,14.539,0,0,1,0,20.574l-7.929,7.933,4.918,4.917,7.933-7.929a21.51,21.51,0,0,0,0-30.418l-7.933-7.929-4.918,4.917Z" transform="translate(-92.46 -37.106)" fill={outlinecolor}/>
            <path id="Path_645" data-name="Path 645" d="M318.9,81.217a14.554,14.554,0,0,1,20.578,0l31.748,31.753,4.922-4.922L344.392,76.3a21.5,21.5,0,0,0-30.413,0L280.49,109.788l4.918,4.917Z" transform="translate(-165.686 -70)" fill={outlinecolor}/>
            <path id="Path_646" data-name="Path 646" d="M339.474,124.006a14.554,14.554,0,0,1-20.578,0L285.408,90.518l-4.918,4.917,33.489,33.488a21.5,21.5,0,0,0,30.413,0l31.753-31.748-4.922-4.922Z" transform="translate(-165.686 -3.01)" fill={outlinecolor}/>
            <rect id="Rectangle_949" data-name="Rectangle 949" width="6.956" height="6.956" transform="translate(205.538 89.246) rotate(-45)" fill={outlinecolor}/>
            <rect id="Rectangle_950" data-name="Rectangle 950" width="6.956" height="6.956" transform="translate(205.538 42.968) rotate(-45)" fill={outlinecolor}/>
            <path id="Path_647" data-name="Path 647" d="M297.9,101.884a21.355,21.355,0,0,0-6.3-15.209l-6.193-6.193L280.49,85.4l6.193,6.2a14.549,14.549,0,0,1,0,20.574l-6.193,6.2,4.918,4.917,6.193-6.193A21.355,21.355,0,0,0,297.9,101.884Z" transform="translate(-165.686 -35.777)" fill={outlinecolor}/>
            <path id="Path_648" data-name="Path 648" d="M329.966,191a14.549,14.549,0,0,1-20.574,0l-44.6-44.6a14.549,14.549,0,0,1,0-20.574l44.6-44.6a14.549,14.549,0,0,1,20.574,0l33.493,33.488,4.917-4.917L334.888,76.3a21.509,21.509,0,0,0-30.418,0l-44.6,44.6a21.51,21.51,0,0,0,0,30.418l44.6,44.6a21.509,21.509,0,0,0,30.418,0l33.488-33.488-4.917-4.917Z" transform="translate(-253.572 -70)" fill={outlinecolor}/>
            <rect id="Rectangle_951" data-name="Rectangle 951" width="6.956" height="6.956" transform="translate(109.885 87.507) rotate(-44.999)" fill={outlinecolor}/>
            <rect id="Rectangle_952" data-name="Rectangle 952" width="6.956" height="6.956" transform="translate(109.885 44.706) rotate(-44.999)" fill={outlinecolor}/>
        </g>
        <g id="Group_1965" data-name="Group 1965" transform="translate(61.146 32.541)">
            <path id="Path_649" data-name="Path 649" d="M267.909,135.126V77.63h10.07v57.5Z" transform="translate(-267.909 -77.63)" fill={filledcolor}/>
        </g>
    </SvgIcon>
  );
};

export default IaqSVG;
