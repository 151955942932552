import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './../../AppContext';
import MUIDataTable from 'mui-datatables';
import {
  Grid,
  IconButton
} from '@material-ui/core';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { navigate, A } from 'hookrouter';
import LocationIcon from '@material-ui/icons/Room';
import GatewayIcon from '@material-ui/icons/Router';
import AlertIcon from '@material-ui/icons/Announcement';
import SensorIcon from '../../img/icons/SensorIcon';
import HeaderBar from '../Generic/HeaderBar';
import LocationCard from '../Generic/LocationCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import Place from '../../icons/Place'

import MapLayout from "../common/MapLayout";

import Pagination from '@material-ui/lab/Pagination';

// mobile components

import {
  MobileTabsContainer,
} from "../Generic/Mobile/MobileTabs"

import MobileSearchBar from '../Generic/Mobile/MobileSearchBar'

import MobileBottomButton, { MobileBottomButtonSpacer } from '../Generic/Mobile/MobileBottomButton'
import {
  FullViewContainer,
  ListViewContainer
} from '../Generic/Mobile/MobileContainers'

//icons

import ListAltIcon from '@material-ui/icons/ListAlt';


const useStyles = makeStyles(theme => ({
  // root: {},
  tableTitle: {
    marginLeft: '1rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

/**
 * Event to add a location that is send to the search to execute
 */
const addLocationEvent = () => {
  console.warn('Event not implemented yet');
};

const AllLocations = (props) => {
  const log = window.log('AllLocations');

  const theme = useTheme();
  const classes = useStyles();

  const {
    appState,
    setAppState,
    setOpenAddingModal,
    getProfileSetting,
    locations,
    getLocations,
    darkModeContainerColor
  } = useContext(AppContext);
  const [state, setState] = useState(locations);
  const [openState, setOpenState] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filterColumns, setColumns] = useState([
    'name',
    'street',
    'city',
    'state',
    'zip',
  ]);
  const [listView, setListView] = useState(
    getProfileSetting('pageView.locations') || false,
  );

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  useEffect(() => {
    setInitialPageLoad(false);
    setOriginalData({
      locations: locations,
    });
    setState(locations);
  }, [locations]);
  
  //After DCS Admin switches account, fetch that account's data
  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width)

  const [mobileViewShowMap, setMobileViewShowMap] = useState(false);

  /**
   * End of mobile view variables
   */

  const searchCallback = (value) => {
    log('Inside of search callback ', originalData);
    let filteredArray = [];
    //Iteratate over every location object
    for (var i = 0; i < originalData['locations'].length; i++) {
      let match = false;
      log(originalData['locations'][i]);
      //Iterate over every filter condtion
      for (var j = 0; j < filterColumns.length; j++) {
        log('Look ' + originalData['locations'][i][filterColumns[j]]);
        //Check for match
        if (
          originalData['locations'][i][filterColumns[j]]
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          match = true;
        }
      }
      //If match add to filtered array
      if (match) {
        filteredArray.push(originalData['locations'][i]);
      }
    }
    setState(filteredArray);
  }

  useEffect(() => {
    if (!appState.isSwitched) return;
    getLocations(true);
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  const options = {
    onRowClick: selectedRow => {
      navigate(`/location/${selectedRow[0]}`);
    },
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    fixedHeader: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        viewColumns: false,
        display: false,
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'street',
      label: 'Street',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'zip',
      label: 'Zip',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'Phone',
      label: 'phone',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'alertCount',
      label: 'Alert Count',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'gatewayCount',
      label: 'Gateway Count',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'sensorCount',
      label: 'Sensor Count',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/'>
        Dashboard
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const sortButtonEvent = () => {
    // NOTE: This may comeback in future ui designs
    // updateUserProfile('pageView.locations', !listView);
    setListView(!listView);
  };

  useEffect(() => {
    setListView(getProfileSetting('pageView.locations'));
  }, [appState?.pageView?.locations]);

  //  Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const displayCardView = () => {
    return state.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(location => (
      <Grid
        item
        xs={12}
        sm={ state.slice((page - 1) * itemsPerPage, page * itemsPerPage).length === 1 ? 12  : 6 }
        key={location.id}
        className='all_locations_card'
      >
        <LocationCard
          id={location.id}
          title={`${location.name}`}
          street={`${location.street}`}
          city={`${location.city}`}
          state={`${location.state}`}
          zip={`${location.zip}`}
          phone={location.phone}
          trailingItems={[
            {
              value: location.alertCount,
              icon: (
                <AlertIcon
                  style={{ fontSize: 28, color: '#FF6A6A' }}
                />
              ),
            },
            {
              value: location.gatewayCount,
              icon: (
                <GatewayIcon
                  style={{ fontSize: 28, color: '#59C754' }}
                />
              ),
            },
            {
              value: location.sensorCount,
              icon: (
                <SensorIcon
                  style={{
                    fontSize: 28,
                    color: theme.palette.primary.main,
                  }}
                />
              ),
            },
          ]}
        />
      </Grid>
    ))
  }

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div>
      { isMobileSize ?
        <FullViewContainer>
          <MobileTabsContainer>
            <MobileSearchBar
              searchCallback={searchCallback}
              suffixComponent={
                <IconButton
                  onClick={() => {
                    setMobileViewShowMap(!mobileViewShowMap)
                  }}
                >
                  {mobileViewShowMap
                    ? <ListAltIcon></ListAltIcon>
                    : <Place style={{ width: 18 }}></Place>
                  }
                </IconButton>
              }
            />
          </MobileTabsContainer>
          <ListViewContainer marginTop={mobileViewShowMap ? -55 : 0}>
            <Grid
              container
              spacing={0}
              style={{
                marginTop: mobileViewShowMap ? 0 : 10
              }}
            >
              {
                mobileViewShowMap ?
                  <Grid item xs={12}>
                    <MapLayout
                      mapStyle={{
                        height: `calc(100vh - ${150 + (mobileViewShowMap ? -55 : 0)}px)`,
                        width: '100%',
                        zIndex: 1
                      }}
                      mapContainerStyle={{
                        padding: 0
                      }}
                      loading={state.loading}
                      mapCenter={{ lat: 37.376, lng: -122.034 }}
                      navigationPrefixUri=""
                    />
                  </Grid>
                  :
                  <Grid
                    container
                    item
                    xs={12}
                  >
                      {state ? state.map((location, index) => (
                      <Grid
                        item
                        xs={12}
                        key={location.id}
                      >
                        <LocationCard
                          isTileLayout={true}
                          backgroundColor={darkModeContainerColor() ?  index % 2 === 0 ? '#212121' :'#424242' : index % 2 === 0 ? '#FFF' : '#F4F5F5'}
                          cardArguments
                          id={location.id}
                          title={`${location.name}`}
                          street={`${location.street}`}
                          city={`${location.city}`}
                          state={`${location.state}`}
                          zip={`${location.zip}`}
                          phone={location.phone}
                          trailingItems={[
                            {
                              value: location.alertCount,
                              icon: (
                                <AlertIcon style={{ fontSize: 28, color: '#FF6A6A' }} />
                              ),
                            },
                            {
                              value: location.gatewayCount,
                              icon: (
                                <GatewayIcon
                                  style={{ fontSize: 28, color: '#59C754' }}
                                />
                              ),
                            },
                            {
                              value: location.sensorCount,
                              icon: (
                                <SensorIcon
                                  style={{
                                    fontSize: 28,
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              ),
                            },
                          ]}
                        />
                      </Grid>
                    )) : null}
                    <MobileBottomButtonSpacer />
                  </Grid>
              }
            </Grid>
          </ListViewContainer>
          <MobileBottomButton
            onClick={openAddModal}
          />
        </FullViewContainer>
        :
        <div>
          <HeaderBar
            leadingIcon={<LocationIcon style={{ fontSize: 32 }} color='primary' />}
            title='Locations'
            buttonAddTitle='ADD LOCATION'
            buttonAddEvent={openAddModal}
            searchCallback={searchCallback}
            sortActive={listView}
            sortButton={true}
            sortButtonEvent={sortButtonEvent}
          />
          <Grid container spacing={2}>
            {state ? (
              listView ? (
                <Grid xs={12} item>
                  <MUIDataTable
                    title={'Locations'}
                    data={state}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              ) : (
                  state.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(location => (
                    <Grid item xs={12} sm={6} key={location.id}>
                      <LocationCard
                        id={location.id}
                        title={`${location.name}`}
                        street={`${location.street}`}
                        city={`${location.city}`}
                        state={`${location.state}`}
                        zip={`${location.zip}`}
                        phone={location.phone}
                        backgroundColor={darkModeContainerColor() ?  '#424242' : '#FFF'}
                        trailingItems={[
                          {
                            value: location.alertCount,
                            icon: (
                              <AlertIcon style={{ fontSize: 28, color: '#FF6A6A' }} />
                            ),
                          },
                          {
                            value: location.gatewayCount,
                            icon: (
                              <GatewayIcon
                                style={{ fontSize: 28, color: '#59C754' }}
                              />
                            ),
                          },
                          {
                            value: location.sensorCount,
                            icon: (
                              <SensorIcon
                                style={{
                                  fontSize: 28,
                                  color: theme.palette.primary.main,
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </Grid>
                  ))
                )
            ) : null}
            {!listView && state && (
              <Grid item xs={12} container justify="center">
                <Pagination
                  variant="outlined"
                  count={Math.ceil(state.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </Grid>
        </div>
      }
    </div>
  );
};

export default withWidth()(AllLocations);
