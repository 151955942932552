import React from "react";
import { SvgIcon } from "@material-ui/core";

const PcbTempSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="189.12"
      height="189.12"
      viewBox="0 0 189.12 189.12"
      {...props}
    >
        <g id="PCBTemp_icon" transform="translate(0)">
            <path id="Path_650" data-name="Path 650" d="M309.965,194.656a5.788,5.788,0,1,0,0-11.577H288.44V167.343h21.525a5.788,5.788,0,1,0,0-11.577H288.44v-1.043A19.464,19.464,0,0,0,269,135.285h-1.043V113.759a5.788,5.788,0,1,0-11.577,0v21.525H240.639V113.759a5.788,5.788,0,1,0-11.577,0v21.525H213.325V113.759a5.788,5.788,0,1,0-11.577,0v21.525H186.012V113.759a5.788,5.788,0,1,0-11.577,0v21.525H173.4a19.46,19.46,0,0,0-19.445,19.439v1.043H132.428a5.788,5.788,0,1,0,0,11.577h21.525V183.08H132.428a5.788,5.788,0,1,0,0,11.577h21.525v15.737H132.428a5.788,5.788,0,1,0,0,11.577h21.525v15.737H132.428a5.788,5.788,0,1,0,0,11.577h21.525v1.043A19.465,19.465,0,0,0,173.4,269.771h1.037V291.3a5.788,5.788,0,0,0,11.577,0V269.771h15.737V291.3a5.788,5.788,0,0,0,11.577,0V269.771h15.737V291.3a5.788,5.788,0,0,0,11.577,0V269.771h15.737V291.3a5.788,5.788,0,0,0,11.577,0V269.771H269a19.469,19.469,0,0,0,19.445-19.445v-1.043h21.525a5.788,5.788,0,1,0,0-11.577H288.44V221.97h21.525a5.788,5.788,0,1,0,0-11.577H288.44V194.656Zm-33.108,55.67A7.872,7.872,0,0,1,269,258.189H173.4a7.873,7.873,0,0,1-7.868-7.862v-95.6a7.873,7.873,0,0,1,7.868-7.862H269a7.871,7.871,0,0,1,7.862,7.862Z" transform="translate(-126.635 -107.965)" fill={outlinecolor}/>
            <g id="Group_1967" data-name="Group 1967" transform="translate(68.582 49.559)">
                <path id="Path_651" data-name="Path 651" d="M163.984,206.192a26.043,26.043,0,0,1-13.9-48.025V129.774a13.593,13.593,0,0,1,27.187,0v27.79a26.269,26.269,0,0,1,12.686,22.351A25.849,25.849,0,0,1,163.984,206.192Zm8.152-43.49-.6-31.413c0-3.624-4.227-6.343-7.85-6.343s-7.555,2.719-7.555,6.343V163a19.212,19.212,0,0,0-10.871,17.214A19.03,19.03,0,1,0,172.136,162.7Z" transform="translate(-138.009 -116.184)" fill={outlinecolor} fillRule="evenodd"/>
                <path id="Path_652" data-name="Path 652" d="M154.161,174.258a14.181,14.181,0,0,1-14.2-14.2,14.4,14.4,0,0,1,9.364-13.289V120.192h9.062V146.77a13.948,13.948,0,0,1,9.967,13.289A14.18,14.18,0,0,1,154.161,174.258Z" transform="translate(-128.187 -96.026)" fill={filledcolor} fillRule="evenodd"/>
            </g>
        </g>
    </SvgIcon>
  );
};

export default PcbTempSVG;
