import React from "react";
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@material-ui/core";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    width: "100%",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    width: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: `100%`,
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  cardContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    height: `100%`,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const LabelGenerator = (props) => {
  const log = window.log('LabelGenerator');
  const { openState, handleClose } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const classes = useStyles();
  const [values, setValues] = React.useState({
    printer: "",
    numberOfLabels: "",
  });
  const [passwordError, setPasswordError] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]: event.target.value });
    log(values);
  };

  const onSubmitClick = async () => {};

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
        <Card className={classes.cardContainer}>
          <CardContent className={classes.formContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography className={classes.titleText} align="center">
                  LABEL GENERATOR
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                        paddingTop: theme.spacing(6),
                      }
                }
                className={classes.formContainer}
              >
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel htmlFor="outlined-adornment-printer">
                      Printer
                    </InputLabel>
                    <Select label="Printer"></Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-numberOfLabels">
                      # of Labels
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-numberOfLabels"
                      className={classes.textField}
                      fullWidth
                      variant="outlined"
                      label="# of Labels"
                      placeholder="# of Labels"
                      value={values.numberOfLabels}
                      name="numberOfLabels"
                      onChange={(e) => handleChange(e)}
                      inputProps={{ min: 0 }}
                      error={passwordError}
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <CardActions className={classes.actionContainer}>
              <Grid
                container
                style={{
                  paddingLeft: theme.spacing(8),
                  paddingRight: theme.spacing(8),
                  marginBottom: theme.spacing(4),
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    className={classes.leftButton}
                    onClick={handleClose}
                    size="large"
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} container justify="flex-end">
                  <Button
                    className={classes.rightButton}
                    onClick={onSubmitClick}
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
    </Dialog>
  );
};

export default LabelGenerator;
