import React, { useState, useContext, useEffect } from 'react';

import { EventBus } from '../common/EventBus';
import { AppContext } from './../../AppContext';
import gateway from '../../img/router-24px.svg';
import ImageButton from '../Generic/ImageButton';
import NameAndID from './ModalComponents/NameAndID';
import GenericDialog from '../Generic/GenericDialog';
import sensor from '../../img/settings_remote-24px.svg';
import Loading from '../common/ModalComponents/Loading';
import Complete from '../common/ModalComponents/Complete';
import SelectLocation from './ModalComponents/SelectLocation';
import SelectGateway from './ModalComponents/SelectGateway';

/**
 * Gateway Form Modals
 */
export default function GatewayFormModal() {
  const log = window.log('GatewayFormModal');

  const {
    snack,
    appState,
    setAppState,
    setLogoutState,
    setFeatureCount,
    openGatewayModal,
    setOpenSensorModal,
    setOpenGatewayModal,
    limitReachedSnackbar,
    fullGatewayList
  } = useContext(AppContext);

  //Gateway name state hooks
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  //Gateway MAC state hooks
  const [gatewayMac, setGatewayMac] = useState('');
  const [gatewayMacError, setGatewayMacError] = useState(false);
  //Location ID state hook
  const [locationId, setLocationId] = useState(null);

  //Edit state hooks
  const [isEdit, setIsEdit] = useState(false);
  const [gatewayEntryId, setGatewayEntryId] = useState(null);

  //Select Gateways
  const [gateways, setGateways] = useState(null)
  const [selectedGateway, setSelectedGateway] = useState(null)

  useEffect(()=>{
    //api data goes here

    setGateways(fullGatewayList)
  },[])

  const setDefaultObject = (editObj = null) => {
    log('Looking at the editObj ' + JSON.stringify(editObj));
    if (editObj) {
      //TODO: STUB
      //Setting hardwarProfile key
      //What is this??
      // editObj.hardwareProfile = gatewayStubData[0]
      //end stub

      setIsEdit(true);
      setSelectedGateway(editObj.hardwareProfile)
      setGatewayMac(false);
      setName(editObj.editName);
      setLocationId(editObj.locationEntryId);
      setGatewayEntryId(editObj.gatewayEntryId);
    } else {
      setName('');
      setIsEdit(false);
      setGatewayMac('');
      setLocationId(null);
    }
  };

  useEffect(() => {
    setDefaultObject();
    //Fixes the edit and the add modals from opening at the same time
    EventBus.on('editGatewayModalProps', data => {
      setDefaultObject(data);
    });

    return () => {
      EventBus.remove('editGatewayModalProps');
    };
  }, []);

  // Marks Add Gateway as complete for onboard
  const handleGatewayFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addGateway: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter(
          item => item === false,
        ).length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], 'error');
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const handleSubmitNameAndId = async () => {
    if (nameError) return false;
    if (isEdit) return true;
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/checkGatewayMAC?gatewayMac=${gatewayMac}&hardware_id=${selectedGateway.hardware_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      if (!json.success) {
        let errorObj = {};
        json.errors.forEach(error => {
          errorObj = { ...errorObj, [error.type]: true };
          snack(error.msg, 'error');
          setGatewayMacError(true);

          if (error.type === 'token') {
            setLogoutState(true);
          }
        });
      } else setGatewayMacError(false);

      return json.success;
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  // Justin This function will now handle location submit step
  const handleSubmitGateway = async () => {
    log('Gateway_Submit: Inside of handle gateway');

    try {
      const data = {
        gatewayMAC: gatewayMac,
        name,
        locationId,
        userId: appState.auth.userInfo.id,
        hardwareProfile: selectedGateway
      };

      if (isEdit) {
        data['gatewayEntryId'] = gatewayEntryId;
        data['editName'] = name;
      }

      log('Gateway_Submit: About to make the web request');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/${isEdit ? 'edit' : 'add'}`,
        {
          method: isEdit ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      const json = await response.json();

      log('Gateway_Submit: Looking at the json response ', json);

      if (json.success) {
        setNameError(false);
        setGatewayMacError(false);
        if (isEdit) {
          EventBus.dispatch('gatewayModalCallBacks');
        } else {
          setDefaultObject();
          handleGatewayFeatureComplete();
        }

        return true;
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          if (error.type === 'gatewayMac') {
            setGatewayMacError(true);
          } else if (error.type === 'limit') {
            limitReachedSnackbar('gateways');
          } else if (error.type === 'system') {
            snack(
              'System error. If the problem persists, please contact support.',
              'error',
            );
          } else if (error.type === 'token') {
            snack(error.msg, 'error');
            setLogoutState(true);
          } else {
            //err alert
            snack(error.msg, 'error');
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleRestart = () => {
    setOpenGatewayModal(false);
    setTimeout(() => {
      setOpenGatewayModal(true);
    }, 500);
  };

  const handleCloseAndOpenSensorState = () => {
    setOpenGatewayModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenSensorModal(true);
    }, 500);
  };

  const handleClose = () => {
    setOpenGatewayModal(false);
    setDefaultObject();
    setSelectedGateway(null);
  };

  if (window.ReactNativeWebView) {
    window.addEventListener('message', e => {
      log(e.origin);
      const data = e.data.split('|');
      if (data[1] !== 'gatewayMiSensors') {
        return;
      }
      if (data[0].includes('MIGATEWAY')) {
        let result = data[0]
          .split(',')[1]
          .replace('MIGATEWAY', '')
          .replace(/:\s*/g, '');
        setGatewayMac(result);
      } else {
        snack('Not a valid gateway QR code.', 'error');
      }
    });
  }

  const handleGatewaySelection = (gateway) => {
    setSelectedGateway(gateway)
  }

  const handleSelectGatewayNext = () => {
    if(selectedGateway){
      return true
    } else {
      snack('Please select a gateway.', 'error');
      return false
    }
  }

  const steps = () =>{
    return (
      [
        {
          label:'Select Gateway',
          nextFunction: handleSelectGatewayNext,
          showProgress: false,
          child: (
            <SelectGateway
              gateways={gateways}
              handleSelectGateway={handleGatewaySelection}
              selection={selectedGateway}
            />
          ),
        },
        {
          label: `GATEWAY ${isEdit ? '' : 'ID &'} NAME`,
          nextFunction: handleSubmitNameAndId,
          showProgress: false,
          child: (
            <NameAndID
              name={name}
              isEdit={isEdit}
              setName={setName}
              nameError={nameError}
              gatewayMac={gatewayMac}
              setNameError={setNameError}
              setGatewayMac={setGatewayMac}
              gatewayMacError={gatewayMacError}
            />
          ),
        },
        {
          label: 'SELECT LOCATION',
          nextFunction: handleSubmitGateway,
          validator: true,
          showProgress: true,
          saveButtonStep: true,
          child: (
            <SelectLocation
              locationId={locationId}
              setLocationId={setLocationId}
            />
          ),
        },
        {
          label: 'LOADING',
          child: (
            <Loading
              header={`${
                isEdit ? 'Updating' : 'Adding'
              } gateway into platform`}
              desc='MiSensors is currently configuring your gateway into the platform. This should take less than a minute.'
            />
          ),
        },
        {
          label: 'COMPLETE', // Because is the finish
          child: (
            <Complete
              header={`Success! Your Gateway has been ${
                isEdit ? 'updated' : 'added'
              }.`}
              desc={
                isEdit
                  ? 'Select Finish to return to the gateway page.'
                  : 'You may now add additional gateways or start adding sensors by selecting the options below.'
              }
              completeButtons={
                isEdit
                  ? []
                  : [
                      <ImageButton
                        key={1}
                        onClick={handleCloseAndOpenSensorState}
                        image={sensor}
                        action='CONTINUE'
                        text='ADD SENSOR'
                      />,
                      <ImageButton
                        key={2}
                        onClick={() => handleRestart()}
                        image={gateway}
                        action='ADD'
                        text='ANOTHER GATEWAY'
                      />,
                    ]
              }
            />
          ),
        },
      ]
    )
  }

  return (
    <div>
      <GenericDialog
        openState={openGatewayModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title={`${isEdit ? 'EDIT' : 'ADD A NEW'} GATEWAY`}
        stepsArray={steps()}
      />
    </div>
  );
}
