import _ from 'lodash';
import React from 'react';
import {
  Box,
  Card,
  Grid,
  Button,
  Tooltip,
  Checkbox,
  makeStyles,
  CardContent,
} from '@material-ui/core';

//  Componenets import
import SensorValueCard from '../../Generic/SensorValueCard';
import { RSLightStepAdvanced } from './RSLightStepAdvanced';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';
// Colors import
import grey from '@material-ui/core/colors/grey';
// Icons import
import CheckIcon from '@material-ui/icons/Check';
import { determineLightRangeType } from '../../common/helpers/calculateLuxDisplay';

const useStyles = makeStyles(theme => ({
  fontColor: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  checkIcon: {
    height: 35,
    width: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #9D9D9D',
    borderRadius: '6px',
  },
  checkIconChild: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    width: 25,
  },
}));

// LuxValues for the thresholdUpdate
const luxValues = [
  [0, 50],
  [51, 1500],
  [1501, 10000],
  [10001, 99999],
];

/**
 * Update the treshold for the objects
 */
const updateThresholdObject = temp => {
  const defaultObject = {
    firstLightThresholdLow: null,
    firstLightThresholdHigh: null,
    secondLightThresholdLow: null,
    secondLightThresholdHigh: null,
  };
  let firstThresholdValidator = false;
  for (var i in temp) {
    if (temp[i]) {
      if (!firstThresholdValidator) {
        if (defaultObject.firstLightThresholdLow === null) {
          defaultObject.firstLightThresholdLow = luxValues[i][0];
        }
        defaultObject.firstLightThresholdHigh = luxValues[i][1];
      } else {
        if (defaultObject.secondLightThresholdLow === null) {
          defaultObject.secondLightThresholdLow = luxValues[i][0];
        }
        defaultObject.secondLightThresholdHigh = luxValues[i][1];
      }
    } else {
      firstThresholdValidator = defaultObject.firstLightThresholdHigh !== null;
    }
  }
  return defaultObject;
};

/**
 * LightGauge for the  card
 */

function LightGauge(props) {
  const useStylesTooltip = makeStyles(theme => ({
    arrow: {
      color: theme.palette.success.main,
    },
    tooltip: {
      backgroundColor: theme.palette.success.main,
      fontSize: 13,
      color: '#FFFFFF',
    },
  }));

  function CustomTooltip(props) {
    const classes = useStylesTooltip();

    return (
      <Tooltip
        arrow
        classes={classes}
        {...props}
        TransitionComponent={({ children }) => children}
      />
    );
  }
  const {
    title, // Title
    description, // Description
    alignItems = 'center', // Direction
    rightIndicator, // Show or Hide the right indicator
    value, // Value
    selectedStart, // Determine is the red background is starting
    selectedEnd, // Determine is the red background is ending
    sensorPosition, // Determine the position of the sensor marker
    sensorValue, // Value of the sensor for Tooltip
  } = props;

  const barStartBorders =
    alignItems === 'flex-start'
      ? {
          borderTopLeftRadius: 3,
          borderBottomLeftRadius: 3,
        }
      : {};

  const barEndBorders =
    alignItems === 'flex-end'
      ? {
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
        }
      : {};

  const containerStartBorders = selectedStart
    ? {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      }
    : {};

  const containerEndBorders = selectedEnd
    ? {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      }
    : {};

  const useStyles = makeStyles(theme => ({
    title: {
      fontWeight: 'bold',
      color: '#747474',
      fontSize: 11,
    },
    subTitle: {
      fontWeight: 'normal',
      color: '#747474',
      fontSize: 11,
    },
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background: value ? 'rgba(255, 106, 106, 0.5)' : 'transparent',
      height: 16,
      ...containerStartBorders,
      ...containerEndBorders,
    },
    bar: {
      border: '2px solid #CCCCCC',
      height: 0,
      width: '100%',

      backgroundColor: '#CCCCCC',
      opacity: 1,
      ...barStartBorders,
      ...barEndBorders,
    },

    indicator: {
      height: 10,
      border: '1px solid #CCCCCC',
      bacgrkoundColor: '#CCCCCC',
    },

    marker: {
      position: 'absolute',
      width: 16,
      height: 16,
      background: theme.palette.success.main,
      border: '2px solid #FFFFFF',
      left: `${sensorPosition}%`,
      borderRadius: 14,
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        {/* Container */}
        <div className={classes.container}>
          {/* Element */}
          {sensorPosition !== undefined ? (
            <CustomTooltip
              title={`${sensorValue} lux`}
              open={true}
              placement='top'
            >
              <div className={classes.marker} />
            </CustomTooltip>
          ) : null}
          <div className={classes.bar} />
          {rightIndicator ? <div className={classes.indicator} /> : null}
        </div>
      </Box>
      <Box display='flex' flexDirection='column' alignItems={alignItems}>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.subTitle}>{description}</Box>
      </Box>
    </React.Fragment>
  );
}
/**
 * CustomCheckbox for the  card
 */
function CustomCheckbox(props) {
  const { value = true, index, onClick } = props;

  const useStyles = makeStyles(theme => ({
    checkIcon: {
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      border: value
        ? `2px solid ${theme.palette.primary.main}`
        : '1px solid #9D9D9D',
      borderRadius: '6px',
    },

    icon: {
      fontSize: 22,
      color: theme.palette.primary.main,
    },
  }));

  const classes = useStyles();

  return (
    <Checkbox
      checked={value}
      onChange={event => {
        onClick(event, index);
      }}
      icon={
        <div>
          <div className={classes.checkIcon} />
        </div>
      }
      checkedIcon={
        <div>
          <div className={classes.checkIcon}>
            <CheckIcon className={classes.icon} />
          </div>
        </div>
      }
      color='primary'
    />
  );
}

/**
 * LightStepCard with the "slider"
 * @param props
 */
function LightStepCard(props) {
  const { object, setObject, baselineSensorSel } = props;
  const value =
    baselineSensorSel.lastSensorMessage !== undefined
      ? baselineSensorSel.lastSensorMessage['light']
      : undefined;

  const useStyles = makeStyles(theme => ({
    card: {
      margin: theme.spacing(1.5, 0),
      border: `1px solid ${grey[500]}`,
      overflow: 'visible',
      padding: theme.spacing(1.5),
    },
    cardContent: {
      marginTop: theme.spacing(2),
    },
    slider: {
      width: '90%',
    },
    manualInputTextfield: {
      width: 120,
    },
  }));

  const classes = useStyles();

  /**
   * Element array
   */
  const elementArray = [
    {
      xs: 2,
      justifyContent: 'center',
      alignItems: 'flex-start',
      rightIndicator: true,
      title: 'Off',
      description: '(0-50 lux)',
    },
    {
      xs: 4,
      justifyContent: 'center',
      alignItems: 'center',
      rightIndicator: true,
      title: 'Low',
      description: '(51-1500 lux)',
    },
    {
      xs: 4,
      justifyContent: 'center',
      alignItems: 'center',
      rightIndicator: true,
      title: 'Med',
      description: '(1501-10000 lux)',
    },
    {
      xs: 2,
      justifyContent: 'center',
      alignItems: 'flex-end',
      ightIndicator: false,
      title: 'High',
      description: '(10000> lux)',
    },
  ];

  /**
   * Handle the change for the checkbox and make the calculation for the Threshold
   */
  const handleCheckboxChange = (event, index) => {
    const temp = Object.assign([], object.checkValues);
    temp[index] = event.target.checked;
    const thresholds = updateThresholdObject(temp);
    const rangeType = determineLightRangeType({ ...object, checkValues: temp });
    setObject({
      ...object,
      ...thresholds,
      rangeType,
      checkValues: temp,
    });
  };

  /**
   * Validate is the its the start of the gauge
   */
  const validateGaugeStart = index => {
    if (index === 0) {
      return object.checkValues[index];
    } else {
      return !object.checkValues[index - 1];
    }
  };
  /**
   * Validate is the its the end of the gauge
   */
  const validateGaugeEnd = index => {
    if (index === 3) {
      return object.checkValues[index];
    } else {
      return !object.checkValues[index + 1];
    }
  };

  const validateQuadrant = index => {
    if (value >= luxValues[index][0] && value <= luxValues[index][1]) {
      const quadrantDifference = luxValues[index][1] - luxValues[index][0];
      const difference = value - luxValues[index][0];
      const percentage = (difference / quadrantDifference) * 100;
      return percentage;
    }
    return undefined;
  };

  return (
    <Card className={classes.card} variant='outlined'>
      <CardContent className={classes.cardContent}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Box className={classes.slider}>
            <Grid container>
              {elementArray.map((item, index) => (
                <Grid
                  key={`gauge__item__${index}`}
                  item
                  xs={item.xs}
                  container
                  justify={item.justifyContent}
                >
                  <LightGauge
                    title={item.title}
                    description={item.description}
                    alignItems={item.alignItems}
                    rightIndicator={item.rightIndicator}
                    value={object.checkValues[index]}
                    selectedStart={validateGaugeStart(index)}
                    selectedEnd={validateGaugeEnd(index)}
                    sensorPosition={validateQuadrant(index)}
                    sensorValue={value}
                  />
                </Grid>
              ))}
              {elementArray.map((item, index) => (
                <Grid
                  key={`checkbox__item__${index}`}
                  item
                  xs={item.xs}
                  container
                  justify={item.justifyContent}
                >
                  <CustomCheckbox
                    value={object.checkValues[index]}
                    onClick={handleCheckboxChange}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

/**
 * Range Settings Step
 * @param {*} props
 */

export default function RangeSettingsLightStep(props) {
  const {
    object,
    setObject,
    advLightErr,
    rangeSelError,
    setAdvLightErr,
    setShowMarkers,
    objectDefinition,
    baselineSensorSel,
    isAdvancedSetting,
    invalidRangeTypeError,
  } = props;

  const classes = useStyles();

  /**
   * Toggle the advancedSettings Value of the lightState
   */
  const toggleAdvancedSettings = () => {
    setObject({
      ...object,
      isAdvancedSetting: !object.isAdvancedSetting,
    });
  };

  return (
    <Grid item xs={12} p>
      <ModalFormHeader
        header={`Alert Settings - ${objectDefinition.title}`}
        desc={`Be certain your baseline sensor is located in the environment you wish to monitor.`}
      />
      {!_.isEmpty(baselineSensorSel.lastSensorMessage) &&
        (baselineSensorSel?.lastSensorMessage['light'] ||
          baselineSensorSel?.lastSensorMessage['light'] === 0) && (
          <Grid item xs={12}>
            <SensorValueCard
              icon={objectDefinition.icon}
              sensorName={objectDefinition.title}
              readingName={'Baseline Reading'}
              sensorValue={baselineSensorSel.lastSensorMessage['light']}
            />
          </Grid>
        )}
      {isAdvancedSetting ? (
        <RSLightStepAdvanced
          object={object}
          setObject={setObject}
          advLightErr={advLightErr}
          rangeSelError={rangeSelError}
          setAdvLightErr={setAdvLightErr}
          setShowMarkers={setShowMarkers}
          objectDefinition={objectDefinition}
          invalidRangeTypeError={invalidRangeTypeError}
        />
      ) : (
        <React.Fragment>
          <Grid
            item
            xs={12}
            fontWeight='fontWeightLight'
            fontSize={16}
            style={{ marginTop: 10 }}
            key='form_desc'
          >
            <Box className={classes.fontColor}>
              Select at least one checkbox to define when the recipient will
              receive an alert.
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LightStepCard
              object={object}
              setObject={setObject}
              baselineSensorSel={baselineSensorSel}
            />
          </Grid>
        </React.Fragment>
      )}

      {/* <Grid item xs={12}>
        <SliderCard
          error={rangeSelError}
          minimumValue={objectDefinition.minimum}
          maximumValue={objectDefinition.maximum}
          light_markers={objectDefinition.marks}
          unitMeasure={objectDefinition.unit}
          values={objectDefinition.rangeValues}
          rangeType={objectDefinition.rangeType}
          isRangeType={isSettingAlertRange(objectDefinition.rangeType)}
          onChange={(newValue) => {
            rangeCallback(objectDefinition.name, "rangeValues", newValue);
          }}
          showMarkers={showMarkers}
        />
      </Grid> */}
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          onClick={toggleAdvancedSettings}
        >
          {isAdvancedSetting ? 'Basic' : 'Advanced'} SETTINGS
        </Button>
      </Grid>
    </Grid>
  );
}
