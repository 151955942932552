import React from 'react';
import { Button, Grid } from '@material-ui/core';

import GatewaySelect from '../../common/GatewaySelect';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

/**
 * Select Gateway Form
 * @param {Object} props
 * @props
 * gatewayId: string
 * gatewayCallback: ()=>{}
 * openGatewayModal: ()=>{}
 * gatewaySelectionError: bool
 */
export default function SelectGateway(props) {
  const {
    gatewayId,
    gatewayCallback,
    openGatewayModal,
    gatewaySelectionError,
    gateways
  } = props;

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Select the Gateway for your sensor'
        desc='Select the gateway that your sensor will be connected to. Once
        selected move onto the next step.'
      />
      <Grid item xs={12}>
        <Button
          variant='outlined'
          onClick={() => openGatewayModal()}
          color='primary'
          style={{
            width: '105%',
          }}
        >
          NO GATEWAY CREATED? ADD A NEW GATEWAY HERE
        </Button>
      </Grid>
      <Grid item xs={12}>
        <GatewaySelect
          error={gatewaySelectionError}
          returnValue={gatewayId}
          setReturnValue={gatewayCallback}
          width={'105%'}
          gateways={gateways}
        />
      </Grid>
    </Grid>
  );
}
