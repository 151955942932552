import React from "react";
import { SvgIcon } from "@material-ui/core";

const Co2SVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="224.784"
      height="143.555"
      viewBox="0 0 224.784 170"
      {...props}
    >
    <path id="Path_631" data-name="Path 631" d="M122.421,149.541a22.461,22.461,0,0,0,22.438,22.438H279.6a22.434,22.434,0,0,0,2.605-44.717L268.612,125.7l.761-13.661c.041-.787.061-1.5.061-2.161a38.2,38.2,0,0,0-72.59-16.619l-7.494,15.479-13.993-9.993a17,17,0,0,0-26.862,13.85l.6,12.179-10.815,3.31A22.31,22.31,0,0,0,122.421,149.541Z" transform="translate(-99.834 -50.053)" fill={filledcolor}/>
    <g id="Group_1948" data-name="Group 1948">
        <path id="Path_632" data-name="Path 632" d="M299.385,211.005H161.4a43.408,43.408,0,0,1-16.624-83.5,37.847,37.847,0,0,1,54.393-31.725,59.533,59.533,0,0,1,110.211,29.59,43.4,43.4,0,0,1-9.993,85.637ZM182.544,103.063a26.737,26.737,0,0,0-26.708,26.708c0,.46.01.92.036,1.374l.215,4.327-4.143,1.272a32.273,32.273,0,0,0,9.456,63.128H299.385a32.267,32.267,0,0,0,3.724-64.319l-5.206-.6.286-5.236c.056-1,.082-1.9.082-2.733a48.413,48.413,0,0,0-92-21.068L203.4,111.85l-5.369-3.826A26.482,26.482,0,0,0,182.544,103.063Z" transform="translate(-118 -67.45)" fill={outlinecolor}/>
    </g>
    <g id="Group_1949" data-name="Group 1949" transform="translate(64.41 63.342)">
        <path id="Path_633" data-name="Path 633" d="M158.149,106.333l7.985,2.534q-1.839,6.675-6.1,9.921a19.222,19.222,0,0,1-24.19-2.309q-5.234-5.556-5.231-15.178,0-10.184,5.257-15.817t13.829-5.635q7.479,0,12.154,4.424a16.053,16.053,0,0,1,4.174,7.51l-8.148,1.946a8.423,8.423,0,0,0-8.6-6.841,9.284,9.284,0,0,0-7.362,3.254q-2.82,3.257-2.82,10.544,0,7.732,2.779,11.014a9.026,9.026,0,0,0,7.234,3.285,8.259,8.259,0,0,0,5.65-2.084A12.145,12.145,0,0,0,158.149,106.333Z" transform="translate(-130.608 -79.849)" fill="#fff"/>
        <path id="Path_634" data-name="Path 634" d="M138.619,101.188a25.891,25.891,0,0,1,1.865-10.463,19.16,19.16,0,0,1,3.8-5.589,15.877,15.877,0,0,1,5.272-3.673,22.347,22.347,0,0,1,8.792-1.614q9.012,0,14.422,5.594t5.415,15.551q0,9.878-5.369,15.454t-14.355,5.579q-9.1,0-14.468-5.548T138.619,101.188Zm8.486-.281q0,6.927,3.2,10.5a11,11,0,0,0,16.2.031q3.157-3.548,3.157-10.641,0-7.012-3.075-10.463a11.389,11.389,0,0,0-16.368.041Q147.1,93.872,147.1,100.907Z" transform="translate(-97.693 -79.849)" fill="#fff"/>
    </g>
    <g id="Group_1950" data-name="Group 1950" transform="translate(150.697 83.514)">
        <path id="Path_635" data-name="Path 635" d="M165.389,105.79v4.741H147.5a12.356,12.356,0,0,1,1.742-5.093,33.89,33.89,0,0,1,5.742-6.386,36.154,36.154,0,0,0,4.23-4.358,5.588,5.588,0,0,0,1.052-3.121,3.548,3.548,0,0,0-.914-2.626,3.892,3.892,0,0,0-5.078.046,4.952,4.952,0,0,0-1.088,3.193L148.1,91.68q.46-4.215,2.856-6.049a9.568,9.568,0,0,1,5.993-1.834,8.669,8.669,0,0,1,6.192,2.125,6.967,6.967,0,0,1,2.253,5.288,9.22,9.22,0,0,1-.644,3.423,14.117,14.117,0,0,1-2.043,3.4,32.806,32.806,0,0,1-3.341,3.4c-1.614,1.476-2.631,2.457-3.06,2.943a8.586,8.586,0,0,0-1.047,1.415Z" transform="translate(-147.498 -83.797)" fill="#fff"/>
    </g>
    </SvgIcon>
  );
};

export default Co2SVG;
