import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import ModalFormHeader from "../../../common/ModalComponents/ModalFormHeader";
import SensorIcon from '../../../../img/icons/SensorIcon'

//MUI
import { Grid, makeStyles } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    sensorSelectionButton: {
      width: "40%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: "1rem",
      },
    },
    listItems: {
        borderRadius: '5px',
        width: '100%',
        height: '120px'
    },
    sensorImage: {
        width: '25%',
        margin: 'auto',
        height: '100%'
    },
    deviceName : {
        fontWeight: 700
    },
    cardDivider: {
        height: '80%',
        marginTop: '2%'
    },
    selectFamily: {
        borderRadius: 4,
        width: '100%',
        backgroundColor:'#FFF',
        border: '1px solid #ced4da'
      },
    sensorInfo:{
        width: '75%'
    }
  }));

export default function SensorModel({ sensorsList, handleSelectSensor, gateways, selection }){
    const defaultFilter = 'All Devices'

    const [filter, setFilter] = useState(defaultFilter)
    const [sensors, setSensors] = useState(sensorsList)
    const [sensorGroups, setSensorGroups] = useState([])
    const classes = useStyles();

    const handleChange = (event) => {
        setFilter(event.target.value)
    }

    useEffect(()=>{
        if(filter === '' || filter === defaultFilter){
            setSensors(sensorsList)
        } else {
            let filtered = _.filter(sensorsList, (o)=>{return o.brand === filter})
            setSensors(filtered)
        }
    },[filter])

    useEffect(()=>{
        let mappedBrands = _.uniq(_.map(sensorsList, 'brand'))
        mappedBrands.unshift(defaultFilter)
        setSensorGroups(mappedBrands)
        // setSensorGroups(_.uniq(_.map(sensorsList, 'brand')))
    }, [sensorsList])

    const handleClick = (item, disabled) => {
        if(!disabled){
            handleSelectSensor(item)
        }
    }

    const deviceCard = (device, disabledCheck) => {
        let isSelected = (selection === device)
        return (
            <ListItem
                button
                selected={isSelected}
                onClick={(e)=>{handleClick(device, disabledCheck)}}
                disabled={disabledCheck}
                className={classes.listItems , isSelected ? 'itemSelected' : '' }
            >
                <Card className={classes.root}>
                    {device.image ? 
                        <CardMedia
                            component="img"
                            className={classes.sensorImage}
                            image={device.image}
                        />
                        :
                        <SensorIcon className={classes.sensorImage} />                
                    }
                    <Divider className={classes.cardDivider} orientation="vertical"  />
                    <CardContent className={classes.sensorInfo}>
                        <Typography className={classes.deviceName}>{device.name}</Typography>
                        <Typography>{device.description}</Typography>
                    </CardContent>
                </Card>
            </ListItem>
            )
    }

    return (
        <Grid item xs={12}>
        <ModalFormHeader
          header="Select the Sensor Model that you want to add"
          desc="Select the Sensor model below to begin the process of adding it into the
          MiSensor Platform."
        />
        <Grid item xs={12}>
            <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={filter}
            onChange={handleChange}
            className={classes.selectFamily}
            >
            {sensorGroups.map(item =>{
                return (
                    <MenuItem value={item}>{item}</MenuItem>
                )
            })}
            </Select>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 25 }}>
          <Grid>
            <List >
                {sensors.map(item => {
                    let disabledCheck = false;
                    for(let i =0; i < gateways.length; i++){
                        if("Puck" === item.brand){
                            disabledCheck = true
                        }
                    }
                    return deviceCard(item, disabledCheck)
                })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    )
};