import _ from 'lodash';

import { convertToC, convertToHPa } from '../../../conversions';
import updateOne from '../../common/api/updateOne';

export async function saveAlertChange(
  editType,
  alertInfo,
  appState,
  changedValues,
  alertType = null,
) {
  const initData = setInitDataObj(alertInfo, appState);
  let data;

  if (editType === 'alert') {
    data = setDataObj(initData, alertType, changedValues, appState);
  } else if (editType === 'sensor') {
    data = { ...initData, selectedSensors: changedValues };
  } else if (editType === 'disabledSensors') {
    data = {
      ...initData,
      disabledSensors: changedValues[0],
      selectedSensors: changedValues[1],
    };
  } else if (editType === 'user' || editType === 'customRecipients') {
    // removes users with no values
    _.remove(changedValues, o => {
      if (!o.name && !o.phone && !o.email) return o;
    });
    data = { ...initData, selectedUsers: changedValues };
  }

  const json = await updateOne('alert', data, appState.auth.token);
  if (json.success) return true;
  else return json.errors;
}

const setNotificationSettings = type => {
  const settings = { email: false, text: false };
  switch (type) {
    case 1:
      settings.email = true;
      break;
    case 2:
      settings.text = true;
      break;
    case 3:
      settings.email = true;
      settings.text = true;
      break;
    default:
      break;
  }
  return settings;
};

const setNotificationAlertType = count => {
  if (count === 1) return 'single';
  if (count > 1) return 'continuous';
};

const setSelectedSensors = sensors => {
  const sensorArr = [];
  for (const sensor of sensors) {
    sensorArr.push(sensor.sensorId);
  }
  return sensorArr;
};

const setNotificationInterval = time => {
  let interval = 5;
  if (time !== 'none') {
    interval = time / 60 / 1000;
  }
  return interval;
};

const setEnableDoorAlert = door => {
  let doorAlert = 'none'; // 0 case
  if (door === 1) doorAlert = 'open_alert';
  if (door === 2) doorAlert = 'close_alert';
  return doorAlert;
};

const setRangeType = typeNum => {
  let typeStr;
  switch (typeNum) {
    case 1:
      typeStr = 'above_value';
      break;
    case 2:
      typeStr = 'below_value';
      break;
    case 3:
      typeStr = 'outside_range';
      break;
    case 4:
      typeStr = 'inside_range';
      break;
    default:
      //5
      typeStr = 'split_value';
      break;
  }
  return typeStr;
};

const setDataObj = (init, updated, changedVal, appState) => {
  const data = _.clone(init);

  if (updated === 'temp_environment' && appState.userSettings.tempType === 'F') {
    changedVal.rangeValues[0] = convertToC(changedVal.rangeValues[0]);
    changedVal.rangeValues[1] = convertToC(changedVal.rangeValues[1]);
  }

  if (updated === 'pressure' && appState.userSettings.pressType === 'PSI') {
    // we save always save temp as hPa
    changedVal.rangeValues[0] = convertToHPa(changedVal.rangeValues[0]);
    changedVal.rangeValues[1] = convertToHPa(changedVal.rangeValues[1]);
  }

  if (updated.includes('door')) {
    data.enableDoorAlert = changedVal.rangeType;
  } else if (updated === 'light_detection') {
    data[`lightRangeType`] = changedVal.rangeType;
    data.firstLightThresholdLow = changedVal.firstLightThresholdLow;
    data.firstLightThresholdHigh = changedVal.firstLightThresholdHigh;
    data.secondLightThresholdLow = changedVal.secondLightThresholdLow;
    data.secondLightThresholdHigh = changedVal.secondLightThresholdHigh;
  } else {
    data[`${updated}RangeType`] = changedVal.rangeType;
    switch (changedVal.rangeType) {
      case 'below_value':
        data[`${updated}ThresholdLow`] = changedVal.rangeValues[1];
        data[`${updated}ThresholdHigh`] = changedVal.rangeValues[1];
        break;
      case 'above_value':
        data[`${updated}ThresholdLow`] = changedVal.rangeValues[0];
        data[`${updated}ThresholdHigh`] = changedVal.rangeValues[0];
        break;
      default:
        // inside/outside range
        data[`${updated}ThresholdLow`] = changedVal.rangeValues[0];
        data[`${updated}ThresholdHigh`] = changedVal.rangeValues[1];
    }
  }

  return data;
};

const setInitDataObj = (alertInfo, appState) => {
  const initData = {};
  const parsedAlert = JSON.parse(alertInfo.alert);

  const { fname, lname } = appState.auth.userInfo;
  const {
    notificationCount: notificationAlertNumber,
    notificationInterval: notificationAlertTime,
    notificationType,
    users: { selectedUsers },
  } = parsedAlert;

  const {
    alert_name: alertName,
    sensorsAssignedToAlert,
    companies_company_id: companyId,
    users_user_id: userId,
    alert_id,
  } = alertInfo;

  if (parsedAlert.temp_environment) {
    const {
      temp_environment: {
        enableTemperatureAlert,
        temperatureRangeType: temperatureRangeNum,
        temperatureScaleType,
        temperatureThresholdHigh,
        temperatureThresholdLow,
      },
    } = parsedAlert;
    const temperatureRangeType = setRangeType(temperatureRangeNum);

    _.assign(initData, {
      temperatureRangeType,
      temperatureScaleType,
      enableTemperatureAlert,
      temperatureThresholdLow,
      temperatureThresholdHigh,
    });
  }

  if (parsedAlert.pressure) {
    const {
      pressure: {
        enablePressureAlert,
        pressureThresholdLow,
        pressureThresholdHigh,
        pressureRangeType: pressureRangeNum,
      },
    } = parsedAlert;
    const pressureRangeType = setRangeType(pressureRangeNum);

    _.assign(initData, {
      pressureRangeType,
      enablePressureAlert,
      pressureThresholdLow,
      pressureThresholdHigh,
    });
  }

  if (parsedAlert.humidity) {
    const {
      humidity: {
        enableHumidityAlert,
        humidityThresholdLow,
        humidityThresholdHigh,
        humidityRangeType: humidityRangeNum,
      },
    } = parsedAlert;
    const humidityRangeType = setRangeType(humidityRangeNum);

    _.assign(initData, {
      humidityRangeType,
      enableHumidityAlert,
      humidityThresholdLow,
      humidityThresholdHigh,
    });
  }

  if (parsedAlert.light) {
    const {
      light: {
        enableLightAlert,
        firstLightThresholdLow,
        firstLightThresholdHigh,
        secondLightThresholdLow,
        secondLightThresholdHigh,
        lightRangeType: lightRangeNum,
      },
    } = parsedAlert;
    const lightRangeType = setRangeType(lightRangeNum);

    _.assign(initData, {
      lightRangeType,
      enableLightAlert,
      firstLightThresholdLow,
      firstLightThresholdHigh,
      secondLightThresholdLow,
      secondLightThresholdHigh,
    });
  }

  if (parsedAlert.door) {
    const enableDoorAlert = setEnableDoorAlert(parsedAlert.door);
    _.assign(initData, { enableDoorAlert });
  }

  if (parsedAlert.panic) {
    _.assign(initData, { enablePanicAlert: parsedAlert.panic });
  }

  if (parsedAlert.impact) {
    _.assign(initData, { enableImpactAlert: parsedAlert.impact });
  }

  const selectedSensors = setSelectedSensors(sensorsAssignedToAlert);
  const notificationSettings = setNotificationSettings(notificationType);
  const notificationInterval = setNotificationInterval(notificationAlertTime);
  const notificationAlertType = setNotificationAlertType(
    notificationAlertNumber,
  );

  return {
    alertId: +alert_id,
    alertName,
    companyId,
    email: notificationSettings.email,
    enableBatteryAlert: false,
    fname,
    lname,
    notificationAlertNumber,
    notificationAlertTime,
    notificationAlertType,
    notificationInterval,
    selectedSensors,
    selectedUsers,
    text: notificationSettings.text,
    userId,
    ...initData,
  };
};
