import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import fetch from "isomorphic-fetch";

const renderReactDom = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

if (!window.fetch) {
  window.fetch = fetch;
}

const fallbackToStore = () => {
  window.location.replace("market://details?id=com.dcs.misensors");
};

const openApp = () => {
  let path = window.location.pathname;
  let params = window.location.search;
  let pathParams = `${path}${params}`;
  let fullURL = "/";
  if (pathParams === "/" || pathParams === "") {
    fullURL = "misensors://";
    // window.location.replace(fullURL);
    window.location.href =
      "intent://#Intent;scheme=misensors;package=com.dcs.misensors.client.android;end;";
  } else {
    fullURL = `misensors:/${pathParams}`;
    // window.location.replace(fullURL);
    window.location.href =
      "intent://#Intent;scheme=misensors;package=com.dcs.misensors.client.android;end;";
  }
};

const triggerAppOpen = () => {
  openApp();
  setTimeout(fallbackToStore, 250);
};

// window.onload = () => {
//   if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
//     triggerAppOpen();
//   }
// };


/**
 * 
 * @param {string} reference - Where the log is coming from
*/
window.log = (reference) => {
  const ref = reference;
  return (...args) => {
    if ( localStorage.getItem('flag') === '!QXPM1qzpm') {
      console.log(`${ref} -> `, ...args);
    }
  }
}

if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      renderReactDom();
    },
    false
  );
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
