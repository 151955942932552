export const getDashApi = async (endpoint, token) => {
    try {
      const request = await fetch(
        process.env.REACT_APP_API_URL + endpoint,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      );
      const resp = await request.json();
      if (!resp.success) {
        let errorObj = {};
        resp.errors.forEach(error => {
          errorObj = { ...errorObj, [error.type]: true };
          if (error.type === 'token') {
            return {success: false, errors: ['token']}
          }
        });
      }
      return resp;
    } catch (err) {
      console.log(err)
        return {success: false, errors: ['network']}
    }
  }
