import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { A, navigate } from 'hookrouter';
import AlertIcon from '@material-ui/icons/Announcement';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import grey from '@material-ui/core/colors/grey';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { AlertContext } from './AlertContext';
import { EventBus } from '../common/EventBus';
import BreadCrumb from '../Generic/BreadCrumb';
import SliderTile from '../Generic/SliderTile';
import { AppContext } from './../../AppContext';
import { permissions } from '../../permissions';
import CardActions from '../Generic/CardActions';
import DetailsCard from '../Generic/DetailsCard';
import { determineSimpleLuxNum } from '../common/helpers/calculateLuxDisplay';
import InformationTile from './ManageAlertComp/InformationTile';
import GenericList from '../Dashboard/GenericList';
import ListElement from '../Generic/ListElement';
import TiledCard from '../Generic/TiledCard';
import LoadingSpinner from '../Generic/LoadingSpinner';
import SensorIcon from '../../img/icons/SensorIcon';
import { ManageAlertModal } from './ManageAlertModal';
import DeleteAlertFormModal from './DeleteAlertFormModal';
import { convertToF, convertToPSI } from '../../conversions';
import { AssignedLists } from './ManageAlertComp/AssignedLists';
import NotFoundPageLoggedIn from '../Auth/NotFoundPageLoggedIn';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';
import {
  determineSplitAdv,
  determineSplitSimple,
} from '../common/helpers/calculateLuxDisplay';

import {
  MobileCustomTab,
  MobileCustomTabs,
  MobileTabsContainer,
  MobileTabPanelContainer,
} from '../Generic/Mobile/MobileTabs';

import MobileBottomButton, {
  MobileBottomButtonSpacer,
  MobilePlusButton,
} from '../Generic/Mobile/MobileBottomButton';

import TabPanel from '../Generic/TabPanel';
import CardInfoIcons from '../Generic/CardInfoIcons';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === 'light') {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles(theme => ({
  centerTextColor: {
    textTransform: 'uppercase',
    color: textColor({ theme, lightTone: 500, darkTone: '#FFF' }),
  },
  center: {
    justifyContent: 'center',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  alertCardContainer: {
    height: 143,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
    alignContent: 'flex-start',
  },
  singleCardContainer: {
    [theme.breakpoints.down('xs')]: {},
  },
  mobileTableList: {
    // Total height minus the height of the header and the tabs
    height: 'calc(100vh - 105px)',
    overflow: 'scroll',
    borderRadius: '0px',
    marginTop: '0px',
  },
  tabContainer: {},
  fullLengthCardContainer: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  noPadding: {
    padding: '0px !important',
  },
  centerCards: {
    justifyContent: 'center',
  },
}));

export function ManageAlertsPage(props) {
  const log = window.log('ManageAlertsPage');
  const { id, drawer } = props;
  const classes = useStyles();
  const theme = useTheme();
  /**
   * Mobile specific components and states
   */
  const [mobileTabsValue, setMobileTabsValue] = useState(0);

  const mobileTabsChange = (event, newValue) => {
    setMobileTabsValue(newValue);
  };

  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of Mobile specific components and states
   */

  const {
    snack,
    appState,
    setAppState,
    getAlertCount,
    setAlertCount,
    openAlertModal,
    setLogoutState,
    setOpenAlertModal,
    setOpenDeleteAlertModal,
    setOpenAddingModal,
    darkModeContainerColor,
  } = useContext(AppContext);
  const {
    getUsers,
    alertInfo,
    modalState,
    saveValueChange,
    setModalState,
    setAlertInfo,
    triggerFromContext,
  } = useContext(AlertContext);

  const [sensorProfile, setSensorProfile] = useState({
    temp_environment: false,
    humidity: false,
    pressure: false,
    motion_detection: false,
    motion_detection_advanced_X: false,
    motion_detection_advanced_Y: false,
    motion_detection_advanced_Z: false,
    light_detection: false,
    panic_button: false,
    proximity: false,
    open_door: false,
    battery: false,
    temp_pcb: false,
    water: false,
    iaq: false,
    voc: false,
    co2: false
  });
  const [tempSlider, setTempSlider] = useState({
    subtitle: '-',
    minimumValue: -100,
    maximumValue: 100,
    unitMeasure: '°C',
    isRangeType: false,
    value: [0, 0],
  });
  const [pcbTempSlider, setPcbTempSlider] = useState({
    subtitle: '-',
    minimumValue: -100,
    maximumValue: 100,
    unitMeasure: '°C',
    isRangeType: false,
    value: [0, 0],
  });
  const [waterSlider, setWaterSlider] = useState({
    // subtitle: '-',
    value: 0
  });
  const [iaqSlider, setIaqSlider] = useState({
    subtitle: '-',
    minimumValue: 0,
    maximumValue: 1000,
    unitMeasure: 'ppm',
    isRangeType: false,
    value: [0, 0],
  });
  const [vocSlider, setVocSlider] = useState({
    subtitle: '-',
    minimumValue: 0,
    maximumValue: 1000,
    unitMeasure: 'ppm',
    isRangeType: false,
    value: [0, 0],
  });
  const [co2Slider, setCo2Slider] = useState({
    subtitle: '-',
    minimumValue: 0,
    maximumValue: 1000,
    unitMeasure: 'ppm',
    isRangeType: false,
    value: [0, 0],
  });
  const [humidSlider, setHumidSlider] = useState({
    subtitle: '-',
    minimumValue: 0,
    maximumValue: 100,
    unitMeasure: '%',
    isRangeType: false,
    value: [0, 0],
  });
  const [pressureSlider, setPressureSlider] = useState({
    subtitle: '-',
    minimumValue: 0,
    maximumValue: 2000,
    unitMeasure: 'hPa',
    isRangeType: false,
    value: [0, 0],
  });
  const [lightSlider, setLightSlider] = useState({
    subtitle: '-',
    minimumValue: 0.01,
    maximumValue: 64000,
    unitMeasure: 'lux',
    isRangeType: false,
    value: [0, 0],
  });
  const [motionSlider, setMotionSlider] = useState({
    subtitle: '-',
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: 'g',
    isRangeType: false,
    value: [0, 0],
  });
  const [motionXSlider, setMotionXSlider] = useState({
    subtitle: '-',
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: 'g',
    isRangeType: false,
    value: [0, 0],
  });
  const [motionYSlider, setMotionYSlider] = useState({
    subtitle: '-',
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: 'g',
    isRangeType: false,
    value: [0, 0],
  });
  const [motionZSlider, setMotionZSlider] = useState({
    subtitle: '-',
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: 'g',
    isRangeType: false,
    value: [0, 0],
  });
  const [doorSlider, setDoorSlider] = useState({
    value: 0,
  });

  const [alertName, setAlertName] = useState('-');
  const [lastUpdated, setLastUpdated] = useState('-');
  const [sensorCount, setSensorCount] = useState('-');
  const [alertCondition, setAlertCondition] = useState({});
  const [pairedSensors, setPairedSensors] = useState([]);

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  useEffect(() => {
    fetchAlert();
    getUsers();
  }, [
    openAlertModal,
    triggerFromContext,
    appState.userSettings,
    appState.auth.userInfo,
  ]);

  useEffect(() => {
    if (initialPageLoad) return;
    setTimeout(() => {
      fetchAlert();
      getUsers();
    }, 500);
  }, [drawer]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    setAppState({ ...appState, isSwitched: false });
    navigate('/alert/all');
  }, [appState.isSwitched]);

  //opens the model for adding a user or sensor
  const mobileAddByType = type => {
    setModalState({
      ...modalState,
      editType: type,
      isOpen: true,
    });
  };

  const fetchAlert = async () => {
    try {
      //Get the UTC offset
      let date = new Date();
      let UTCOffset = date.getTimezoneOffset();

      log('Looking at id ' + id);
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/alert/get?alertId=${id}&Offset=${UTCOffset}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();
      log('json:', json);

      if (json.success) {
        log('Looking at sensor profile ', json.data.alert[0].sensorProfile);

        try {
          const parsedAlertCondition = JSON.parse(json.data.alert[0].alert);
          setAlertCondition(parsedAlertCondition);
        } catch (err) {
          log('Error parsing alert condition');
        }

        const alertObj = json.data.alert[0];

        setAlertInfo(alertObj);
        setSensorProfile(alertObj.sensorProfile);
        if (alertObj.sliders.tempSlider) {
          let alertObjTempSlider = alertObj.sliders.tempSlider;

          alertObjTempSlider.unitMeasure = `°${appState.userSettings.tempType}`;
          //Convert the Fahrenheit and set maxTemp slider to 85 Cels
          if (appState.userSettings.tempType === 'F') {
            alertObjTempSlider.maximumValue = 185;
          }

          alertObjTempSlider.value[0] = convertToF(
            alertObjTempSlider.value[0],
            appState.userSettings.tempType,
          );
          alertObjTempSlider.value[1] = convertToF(
            alertObjTempSlider.value[1],
            appState.userSettings.tempType,
          );

          setTempSlider(alertObjTempSlider);
        }

        if (alertObj.sliders.pcbTempSlider) {
          setPcbTempSlider(alertObj.sliders.pcbTempSlider);
        }
        if (alertObj.sliders.vocSlider) {
          setVocSlider(alertObj.sliders.vocSlider);
        }
        if (alertObj.sliders.iaqSlider) {
          setIaqSlider(alertObj.sliders.iaqSlider);
        }
        if (alertObj.sliders.co2Slider) {
          setCo2Slider(alertObj.sliders.co2Slider);
        }
        if (alertObj.sliders.waterSlider) {
          setWaterSlider(alertObj.sliders.waterSlider);
        }

        if (alertObj.sliders.humidSlider) {
          setHumidSlider(alertObj.sliders.humidSlider);
        }

        if (alertObj.sliders.pressureSlider) {
          let alertObjPressSlider = alertObj.sliders.pressureSlider;

          //Convert pressure to PSI
          alertObjPressSlider.unitMeasure = appState.userSettings.pressType;

          alertObjPressSlider.maximumValue = convertToPSI(
            alertObjPressSlider.maximumValue,
            appState.userSettings.pressType,
          );

          alertObjPressSlider.value[0] = convertToPSI(
            alertObjPressSlider.value[0],
            appState.userSettings.pressType,
          );
          alertObjPressSlider.value[1] = convertToPSI(
            alertObjPressSlider.value[1],
            appState.userSettings.pressType,
          );

          setPressureSlider(alertObjPressSlider);
        }
        if (alertObj.sliders.lightSlider) {
          const { lightSlider } = alertObj.sliders;
          // Converts values to simple lux values
          if (!appState.userSettings.advancedLux) {
            lightSlider.minimumValue = determineSimpleLuxNum(
              lightSlider.minimumValue,
            );
            lightSlider.maximumValue = determineSimpleLuxNum(
              lightSlider.maximumValue,
            );
            lightSlider.unitMeasure = 'LuxSimple';

            let simpleVals;
            if (_.isArray(lightSlider.value[0])) {
              const simpleArr1 = determineSplitSimple(lightSlider.value[0]);
              const simpleArr2 = determineSplitSimple(lightSlider.value[1]);

              simpleVals = [simpleArr1, simpleArr2];
            } else {
              simpleVals = _.map(lightSlider.value, val =>
                determineSimpleLuxNum(val),
              );
            }

            lightSlider.value = simpleVals;
          } else {
            if (_.isArray(lightSlider.value[0])) {
              lightSlider.value = determineSplitAdv(
                lightSlider.value,
                lightSlider.subtitle,
              );
            }
          }

          setLightSlider(lightSlider);
        }
        if (alertObj.sliders.doorSlider) {
          setDoorSlider(alertObj.sliders.doorSlider);
        }
        if (alertObj.sliders.motionSlider) {
          const motionObj = alertObj.sliders.motionSlider;
          const motionX = motionObj['motion_detection_advanced_X'];
          const motionY = motionObj['motion_detection_advanced_Y'];
          const motionZ = motionObj['motion_detection_advanced_Z'];
          let motion = null;
          //Check if we should toggle basic or advanced setting
          if (
            motionX.subtitle === motionY.subtitle &&
            motionX.subtitle === motionZ.subtitle &&
            motionX.value[0] === motionY.value[0] &&
            motionX.value[1] === motionZ.value[1] &&
            motionX.value[0] === motionZ.value[0] &&
            motionX.value[1] === motionZ.value[1]
          ) {
            //Pass
            motion = motionX;
          } else {
            motion = {
              subtitle: '-',
              minimumValue: -50,
              maximumValue: 50,
              unitMeasure: 'g',
              isRangeType: false,
              value: [0, 0],
            };
            setSensorProfile(prevState => {
              return {
                ...prevState,
                motion_detection_advanced_X: true,
                motion_detection_advanced_Y: true,
                motion_detection_advanced_Z: true,
              };
            });
          }
          setMotionSlider(motion);
          setMotionXSlider(motionX);
          setMotionYSlider(motionY);
          setMotionZSlider(motionZ);
        }
        setAlertName(alertObj.alertName);
        setLastUpdated(alertObj.lastUpdatedTime);
        setSensorCount(alertObj.sensorCount);
        setPairedSensors(alertObj.sensorsAssignedToAlert);
        getAlertCount();
        setAlertCount(alertObj.alertCount);
      } else {
        setAlertInfo({});
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }

    setInitialPageLoad(false);
  };

  const renderLastUpdatedMobile = () => {
    if (!lastUpdated || lastUpdated === '-') return '-';
    let userTimeZone = '';
    let time = '';
    if (Intl?.DateTimeFormat()?.resolvedOptions()) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    time = moment.tz(lastUpdated, userTimeZone).format('MM/DD/YYYY');

    return time;
  };

  const renderLastUpdated = () => {
    if (!lastUpdated || lastUpdated === '-') return '-';
    let userTimeZone = '';
    let time = '';
    if (Intl?.DateTimeFormat()?.resolvedOptions()) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (appState.userSettings?.timeFormat === '12hr') {
      time = moment
        .tz(lastUpdated, userTimeZone)
        .format('ddd MMM DD YYYY hh:mm:ssa z');
    } else {
      time = moment
        .tz(lastUpdated, userTimeZone)
        .format('ddd MMM DD YYYY kk:mm:ss z');
    }

    return time;
  };

  const configRangeCards = () => {
    const cards = defaultSensorProfile(theme, undefined, false, 60);
    _.forEach(cards, card => {
      card.title.toUpperCase();
      card.underlineColor = theme.palette.primary.main;

      switch (card.name) {
        case 'temp_environment':
          card.subtitle = tempSlider.subtitle;
          card.slider = tempSlider;
          card.enabled = sensorProfile.temperature;
          break;
        case 'iaq':
          card.subtitle = iaqSlider.subtitle;
          card.slider = iaqSlider;
          card.enabled = sensorProfile.iaq;
          break;
        case 'voc':
          card.subtitle = vocSlider.subtitle;
          card.slider = vocSlider;
          card.enabled = sensorProfile.voc;
          break;
        case 'eco2':
          card.subtitle = co2Slider.subtitle;
          card.slider = co2Slider;
          card.enabled = sensorProfile.eco;
          break;
        case 'temp_pcb':
          card.subtitle = pcbTempSlider.subtitle;
          card.slider = pcbTempSlider;
          card.enabled = sensorProfile.pcbTemp;
          break;
        case 'water':
          card.enabled = sensorProfile.water;
          card.centerText = 'You will receive alerts when water is detected';
          break;
        case 'humidity':
          card.subtitle = humidSlider.subtitle;
          card.slider = humidSlider;
          card.enabled = sensorProfile.humidity;
          break;
        case 'pressure':
          card.subtitle = pressureSlider.subtitle;
          card.slider = pressureSlider;
          card.enabled = sensorProfile.pressure;
          break;
        case 'light_detection':
          card.subtitle = lightSlider.subtitle;
          card.slider = lightSlider;
          card.enabled = sensorProfile.light_detection;
          break;
        case 'panic_button':
          card.enabled = sensorProfile.panic_button;
          card.centerText = 'You will receive alerts when button is pressed';
          break;
        case 'motion_detection':
          card.enabled = sensorProfile.motion_detection;
          card.centerText =
            'You will receive alerts when the device detects 192 milli-g on any axis.';
          break;
        case 'door_open_close':
          card.enabled = sensorProfile.open_door;
          card.centerText =
            doorSlider.value === 1
              ? 'You will receive alerts when door is opened'
              : doorSlider.value === 2
              ? 'You will receive alerts when door is closed'
              : ''; //0 - Not enabled, 1 - Opened, 2 - Closed
          card.doorAlertValue =
            doorSlider.value === 1 ? 'open_alert' : 'close_alert';
          break;
        default:
          // battery
          card.subtitle = tempSlider.battery;
          card.centerText = 'You will receive alerts when battery is low';
          break;
      }
    });
    return cards;
  };

  const handleOpenEditAlertModal = () => {
    EventBus.dispatch('editAlertModalProps', {
      alertId: id,
      alertName: alertName,
      alertSliders: alertSliders(),
      pairedSensors: pairedSensors,
      alertCondition: alertCondition,
      editAlertProfiles: sensorProfile,
    });
    setOpenAlertModal(true);
  };

  const handleOpenDeleteAlertModal = () => {
    log('Delete click');
    setOpenDeleteAlertModal(true);
  };

  const alertSliders = () => {
    return {
      temp_environment: tempSlider,
      humidity: humidSlider,
      pressure: pressureSlider,
      light_detection: lightSlider,
      motion_detection: motionSlider,
      motion_detection_advanced_X: motionXSlider,
      motion_detection_advanced_Y: motionYSlider,
      motion_detection_advanced_Z: motionZSlider,
      temp_pcb: pcbTempSlider,
      voc: vocSlider,
      iaq: iaqSlider,
      eco2: co2Slider
    };
  };

  let breadcrumbs = (
    <div style={{ display: 'inline-flex' }}>
      <A className={classes.breadcrumbs} href='/alert/all'>
        Alerts
      </A>
      <div>{'\xa0-\xa0'}</div>
    </div>
  );

  const cards = () => {
    const cardsArr = [];
    const rangeCards = configRangeCards();
    rangeCards.forEach(card => {
      if (card.enabled) {
        const cardObj = {
          item: card,
          type: 'information',
        };

        if (card.slider) cardObj.type = 'range';
        if (card?.subtitle?.includes('Split')) cardObj.type = 'split';

        cardsArr.push(cardObj);
      }
    });

    if (cardsArr.length) {
      cardsArr[cardsArr.length - 1].lastOne = cardsArr.length % 2 !== 0;
    }

    return cardsArr;
  };

  const { allUsers } = useContext(AlertContext);

  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
    setTimeout(() => getAssignedUsers(), 500);
  }, [allUsers, alertInfo]);

  const getAssignedUsers = () => {
    if (alertInfo.alert) {
      const sensorUsers = JSON.parse(alertInfo.alert).users.selectedUsers;

      // need to configure user with first name, last name, disabled notifications
      _.forEach(sensorUsers, user => {
        // Custom Recipient
        if (user.name) {
          const nameArr = user.name.split(' ');
          user['fname'] = nameArr[0];
          user['lname'] = nameArr[1] || '';
        }
        // User
        if (user.id) {
          const foundUser = _.find(allUsers, { id: user.id });
          if (foundUser) {
            user['fname'] = foundUser.fname || '';
            user['lname'] = foundUser.lname || '';
          }
        }

        user['disabledNotifcations'] =
          user['disabledNotifcations'] === undefined ||
          user['disabledNotifcations'] === false
            ? false
            : true;
      });
      setAssignedUsers(sensorUsers);
    }
  };

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  function deleteItem(index, type) {
    if (type === 'user') {
      _.pullAt(assignedUsers, index);
      saveValueChange('user', assignedUsers);
    }
    if (type === 'device') {
      _.pullAt(alertInfo.sensorsAssignedToAlert, index);
      let idStore = _.map(
        alertInfo.sensorsAssignedToAlert,
        sensor => sensor.sensorId,
      );
      saveValueChange('sensor', idStore);
    }
  }

  const deleteItemButton = (index, type) => (
    <IconButton data-testid='sensors_edit_button' id='sensors_edit_button'>
      <DeleteForeverIcon onClick={() => deleteItem(index, type)} />
    </IconButton>
  );

  return alert ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div>
        <ManageAlertModal />
        <DeleteAlertFormModal alertId={id} />
        {isMobileSize ? (
          <div
            style={{
              overflow: 'hidden',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.tabContainer}>
                {/* Table tabs selector */}
                <MobileTabsContainer>
                  <MobileCustomTabs
                    value={mobileTabsValue}
                    centered
                    onChange={mobileTabsChange}
                    aria-label='alert_sensors_table_tabs'
                    indicatorColor='primary'
                    textColor='primary'
                  >
                    <MobileCustomTab label='Alert Settings' />
                    <MobileCustomTab label='Assigned Sensors' />
                    <MobileCustomTab label='Assigned Users' />
                  </MobileCustomTabs>
                </MobileTabsContainer>
              </Grid>
              <Grid
                item
                xs={12}
                spacing={0}
                style={{
                  height: 'calc(100vh - 104px)',
                  overflow: mobileTabsValue === 0 ? 'auto' : 'hidden',
                }}
              >
                <MobileTabPanelContainer>
                  <TabPanel value={mobileTabsValue} index={0}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TiledCard
                          gutterBottom={true}
                          backgroundColor={
                            darkModeContainerColor() ? '#424242' : '#FFF'
                          }
                          title={'Alert Name: ' + alertName}
                          subtitles={[
                            'Last Updated: ' + renderLastUpdatedMobile(),
                            '',
                          ]}
                          trailingItem={
                            <CardInfoIcons
                              displayDivider={true}
                              isDict={false}
                              items={[
                                {
                                  icon: '',
                                },
                                {
                                  value: sensorCount,
                                  icon: (
                                    <SensorIcon
                                      color='primary'
                                      style={{ fontSize: 32 }}
                                    />
                                  ),
                                },
                              ]}
                              enableZero={true}
                            />
                          }
                        >
                          {permissions[appState?.auth?.userInfo?.perm]
                            ?.displayManageAlert && (
                            <CardActions
                              editEvent={() => {
                                handleOpenEditAlertModal();
                              }}
                              deleteEvent={() => {
                                handleOpenDeleteAlertModal();
                              }}
                            />
                          )}
                        </TiledCard>
                      </Grid>
                      <Grid item xs={12} container>
                        {cards().map((card, index) => {
                          return (
                            <Grid item key={card} xs={12}>
                              <InformationTile
                                card={card}
                                entityName={card.item.title}
                                entitySubtitle={card.item.subtitle}
                                underlineColor={card.item.underlineColor}
                                icon={card.item.icon}
                                tileStyle
                              >
                                <Box
                                  fontWeight='fontWeightMedium'
                                  className={clsx(
                                    classes.centerTextColor,
                                    classes.center,
                                  )}
                                  display='flex'
                                  justifyContent='center'
                                  style={{ position: 'relative' }}
                                >
                                  {card.type === 'range' ||
                                  card.type === 'split' ? (
                                    <SliderTile
                                      drawer={drawer}
                                      unitMeasure={card.item.slider.unitMeasure}
                                      values={card.item.slider.value}
                                      isRangeType={card.item.slider.isRangeType}
                                      maximumValue={
                                        card.item.slider.maximumValue
                                      }
                                      minimumValue={
                                        card.item.slider.minimumValue
                                      }
                                      type={card.item.subtitle}
                                    />
                                  ) : (
                                    <Box
                                      fontWeight='fontWeightMedium'
                                      className={clsx(
                                        classes.centerTextColor,
                                        classes.center,
                                      )}
                                      display='flex'
                                      justifyContent='center'
                                    >
                                      <p>{card.item.centerText}</p>
                                    </Box>
                                  )}
                                </Box>
                              </InformationTile>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <MobileBottomButtonSpacer />
                      <MobileBottomButton onClick={openAddModal} />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={mobileTabsValue} index={1}>
                    <GenericList
                      showActions={false}
                      className={classes.mobileTableList}
                      data={pairedSensors}
                      emptyBuilder={() => (
                        <ListElement
                          index={0}
                          title={
                            <Typography
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                fontWeight: 'bold',
                                fontSize: 16,
                                color:
                                  theme.palette.type === 'light'
                                    ? grey[500]
                                    : '#FFF',
                              }}
                            >
                              No Matching Sensors
                            </Typography>
                          }
                        />
                      )}
                      itemBuilder={(item, index) => (
                        <ListElement
                          index={index}
                          title={item.sensorName}
                          subtitle={item.locationName}
                          titleIsBold={true}
                          leftSideItems={deleteItemButton(index, 'device')}
                        />
                      )}
                      useItemBuilder={true}
                    />
                    <MobileBottomButtonSpacer />
                    <MobilePlusButton
                      onClick={() => {
                        mobileAddByType('sensor');
                      }}
                    />
                  </TabPanel>
                  <TabPanel value={mobileTabsValue} index={2}>
                    <GenericList
                      showActions={false}
                      className={classes.mobileTableList}
                      data={assignedUsers}
                      emptyBuilder={() => (
                        <ListElement
                          index={0}
                          title={
                            <Typography
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                fontWeight: 'bold',
                                fontSize: 16,
                                color:
                                  theme.palette.type === 'light'
                                    ? grey[500]
                                    : '#FFF',
                              }}
                            >
                              No Matching Users
                            </Typography>
                          }
                        />
                      )}
                      itemBuilder={(item, index) => (
                        <ListElement
                          index={index}
                          title={`${item.fname} ${item.lname}`}
                          subtitle={item.email}
                          titleIsBold={true}
                          leftSideItems={deleteItemButton(index, 'user')}
                        />
                      )}
                      useItemBuilder={true}
                    />
                    <MobilePlusButton
                      onClick={() => {
                        mobileAddByType('user');
                      }}
                    />
                  </TabPanel>
                </MobileTabPanelContainer>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <BreadCrumb
              breadcrumbs={breadcrumbs}
              leadingIcon={
                <AlertIcon style={{ fontSize: 32 }} color='primary' />
              }
              title={`${alertName}`}
            />

            <Grid
              style={{ marginTop: '1rem' }}
              container
              className={`${cards().length === 1 ? classes.centerCards : null}`}
            >
              <Grid item xs={12} className={classes.alertCardContainer}>
                <DetailsCard
                  singleAlertCard={true}
                  enableZero={true}
                  showAvatar={false}
                  name={`Alert Name: ${alertName}`}
                  buttons={
                    permissions[appState?.auth?.userInfo?.perm]
                      ?.displayManageAlert && (
                      <CardActions
                        editEvent={() => {
                          handleOpenEditAlertModal();
                        }}
                        deleteEvent={() => {
                          handleOpenDeleteAlertModal();
                        }}
                        disableDelete={sensorProfile.battery}
                      />
                    )
                  }
                  subtitle={`Last Updated:`}
                  subtitle2={`${renderLastUpdated()}`}
                  trailingItems={[
                    {
                      icon: '',
                    },
                    {
                      value: sensorCount,
                      icon: (
                        <SensorIcon color='primary' style={{ fontSize: 32 }} />
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid
                className={`${classes.cardContainer} ${
                  cards().length === 1 ? classes.fullLengthCardContainer : null
                }`}
                style={{ marginTop: '1rem' }}
                container
                item
                spacing={2}
                xs={12}
                sm={8}
              >
                {cards().map((card, index) => {
                  return (
                    <Grid
                    className={`${classes.singleCardContainer} ${
                      cards().length === 1 ? classes.noPadding : null
                    }`}
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={card.lastOne ? 12 : 6}
                  >
                    <InformationTile card={card}>
                      <Box
                        fontWeight='fontWeightMedium'
                        className={clsx(
                          classes.centerTextColor,
                          classes.center,
                        )}
                        display='flex'
                        justifyContent='center'
                        style={{ position: 'relative' }}
                      >
                        {card.type === 'range' || card.type === 'split' ? (
                          <SliderTile
                            drawer={drawer}
                            unitMeasure={card.item.slider.unitMeasure}
                            values={card.item.slider.value}
                            isRangeType={card.item.slider.isRangeType}
                            maximumValue={card.item.slider.maximumValue}
                            minimumValue={card.item.slider.minimumValue}
                            type={card.item.subtitle}
                          />
                        ) : (
                          <p>{card.item.centerText}</p>
                        )}
                      </Box>
                    </InformationTile>
                  </Grid>
                  )
                  
                })}
              </Grid>
              <AssignedLists cards={cards().length} />
            </Grid>
          </div>
        )}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
}

export default withWidth()(ManageAlertsPage);
