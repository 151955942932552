import _ from 'lodash';
import React from 'react';

import DoorSVG from '../../../icons/DoorSVG';
import LightSVG from '../../../icons/LightSVG';
import PanicSVG from '../../../icons/PanicSVG';
import BatterySVG from '../../../icons/BatterySVG';
import HumiditySVG from '../../../icons/HumiditySVG';
import PressureSVG from '../../../icons/PressureSVG';
import TemperatureSVG from '../../../icons/TemperatureSVG';
import MotionDetectionSVG from '../../../icons/MotionDetectionSVG';
import Co2SVG from '../../../icons/co2SVG'
import VocSVG from '../../../icons/vocSVG'
import IaqSVG from '../../../icons/iaqSVG'
import PcbTempSVG from '../../../icons/pcbTempSVG'
import WaterSVG from '../../../icons/waterSVG'


/**
 * returns the array of icons for sensor profile form
 * @param {*} theme
 */
export const defaultSensorProfile = (
  theme,
  sensorProfile = undefined,
  isAlert = false,
  iconSize = 45,
) => {
  /**
   * keeps values null safe
   * @param {string} title
   * @returns {bool}
   */
  const determineVal = (title, name) => {
    let val = false;
    if (!sensorProfile && title === 'Temperature') val = true;
    if (sensorProfile) {
      let sensorObj = _.find(sensorProfile, { type: title });
      // Some sensor profiles' type are formatted like title, and some are formatted like name
      if (!sensorObj) sensorObj = _.find(sensorProfile, { type: name });
      // the door key is not standardized across platform
      if (!sensorObj && name === 'door_open_close')
        sensorObj = _.find(sensorProfile, { type: 'open_door' });
      if (sensorObj) val = sensorObj.value;
    }

    return val;
  };

  //to disable any of these features in the alert/sensor section, pass in a key named alertDisabled / sensorDisabled set to true
  //to reenable any of the features, delete the disabled key or set it to false
  return [
    {
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Temperature', 'temp_environment'),
      title: 'Temperature',
      name: 'temp_environment',
      description: 'Define when an alert notification will be sent to the recipient.'
    },
    {
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Impact Detection', 'motion_detection'),
      title: 'Impact Detection',
      name: 'motion_detection',
      description: 'Select your impact detection range settings below. You will receive alerts from the system based off these settings.'
    },
    {
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Pressure', 'pressure'),
      title: 'Pressure',
      name: 'pressure',
      description: 'Select your pressure range settings below. You will receive alerts from the system based off these settings.'
    },
    {
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Multi-Function Button', 'panic_button'),
      title: 'Multi-Function Button',
      name: 'panic_button',
      description: 'You will receive alerts when the multi-function button is pressed.'
    },
    {
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Light Detection', 'light_detection'),
      title: 'Light Detection',
      name: 'light_detection',
      description: 'Be certain your baseline sensor is located in the environment you wish to monitor.'
    },
    {
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Door Open/Close', 'door_open_close'),
      title: 'Door Open/Close',
      name: 'door_open_close',
      description: 'Select when you would like to receive your alert from the options below.'
    },
    {
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Humidity', 'humidity'),
      title: 'Humidity',
      name: 'humidity',
      description: 'Define when an alert notification will be sent to the recipient.'
    },
    {
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Battery', 'battery'),
      title: 'Battery',
      name: 'battery',
      description: ''
    },
    {
      icon: (
        <VocSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('VOC', 'voc'),
      title: 'VOC',
      name: 'voc',
      description: 'Air Quality'
    },
    {
      icon: (
        <IaqSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('IAQ', 'iaq'),
      title: 'IAQ',
      name: 'iaq',
      description: 'Indoor Air Quality'
    },
    {
      icon: (
        <PcbTempSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('PCB Temp', 'temp_pcb'),
      title: 'PCB Temp',
      name: 'temp_pcb',
      description: 'PCB Temperature'
    },
    {
      icon: (
        <Co2SVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Co2', 'eco2'),
      title: 'Co2',
      name: 'eco2',
      description: 'Co2 Levels'
    },
    {
      icon: (
        <WaterSVG
          outlinecolor={
            theme.palette.type === 'light'
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={
            isAlert
              ? theme.palette.iconColorAlert.filled
              : theme.palette.iconColor.filled
          }
          style={{ height: iconSize, width: iconSize }}
        />
      ),
      value: determineVal('Water', 'water'),
      title: 'Water',
      name: 'water',
      description: 'Water Detection'
    }
  ];
};
