import React from 'react';

// libraries imports

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Box, Divider, Typography, Tooltip } from '@material-ui/core';
import { NAME_MAP } from '../../constants';

// Icon imports

import TemperatureSVG from '../../icons/TemperatureSVG';
import DoorSVG from '../../icons/DoorSVG';
import PanicSVG from '../../icons/PanicSVG';
import HumiditySVG from '../../icons/HumiditySVG';
import LightSVG from '../../icons/LightSVG';
import MotionDetectionSVG from '../../icons/MotionDetectionSVG';
import PressureSVG from '../../icons/PressureSVG';
import ProximitySVG from '../../icons/ProximitySVG';
import BatterySVG from '../../icons/BatterySVG';
import PcbTempSVG from '../../icons/pcbTempSVG';
import SensorIcon from '../../img/icons/SensorIcon';
import WaterSVG from '../../icons/waterSVG';
import VocSVG from '../../icons/vocSVG';
import Co2SVG from '../../icons/co2SVG';
import IaqSVG from '../../icons/iaqSVG';



export default function ManageAlertSensorCell(props) {
  const theme = useTheme();

  const {
    sensorIcons,
    numberOfAlerts,
    isSmall,
    justifyContent="flex-end",
    showSensorCounter = true,
    size = 33
  } = props;

  const useStyles = makeStyles(theme => ({
    icon: {
      margin: theme.spacing(0, isSmall ? 0.5 : 1),
    },
    divider: {
      backgroundColor: theme.palette.type === 'dark' && '#FFF',
    },
    itemContainer: {
      height: -1,
      lineHeight: 0,
      fontSize: 0,
    },
  }));

  const classes = useStyles();

  const iconsDict = {
    temp_environment: key => (
      <Tooltip title='Temperature' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <TemperatureSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    humidity: key => (
      <Tooltip title='Humidity' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <HumiditySVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    pressure: key => (
      <Tooltip title='Pressure' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <PressureSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    motionDetection: key => (
      <Tooltip title='Impact Detection' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <MotionDetectionSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    lightDetection: key => (
      <Tooltip title='Light Detection' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <LightSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    panicButton: key => (
      <Tooltip title='Multi-Function Button' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <PanicSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    proximity: key => (
      <Tooltip title='Proximity' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <ProximitySVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    openDoor: key => (
      <Tooltip title='Open Door' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <DoorSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    battery: key => (
      <Tooltip title='Battery' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <BatterySVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    temp_pcb: key => (
      <Tooltip title='PCB Temp' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <PcbTempSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    water: key => (
      <Tooltip title='Water' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <WaterSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    eco2: key => (
      <Tooltip title='Co2 Level' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <Co2SVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    voc: key => (
      <Tooltip title='VOC' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <VocSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    ),
    iaq: key => (
      <Tooltip title='IAQ' placement='top' key={key}>
        <div className={classes.itemContainer}>
          <IaqSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === 'light'
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: size, width: size }}
          />
        </div>
      </Tooltip>
    )
  };

  const items = [];
  for (const key in sensorIcons) {
    if (sensorIcons[key]) {
      items.push(
        <Divider
          className={classes.divider}
          key={`divider__${key}`}
          orientation='vertical'
          flexItem
        />,
      );
      let mappedName = NAME_MAP[key]
      if(mappedName){
        items.push(iconsDict[mappedName](`icon__${key}`));
      } else {
        items.push(iconsDict[key](`icon__${key}`));
      }
    }
  }

  if (!isSmall) {
    items.push(
      <Divider
        className={classes.divider}
        key={'__divider__'}
        orientation='vertical'
        flexItem
      />,
    );
  }

  if (showSensorCounter) {
    items.push(
      <Tooltip title='Sensors' placement='top' key='sensors'>
        <div className={classes.itemContainer}>
          <SensorIcon
            key={'__sensor__icon__'}
            className={classes.icon}
            style={{
              fontSize: 32,
              color: theme.palette.primary.main,
            }}
          />
        </div>
      </Tooltip>,
    );
  }
  return (
    <Box
      display='flex'
      justifyContent={justifyContent}
      flexWrap='wrap'
      alignItems='center'
      className={classes.container}
    >
      {items}
      <Tooltip title='Sensor Count' placement='top'>
        <Typography
          style={{
            fontWeight: 450,
          }}
        >
          {numberOfAlerts}
        </Typography>
      </Tooltip>
    </Box>
  );
}
