import _ from 'lodash';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import LocationIcon from '@material-ui/icons/Room';
import AlertIcon from '@material-ui/icons/Announcement';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Button,
} from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Tile from '../Dashboard/Tile.js';

import LastAlertsList from '../Dashboard/LastAlertsList.js';

import IconSelector from './IconSelector';
import HeaderBar from '../Generic/HeaderBar';
import { AppContext } from './../../AppContext';
import SensorIcon from '../../img/icons/SensorIcon';
import LoadingSpinner from '../Generic/LoadingSpinner';
import ManageAlerts from './AllAlertsComponents/ManageAlerts';
import ManageAlertsMobile from './AllAlertsComponents/ManageAlertsMobile';
import DevicesStatusList from '../Dashboard/DevicesStatusList.js';
import ActiveAlertsList from './AllAlertsComponents/ActiveAlertsList';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';

import { permissions } from '../../permissions';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

// mobile components

import {
  MobileCustomTab,
  MobileCustomTabs,
  MobileTabsContainer,
  MobileTabPanelContainer,
} from '../Generic/Mobile/MobileTabs';

// import MobileSearchBar from '../Generic/Mobile/MobileSearchBar'
import MobileBottomButton from '../Generic/Mobile/MobileBottomButton';
import {
  FullViewContainer,
  // ListViewContainer
} from '../Generic/Mobile/MobileContainers';

import TabPanel from '../Generic/TabPanel';

const useStyles = makeStyles(theme => ({
  alertsCards: {
    marginBottom: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(4, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.type === 'light' ? grey[50] : grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: theme.palette.type === 'light' ? 'white' : grey[800],
    },
  },
  formSelect: {
    width: '100%',
  },
}));

const AllAlerts = props => {
  const log = window.log('AllAlerts');
  const classes = useStyles();

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    snack,
    appState,
    setAppState,
    alertCount,
    alertsArray,
    openUserModal,
    setAlertCount,
    openAlertModal,
    setAlertsArray,
    setLogoutState,
    openSensorModal,
    openGatewayModal,
    openLocationModal,
    setOpenAddingModal,
    fetchCompanyCounts,
    contextResolvedAlerts,
  } = useContext(AppContext);

  const [state, setState] = useState();
  const [icons, setIcons] = useState({});
  const [iconsArr, setIconsArr] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [alertData, setAlertData] = useState([]);
  const [alertTypes, setAlertTypes] = useState();
  const [networkErr, setNetworkErr] = useState(false);
  const [activeAlerts, setActiveAlerts] = useState('-');
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState(8); // null ensures that selected alert table starts with "ACTIVE ALERTS"
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [sensorsWithAlerts, setSensorsWithAlerts] = useState('-');
  const [locationsWithAlerts, setLocationsWithAlerts] = useState('-');
  const [alertCounts, setAlertCounts] = useState({
    tempAlertCount: 0,
    humidAlertCount: 0,
    pressAlertCount: 0,
    motionAlertCount: 0,
    lightAlertCount: 0,
    panicButtonAlertCount: 0,
    proximityAlertCount: 0,
    doorOpenAlertCount: 0,
    batteryAlertCount: 0,
  });
  const [intervalId, setInvervalId] = useState(null);
  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
  }, [appState.auth.userInfo]);

  const intervalFunction = () => {
    if (window.location.href.includes('/alert/all')) {
      fetchAllAlertsPage();
    } else {
      clearInterval(intervalId);
    }
  };
  const initInterval = () => {
    if (intervalId === null) {
      setInvervalId(setInterval(() => intervalFunction(), 30000));
    }
  };
  useEffect(() => {
    fetchCompanyCounts();
    fetchAllAlertsPage();
    initInterval();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
  ]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    fetchCompanyCounts();
    fetchAllAlertsPage();
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  useEffect(() => {
    configIcons();
  }, [theme.palette.type]);

  /**
   * Alert types
   */

  useEffect(() => {
    setAlertTypes([
      {
        label: 'Temperature',
        icon: icons['Temperature'],
        text: alertCounts.tempAlertCount,
      },
      {
        label: 'Humidity',
        icon: icons['Humidity'],
        text: alertCounts.humidAlertCount,
      },
      {
        label: 'Pressure',
        icon: icons['Pressure'],
        text: alertCounts.pressAlertCount,
      },
      {
        label: 'Impact Detection',
        icon: icons['Impact Detection'],
        text: alertCounts.motionAlertCount,
      },
      {
        label: 'Light Detection',
        icon: icons['Light Detection'],
        text: alertCounts.lightAlertCount,
      },
      {
        label: 'Multi-Function Button',
        icon: icons['Multi-Function Button'],
        text: alertCounts.panicButtonAlertCount,
      },
      {
        label: 'Door Open/Close',
        icon: icons['Door Open/Close'],
        text: alertCounts.doorOpenAlertCount,
      },
      {
        label: 'Battery',
        icon: icons['Battery'],
        text: alertCounts.batteryAlertCount,
      },
      {
        label: smallDevice ? 'All Alerts' : 'All',
        icon: <Typography variant='h5'>All</Typography>,
        text: '',
      },
    ]);
  }, [alertCounts, theme.palette.type]);

  /**
   * Mobile specific components and states
   */

  const [mobileTabsValue, setMobileTabsValue] = useState(0);

  const mobileTabsChange = (event, newValue) => {
    setMobileTabsValue(newValue);
  };

  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of Mobile specific components and states
   */

  const configIcons = () => {
    const iconsObj = {};
    const iconArr = [];
    const sensorProfile = defaultSensorProfile(theme);
    _.forEach(sensorProfile, sensor => {
      let cloneSensor = _.cloneDeep(sensor);
      cloneSensor.icon.props.filledcolor = theme.palette.iconColorAlert.filled;
      cloneSensor.icon.props.style = { height: 38, width: 38 };
      iconsObj[cloneSensor.title] = cloneSensor.icon;
      iconArr.push({ ...cloneSensor.icon, name: cloneSensor.name });
    });
    setIcons(iconsObj);
    setIconsArr(iconArr);
  };

  const setSelectedAlertsArray = (index, alertsArray) => {
    let tmpAlerts = [];
    alertsArray.map(alert => {
      //Grab all alerts
      if (+index === 8) {
        tmpAlerts.push(alert);
        //Move the leading Icon back by 1 to accomodate for the all seletor
      } else if (index == alert.info.leadingIcon) {
        tmpAlerts.push(alert);
      }
    });
    setSelectedAlerts(tmpAlerts);
  };

  const fetchAllAlertsPage = async () => {
    log('fetchAlertCounts: Inside of fetchAlertCounts');
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/allPage',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await response.json();
      log('json:', json);

      log('Looking at json ', json);
      if (json.success) {
        setAlertData(json.data.alerts);
        setActiveAlerts(json.data.activeAlertCount);
        setLocationsWithAlerts(json.data.locationsWithActiveAlerts);
        setSensorsWithAlerts(json.data.sensorsWithActiveAlerts);
        //This is for the app bar
        setAlertCount(json.data.activeAlertCount);
        //This is for the alert selector bar
        setAlertCounts({ ...alertCounts, ...json.data.alertCounts });
        // setAlertsArray(json.data.alertsArray);
        setSelectedAlertsArray(selectedAlert, json.data.alertsArray);
        log('Looking at the alert counts\n', json.data.alertCounts);
      } else {
        json.errors.forEach(err => {
          snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
      setNetworkErr(true);
    }
    setInitialPageLoad(false);
  };

  const scrollToList = () => {
    const elmnt = document.getElementById('active__alerts__list');
    elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const alertTiles = [
    {
      id: 1,
      entityName: 'ACTIVE ALERTS',
      underlineColor: theme.palette.primary.main,
      numberOfEntities: activeAlerts,
      onClick: scrollToList,
      icon: (
        <AlertIcon
          style={{
            fontSize: 48,
            color: red[500],
          }}
        />
      ),
    },
    {
      id: 2,
      entityName: 'LOCATIONS WITH ALERTS',
      underlineColor: theme.palette.primary.main,
      numberOfEntities: locationsWithAlerts,
      icon: (
        <LocationIcon
          color='primary'
          style={{
            fontSize: 48,
          }}
        />
      ),
    },
    {
      id: 1,
      entityName: 'SENSORS WITH ALERTS',
      underlineColor: theme.palette.primary.main,
      numberOfEntities: sensorsWithAlerts,
      icon: (
        <SensorIcon
          style={{
            fontSize: 48,
            color: theme.palette.primary.main,
          }}
        />
      ),
    },
  ];

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const tableHeight = 'calc(100vh - 155px)';

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : networkErr ? null : (
    <div className={classes.root}>
      {isMobileSize ? (
        <FullViewContainer>
          {/* Table tabs selector */}
          <MobileTabsContainer>
            <MobileCustomTabs
              value={mobileTabsValue}
              centered
              onChange={mobileTabsChange}
              aria-label='alert_sensors_table_tabs'
              indicatorColor='primary'
              textColor='primary'
            >
              <MobileCustomTab label='Active alerts' />
              <MobileCustomTab label='Resolved alerts' />
              <MobileCustomTab label='Manage alerts' />
            </MobileCustomTabs>
          </MobileTabsContainer>
          <MobileTabPanelContainer>
            <TabPanel value={mobileTabsValue} index={0}>
              {/* Active alerts list */}
              <LastAlertsList
                listHeight={tableHeight}
                alerts={alertsArray}
                actionTitle={false}
                mobileLayout={true}
              />
            </TabPanel>
            <TabPanel value={mobileTabsValue} index={1}>
              {/* Resolved Alerts */}
              <DevicesStatusList
                listHeight={tableHeight}
                devices={contextResolvedAlerts}
                entity={'alerts'}
                actionTitle={false}
                mobileLayout={isMobileSize}
              />
            </TabPanel>
            <TabPanel value={mobileTabsValue} index={2}>
              {/* Manage Alerts */}
              <ManageAlertsMobile
                listHeight={tableHeight}
                alertData={alertData}
              />
            </TabPanel>
          </MobileTabPanelContainer>
          <MobileBottomButton onClick={openAddModal} />
        </FullViewContainer>
      ) : (
        <div>
          <HeaderBar
            leadingIcon={<AlertIcon style={{ fontSize: 32 }} color='primary' />}
            title='Alerts'
            searchInput={false}
            buttonAddTitle='ADD ALERT'
            buttonAddEvent={openAddModal}
            searchCallback={value => {}}
          />
          <Grid
            container
            spacing={2}
            justify='center'
            className={classes.alertsCards}
          >
            {alertTiles.map((u, index) => (
              <Grid item xs={12} md={4} key={`alerts__tiles__${index}`}>
                <Tile
                  entityName={u.entityName}
                  underlineColor={u.underlineColor}
                  numberOfEntities={u.numberOfEntities}
                  icon={u.icon}
                  iconSize='55px'
                />
              </Grid>
            ))}
          </Grid>
          {smallDevice ? (
            <Grid item xs={12}>
              <Card>
                <FormControl variant='outlined' className={classes.formSelect}>
                  <Select
                    labelId='alert-profile-range-type'
                    id='alert-profile-range-select'
                    className={classes.select}
                    inputProps={{
                      name: 'Alert type',
                      id: 'range-type-selector',
                    }}
                    onChange={event => {
                      log('Looking at index ' + event.target.value);
                      setSelectedAlert(event.target.value);
                      setSelectedAlertsArray(event.target.value, alertsArray);
                    }}
                    value={selectedAlert}
                  >
                    {alertTypes.map(({ label }, index) => {
                      return (
                        <MenuItem
                          key={`alert__range__setting__option__${index}`}
                          value={index}
                        >
                          {<em>{label}</em>}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Card>
            </Grid>
          ) : (
            <IconSelector
              items={alertTypes}
              selectedIndexCallback={index => {
                log('Looking at index ' + index);
                setSelectedAlert(index);
                setSelectedAlertsArray(index, alertsArray);
              }}
            ></IconSelector>
          )}
          {selectedAlert !== null ? (
            <ActiveAlertsList
              title={`${alertTypes[selectedAlert].label.toUpperCase()} ALERTS`}
              icons={iconsArr}
              alerts={selectedAlerts}
              fetchAllAlertsPage={fetchAllAlertsPage}
            />
          ) : (
            /* Table when an alert is selected in the icon selector */
            /* Table when there are no alerts selected in the icon selector */
            <ActiveAlertsList
              title='ACTIVE ALERTS'
              icons={iconsArr}
              alerts={alertsArray}
              fetchAllAlertsPage={fetchAllAlertsPage}
            />
          )}
          <ManageAlerts alertData={alertData} smallDevice={smallDevice} />
          <Grid item xs={12} sm={12}>
            <DevicesStatusList
              title='Resolved Alerts'
              devices={contextResolvedAlerts}
              entity={'alerts'}
              actionTitle={false}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default withWidth()(AllAlerts);
