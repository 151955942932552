import { navigate } from 'hookrouter';
import React, { useContext } from 'react';
import grey from '@material-ui/core/colors/grey';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { AppContext } from '../../AppContext';
import { PendingTag } from '../Generic/PendingTag';

/**
 * UserCard
 * @param {Object} props
 * @props
 * id: Integer
 * name: String
 * avatar: String
 * email: String
 * companyName: String
 */
function UserCardMobile(props) {
  const { appState } = useContext(AppContext);
  const theme = useTheme();
  const {
    id, // Id of the user,
    avatar, // Link to the image
    name, // Name of the user
    email, // Email of the user
    companyName, //Company of the user
    perm, // Permissions Level
    isUser,
    pendingUser, // User hasn't accepted the invitation yet
    backgroundColor, //
  } = props;

  const useStyles = makeStyles(theme => ({
    avatarLarge: {
      width: '74px',
      height: '74px',
      border: '1px solid #CCC',
      backgroundColor: !avatar && '#F4F5F5',
    },
    avatarImage: {
      objectFit: !avatar && 'none',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 14,
      color: theme.palette.type === 'light' ? grey[600] : '#fff',
      [theme.breakpoints.down('md')]: {
        textAlign: 'start',
      },
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? grey[500] : '#FFF',
      [theme.breakpoints.down('md')]: {
        textAlign: 'start',
      },
    },
    userInfoText: {
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.up('2090')]: {
        transform: 'translateX(-60px)',
      },
    },
    rootCard: {
      padding: theme.spacing(1),
      paddingLeft: 18,
      paddingTop: 19,
      paddingBottom: 19,
      backgroundColor: backgroundColor,
    },
    cardContent: {
      marginLeft: 0,
      marginBottom: 10,
      paddingLeft: 10,
      paddingBottom: '18px !important',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  }));

  const classes = useStyles();

  /**
   * Event to send the browser to the user page
   */
  const showUser = () => {
    if (isUser) {
      navigate(`/current/user/configuration`);
    } else {
      navigate(`/user/${id}`);
    }
  };

  const determineEdit = () => {
    let canEdit = true;
    if (perm || perm === 0) {
      const userPerm = appState.auth.userInfo.perm;
      if (userPerm === 1 || userPerm === 53) canEdit = false;
      if (userPerm === 0 && perm === 99) canEdit = false;
      if (userPerm === 2 && (perm === 99 || perm === 0)) canEdit = false;
    }
    return canEdit;
  };

  return (
    <div className={classes.rootCard}>
      <Grid
        item
        container
        xs={12}
        spacing={0}
        height='1px'
        style={{ position: 'relative' }}
      >
        <Grid item container xs={12} sm={12} md={8}>
          <Grid
            data-testclass='user_card'
            item
            container
            direction='column'
            className={classes.userInfoText}
            xs={12}
            md={8}
            onClick={showUser}
            style={{ cursor: 'pointer' }}
          >
            <Typography className={classes.title}>{name}</Typography>
            <Typography className={classes.subTitle}>Email: {email}</Typography>
            <Typography className={classes.subTitle}>{companyName}</Typography>
          </Grid>
        </Grid>
        {pendingUser && <PendingTag pendingUser={pendingUser} id={id} />}
      </Grid>
    </div>
  );
}

export default UserCardMobile;
