import _ from 'lodash';
import { navigate } from 'hookrouter';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';
import GenericList from './GenericList';
import AlertContainer from './AlertContainer';
import ResolveAlertModal from '../Alerts/ResolveAlertModal';
import { convertToF, convertToPSI } from '../../conversions';

import { AppContext } from '../../AppContext';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * LastAlertsList for
 * the list in the dashboard
 *
 * @props
 * alerts: Array[Object]
 */
const LastAlertsList = props => {
  const log = window.log('LastAlertsList');
  const classes = useStyles();
  const theme = useTheme();
  const [alertsArr, setAlertsArr] = useState([]);
  const { appState } = useContext(AppContext);
  const findIcon = type => {
    let name = type;
    switch (type) {
      case 'motion':
      case 'xAccel':
      case 'yAccel':
      case 'zAccel':
        name = 'motion_detection';
        break;
      case 'door':
        name = 'door_open_close';
        break;
      case 'pressure':
        name = 'pressure';
        break;
      case 'panic':
        name = 'panic_button';
        break;
      case 'light':
        name = 'light_detection';
        break;
      case 'humidity':
        name = 'humidity';
        break;
      case 'battery':
        name = 'battery';
        break;
      case 'voc':
        name = 'voc';
        break;
      case 'iaq':
        name = 'iaq';
        break;
      case 'temp_pcb':
        name = 'temp_pcb';
        break;
      case 'eco2':
        name = 'eco2';
        break;
      case 'water':
        name = 'water';
        break;
      default:
        name = 'temp_environment'; // set this in the case of a failure, won't break the page
        break;
    }

    const iconArr = defaultSensorProfile(theme, undefined, true, 35);
    const icon = _.find(iconArr, { name });

    return icon.icon;
  };

  const {
    alerts, // Alerts array with the alerts objects
    title,
    dashboardTable,
    isMobile,
    actionTitle = true,
    listHeight,
    mobileLayout = false, // boolean to shot the tiles with the mobile layout
    appendTime = false,
    fetchAllAlertsPage,
    paddingTop = 0, // Padding top for the mobile headerAlert
    onClickReplace = null, //Used to replace the original onClick function
    customFooter,
    customCount,
    ...other
  } = props;
  const handleOnClick = index => {
    if (onClickReplace) {
      onClickReplace(index);
    } else {
      onClick(index);
    }
  };
  const onClick = index => {
    if (mobileLayout && typeof index !== 'object') {
      // Don't want to manage low battery alerts
      if (alerts[index].info.type === 'battery') return;
      setOpenResolveAlert(true);
      //Only dealing with temperature right now
      if (alerts[index].info.measurementUnit === '°C') {
        if (appState.userSettings.measurement === 'imperial') {
          setMeasurementUnit(' °F');
        } else {
          setMeasurementUnit(' °C');
        }
        setMeasurementValue(
          convertToF(
            alerts[index].info.lastSensorValue,
            appState.userSettings.measurement,
          ),
        );
      } else if (alerts[index].info.measurementUnit === 'hPa') {
        if (appState.userSettings.measurement === 'imperial') {
          setMeasurementUnit(' PSI');
        } else {
          setMeasurementUnit(' hPa');
        }
        setMeasurementValue(
          convertToPSI(
            alerts[index].info.lastSensorValue,
            appState.userSettings.measurement,
          ),
        );
      } else {
        setMeasurementValue(+alerts[index].info.lastSensorValue);
        setMeasurementUnit(alerts[index].info.measurementUnit);
      }
      setCurrentAlert(alerts[index]);
      log('Looking at alert ', alerts[index]);
    } else {
      navigate('/alert/all');
    }
  };

  // States for resolve Alert modal
  const updateAlertPage = () => {
    log('Running update alert');
    fetchAllAlertsPage && fetchAllAlertsPage();
  };
  const resolveAlertClose = () => {
    setOpenResolveAlert(false);
  };
  const [openResolveAlert, setOpenResolveAlert] = useState(false);
  const [measurementUnit, setMeasurementUnit] = useState(' °C');
  const [measurementValue, setMeasurementValue] = useState();
  const [currentAlert, setCurrentAlert] = useState({
    info: {
      id: 0,
      alertName: '',
      locationName: '',
      deviceName: '',
      leadingIcon: <div></div>,
      minValue: 0,
      maxValue: 0,
      minRange: 0,
      maxRange: 0,
      rangeType: '-',
      lastSensorValue: 0,
      messageReceivedDate: '',
      type: '',
    },
    trailing: {
      canSnooze: false,
    },
    device: {
      deviceName: '',
      sensorId: '',
      location: '',
      deviceImageUrl: '',
    },
  });
  useEffect(() => {
    log(alerts);
    if (alerts[0] && alerts[0].info) {
      setAlertsArr(alerts);
    } else {
      setAlertsArr(alerts);
    }
  }, [alerts]);

  return alerts[0] && alerts[0].info ? (
    <>
      <GenericList
        {...other}
        paddingTop={paddingTop}
        title={title}
        paginate
        listHeight={listHeight}
        actionTitle={actionTitle && !isMobile ? 'View All Alerts' : null}
        onClick={handleOnClick}
        data={alertsArr}
        dashboardTable={dashboardTable}
        emptyBuilder={() => {
          return (
            <div key={0} className={classes.alertContainer}>
              <AlertContainer
                key={0}
                index={0}
                alertName={
                  <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    No Active Alerts
                  </div>
                }
                locationName={null}
                noActiveAlerts={true}
                mobileLayout={mobileLayout}
              />
            </div>
          );
        }}
        itemBuilder={(u, index) => {
          return (
            <div
              key={`last__alert__list__${index}`}
              className={classes.alertContainer}
              onClick={() => handleOnClick(index)}
            >
              <AlertContainer
                className={classes.alertContainer}
                leadingIcon={findIcon(u.info.type)}
                key={u.info.id}
                index={index}
                alertName={u.info.alertName}
                locationName={u.info.locationName}
                deviceName={u.info.deviceName}
                canSnooze={u.trailing.canSnooze}
                time={u.info.messageReceivedDate}
              />
            </div>
          );
        }}
        useItemBuilder={true}
      />
      <ResolveAlertModal
        messageReceivedDate={currentAlert.info.messageReceivedDate}
        openState={openResolveAlert}
        alertIcon={findIcon(currentAlert.info.type)}
        alertName={currentAlert.info.alertName}
        device={currentAlert.device}
        handleClose={resolveAlertClose}
        minValue={parseInt(currentAlert.info.minValue)}
        maxValue={parseInt(currentAlert.info.maxValue)}
        minRange={parseInt(currentAlert.info.minRange)}
        maxRange={parseInt(currentAlert.info.maxRange)}
        rangeType={currentAlert.info.rangeType}
        sensorValue={parseInt(measurementValue)}
        currentAlert={currentAlert}
        updateAlertPage={updateAlertPage}
        measurementUnit={measurementUnit}
        alertType={currentAlert.info.type}
      />
      {customFooter ? () => customFooter() : null}
    </>
  ) : (
    <GenericList
      {...other}
      title={title}
      actionTitle={actionTitle && 'View All Alerts'}
      onClick={handleOnClick}
      data={alertsArr}
      dashboardTable={dashboardTable}
      emptyBuilder={() => {
        return (
          <div key={0} className={classes.alertContainer}>
            <AlertContainer
              key={0}
              index={0}
              alertName={
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  No Active Alerts
                </div>
              }
              locationName={null}
              noActiveAlerts={true}
              mobileLayout={mobileLayout}
            />
          </div>
        );
      }}
      itemBuilder={(u, index) => {
        return (
          <div
          key={`last__alert__list__${index}`}
          className={classes.alertContainer}
          onClick={handleOnClick}
        >
          <AlertContainer
            leadingIcon={findIcon(u.info.type)}
            key={u.id}
            index={index}
            alertName={u.alertName}
            locationName={u.locationName}
            deviceName={u.deviceName}
            mobileLayout={mobileLayout}
          />
        </div>
        )
      }}
      useItemBuilder={true}
    />
  );
};

export default LastAlertsList;
