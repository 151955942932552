import React, { useState, useContext, useEffect } from 'react';
import { SvgIcon } from '@material-ui/core';
import { AppContext } from '../AppContext';

import { useTheme } from '@material-ui/core/styles';

const BatterySVG = props => {
  const { appState } = useContext(AppContext);
  const [bgColor, setBgColor] = useState(
    appState.userSettings.darkMode ? '#424242' : '#fff',
  );

  const theme = useTheme();

  useEffect(() => {
    setBgColor(theme.palette.type === 'dark' ? '#424242' : '#fff');
  }, [theme.palette.type]);

  return (
    <SvgIcon width='22' height='16' viewBox='0 0 22 16' {...props}>
      <g transform='translate(3.55 3.456)'>
        <rect
          fill={bgColor}
          width='22'
          height='16'
          rx='3'
          transform='translate(-3.55 -3.456)'
        />
        <g transform='translate(0 -0.847)'>
          <path
            fill='#FF6A6A'
            d='M1.121,1.332V9.326H15.688V1.332ZM1.129,0H15.68a1.239,1.239,0,0,1,1.129,1.328V2.665H17.93V7.994H16.809V9.33a1.246,1.246,0,0,1-1.129,1.328H1.129A1.239,1.239,0,0,1,0,9.33v-8A1.247,1.247,0,0,1,1.129,0Z'
            transform='translate(-0.55)'
          />
          <rect
            fill='#FF6A6A'
            width='4.103'
            height='6.432'
            transform='translate(1.45 2.138)'
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default BatterySVG;
