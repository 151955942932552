import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import grey from '@material-ui/core/colors/grey';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Slider, Tooltip, withWidth, Typography } from '@material-ui/core';

import { AppContext } from './../../AppContext';
import { determineSimpleLuxDisplay } from '../common/helpers/calculateLuxDisplay';
import { AlertContext } from '../Alerts/AlertContext';

const isSmall = _width => {
  switch (_width) {
    case 'sm':
      return true;
    case 'xs':
      return true;
    default:
      return false;
  }
};

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1.5, 0),
    border: `1px solid ${grey[500]}`,
    overflow: 'visible',
    padding: theme.spacing(1.5),
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  slider: {
    position: 'absolute',
  },
}));

const StyledSlider = withStyles({
  rail: { color: '#CCCCCC' },
  track: {
    color: '#08B3FF',
    '& .MuiSlider-track': {
      color: '#08B3FF',
    },
  },
  trackInverted: {
    '& .MuiSlider-track': {
      backgroundColor: '#E6E6E6',
    },
    '& .MuiSlider-rail': { color: '#08B3FF !important' },
  },
  thumb: { color: '#08B3FF' },
})(Slider);

const useTooltipStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    zIndex: 1299,
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useTooltipStyles();

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement={props.index === 0 ? 'top' : 'bottom'}
      title={value}
      style={{ zIndex: '1' }}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const SliderTile = function (props) {
  const log = window.log('SliderTile');
  const classes = useStyles();
  const { modalOpenState } = useContext(AppContext);
  const { modalState } = useContext(AlertContext);

  let {
    type, // Type of range
    width, // Breakpoints for width
    drawer, // if drawer is open
    values, // number, one element Ex: 15 , or array of two numbers Ex: [15,20]
    onChange, // Function (event, newValue)
    isRangeType = false, // boolean, if it is a single value or a range
    unitMeasure = '%', // String, to specify the unit , example "°F"
    minimumValue = 0, // number, minimum value of the range
    maximumValue = 100, // number, maximun value of the range
  } = props;

  const valueTextFormat = value => {
    if (unitMeasure === 'LuxSimple') {
      return (
        <Typography style={{ color: 'white' }}>
          {determineSimpleLuxDisplay(value)}
        </Typography>
      );
    }

    return (
      <Typography style={{ color: 'white' }}>
        {value}
        {unitMeasure}
      </Typography>
    );
  };

  const isInverted = () => {
    if (!type || type === 'Below Value' || type === 'Inside of Range') {
      return 'normal';
    }
    return 'inverted';
  };

  const marks = [
    {
      value: minimumValue,
      label: valueTextFormat(minimumValue),
    },
    {
      value: maximumValue,
      label: valueTextFormat(maximumValue),
    },
  ];

  if (!isRangeType && type && !type.includes('Split')) {
    values = values[0];
  }
  const defaultValue = isRangeType
    ? [minimumValue, maximumValue / 2]
    : minimumValue;

  log('Looking at the default value ' + defaultValue);

  return type && !type.includes('Split') ? (
    <StyledSlider
      className={classes.slider}
      ValueLabelComponent={ValueLabelComponent}
      defaultValue={defaultValue} 
      valueLabelFormat={valueTextFormat}
      value={values}
      onChange={(e, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
      disabled
      min={minimumValue}
      max={maximumValue}
      valueLabelDisplay={
        modalOpenState() || (drawer && isSmall(width)) || modalState.isOpen
          ? 'off'
          : 'on'
      }
      aria-labelledby='range-slider'
      getAriaValueText={valueTextFormat}
      marks={marks}
      track={isInverted()}
      color='primary'
    />
  ) : (
    <>
      <StyledSlider
        className={classes.slider}
        ValueLabelComponent={ValueLabelComponent}
        defaultValue={defaultValue}
        valueLabelFormat={valueTextFormat}
        value={values[0]}
        onChange={(e, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        disabled
        min={minimumValue}
        max={maximumValue}
        valueLabelDisplay={
          modalOpenState() || (drawer && isSmall(width)) || modalState.isOpen
            ? 'off'
            : 'on'
        }
        aria-labelledby='range-slider'
        getAriaValueText={valueTextFormat}
        marks={marks}
        track={'normal'}
      />
      <StyledSlider
        className={classes.slider}
        ValueLabelComponent={ValueLabelComponent}
        defaultValue={defaultValue}
        valueLabelFormat={valueTextFormat}
        value={values[1]}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        disabled
        min={minimumValue}
        max={maximumValue}
        valueLabelDisplay={
          modalOpenState() || (drawer && isSmall(width)) || modalState.isOpen
            ? 'off'
            : 'on'
        }
        aria-labelledby='range-slider'
        getAriaValueText={valueTextFormat}
        marks={marks}
        track={'normal'}
      />
    </>
  );
};

export default withWidth()(SliderTile);
