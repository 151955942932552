import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Box } from "@material-ui/core";

import backgroundImage from "../../img/login_background.png";

import grey from "@material-ui/core/colors/grey";
/**
 *  103px is the height of the navbar and the padding of the container
 * , so it is fully centered in the screen with half of it 51.5
 */

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    height: "100%",
  },
  leftImageContainer: {
    // backgroundColor: theme.palette.primary.light,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      top: "0",
      // backgroundColor: theme.palette.primary.light,
      opacity: "0.6",
      zIndex: "1",
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imageBackground: {
    height: "100%",
    position: "relative",
    transform: "translate(-60%, 0px)",
    [theme.breakpoints.up("lg")]: {
      transform: "translate(-50%, 0px)",
    },
  },
  cardContainer: {
    height: "100%",
    width: "100%",
  },
  pageWrapper: {
    height: "100vh",
    width: "100%",
  },
  logo: {
    width: "230px",
  },
  subtitle: {
    color: theme.palette.type === "light" ? grey[600] : grey[400],
    width: "80%",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

export default function LoginLayout(props) {
  const theme = useTheme();
  const {
    subtitle,
    children,
    subtitleStyles,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.pageWrapper}>
      <Paper className={classes.cardContainer}>
        <Grid container className={classes.sectionContainer}>
          <Grid item md={4} className={classes.leftImageContainer}>
            <img
              className={classes.imageBackground}
              src={backgroundImage}
              alt="user"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={8}
            className={classes.rightContainer}
          >
            <Box>
              <img
                className={classes.logo}
                src={
                  theme.palette.type === "light"
                    ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg"
                    : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg"
                }
                alt="logo"
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
            >
              <Typography
                className={classes.subtitle}
                style={subtitleStyles}
              >
                {subtitle}
              </Typography>
            </Box>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
