import React, { useState, useEffect } from 'react';
import { Badge, makeStyles, withStyles } from '@material-ui/core';
import moment from 'moment';

/**
 * Device Card
 * @param {Object} props
 * @props
 * lastMessageTime: timestamp,
 */
export default function DeviceStatusIcon({ lastMessageTime, reportInterval }) {
  let timeout = reportInterval

  //Start the state value to be the timeout value + 1 so the connection dot defaults to red
  const [connectionTimeoutTimer, setConnectionTimeoutTimer] = useState(
    timeout + 1,
  );

  //Set the connection dot red as default
  const [connectedStatus, setConnectedStatus] = useState(false);

  useEffect(() => {
    const countdownTimer = () => {
      setInterval(() => {
        setConnectionTimeoutTimer(
          connectionTimeoutTimer => connectionTimeoutTimer + 1,
        );
      }, 1000);
    };
    countdownTimer();
    return () => clearInterval(countdownTimer);
  }, []);

  useEffect(() => {
    setConnectedStatus(connectionTimeoutTimer <= timeout);
  }, [connectionTimeoutTimer]);

  //Driven from the socket IO
  useEffect(() => {
    determineTimeSince(lastMessageTime);
  }, [lastMessageTime]);

  const determineTimeSince = lastMessage => {
    if(lastMessage === undefined || lastMessage === null){
      setConnectedStatus(false)
    }else{
      const currTime = moment();
      const diff = moment.duration(currTime.diff(lastMessage)).as('seconds');

      setConnectionTimeoutTimer(diff);
      // less than 5 minutes is true
      if (diff <= timeout){
        setConnectedStatus(true);
      }else{
        setConnectedStatus(false);
      }
    }
  };

  const classes = useStyles();

  const StyledBadge = withStyles(theme => ({
    anchorOriginBottomRightCircle: '', // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: connectedStatus ? '#59C754' : '#FF6A6A',
      color: connectedStatus ? '#59C754' : '#FF6A6A',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': connectedStatus && {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <StyledBadge
      classes={{
        anchorOriginBottomRightCircle: classes.statusCircle,
      }}
      overlap='circle'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant={'dot'}
    />
  );
}

const useStyles = makeStyles(() => ({
  statusCircle: {
    right: '0%',
    bottom: '49%',
  },
}));