import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

//Components
import { AppContext } from '../../AppContext';

const useStyles = makeStyles(theme => ({
  underLine: {
    backgroundColor: theme.palette.primary.main,
    height: '1px',
    width: '3rem',
  },
}));

const DashboardBarGraph = props => {
  const theme = useTheme();
  const { appState } = useContext(AppContext);
  const { data } = props;

  const [formattedData, setFormattedData] = useState([]);
  const [forecastedAverage, setForecastedAverage] = useState(0);
  const [buttonState, setButtonState] = useState('forecast');

  const formatBarData = data => {
    const { previous, current, forecast } = data;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formattedBarGraphData = [];
    if (buttonState === 'current') {
      for (const key in previous) {
        const splitDate = key.split('-');
        const formattedYear = splitDate[1].slice(2, 4);
        const formattedDate = splitDate[0] + '/1/' + formattedYear;
        const obj = previous[key];
        formattedBarGraphData.unshift({
          date: formattedDate,
          UPGRADES: obj.totalProrated,
          'COMPLETED RENEWALS': obj.totalCompletedAnnual,
          'INCOMPLETE RENEWALS': obj.totalPendingAnnual,
          'Total Annual': obj.totalAnnual,
        });
      }
      if (current) {
        formattedBarGraphData.push({
          date: `${currentMonth}/1/${String(currentYear).slice(2, 4)}`,
          UPGRADES: current.totalProrated,
          'COMPLETED RENEWALS': current.totalCompletedAnnual,
          'INCOMPLETE RENEWALS': current.totalPendingAnnual,
          'Total Annual': current.totalAnnual,
        });
      }
    } else {
      for (const key in forecast) {
        const splitDate = key.split('-');
        const formattedYear = splitDate[1].slice(2, 4);
        const formattedDate = splitDate[0] + '/1/' + formattedYear;
        const obj = forecast[key];
        formattedBarGraphData.push({
          date: formattedDate,
          Total: obj.totalAnnual,
        });
      }
    }
    getAverage(formattedBarGraphData);
    setFormattedData(formattedBarGraphData);
  };

  const getAverage = data => {
    let total = 0;
    let count = 0;
    for (const key in data) {
      count++;
      total += data[key]['Total'];
    }
    setForecastedAverage(total / count);
  };

  useEffect(() => {
    formatBarData(data);
  }, [data, buttonState]);

  const renderTooltip = item => {
    const isHovered = type =>
      type === item.id ? { fontWeight: 'bolder' } : {};

    return buttonState === 'current' ? (
      <>
        <Typography style={isHovered('INCOMPLETE RENEWALS')}>
          Incomplete Renewals: ${item.data['INCOMPLETE RENEWALS']}
        </Typography>
        <Typography style={isHovered('COMPLETED RENEWALS')}>
          Completed Renewals: ${item.data['COMPLETED RENEWALS']}
        </Typography>
        <Typography style={isHovered('UPGRADES')}>
          Upgrades: ${item.data['UPGRADES']}
        </Typography>
        <Typography style={isHovered('Total Annual')}>
          Total Annual: ${item.data['Total Annual']}
        </Typography>
      </>
    ) : (
      <Typography style={isHovered('Total Annual')}>
        Total: ${item.data['Total']}
      </Typography>
    );
  };

  return (
    <Card>
      <Grid
        style={{
          paddingTop: 19,
          position: 'relative',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          style={{
            color: '#747474',
            font: 'normal normal 600 12px/25px Open Sans;',
          }}
        >
          FORECASTED REVENUE {new Date().getFullYear()}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 3.5,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              borderTop: '1px solid #08B3FF',
              display: 'inline-block',
              width: 89,
            }}
          ></div>
        </div>
        <Typography
          style={{
            color: '#08B3FF',
            font: 'normal normal bold 15px/20px Open Sans',
          }}
        >
          Avg. ${Number(forecastedAverage || 0).toFixed(2)}
        </Typography>
      </Grid>
      <CardContent style={{ height: '46vh' }}>
        <ResponsiveBar
          data={formattedData}
          keys={
            buttonState === 'current'
              ? ['UPGRADES', 'COMPLETED RENEWALS', 'INCOMPLETE RENEWALS']
              : ['Total']
          }
          indexBy='date'
          margin={{ top: 25, right: 30, bottom: 50, left: 40 }}
          padding={0.1}
          colors={
            buttonState === 'current'
              ? ['#051F34', '#08B3FF', 'white']
              : '#08B3FF'
          }
          borderWidth={1}
          borderColor='#08B3FF'
          axisTop={null}
          axisRight={null}
          label={val => ''}
          labelTextColor={
            appState?.auth?.userInfo?.themePreferences?.darkMode
              ? 'white'
              : 'black'
          }
          tooltip={item => renderTooltip(item)}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
            precision: 'month',
          }}
          xFormat='time:%Y-%m-%d'
          axisBottom={{
            format: d => moment(d).format('MMM YY'),
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            // legend: "Months",
            legendPosition: 'middle',
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableArea={false}
          useMesh={false}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 0.3 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={
            appState?.auth?.userInfo?.themePreferences?.darkMode && {
              axis: {
                ticks: {
                  text: {
                    fill: 'white',
                  },
                },
                legend: {
                  text: {
                    fill: 'white',
                  },
                },
              },
              tooltip: {
                container: {
                  color: 'black',
                },
              },
            }
          }
        />
      </CardContent>
      <Grid
        container
        md={12}
        style={{ justifyContent: 'center', paddingBottom: 18 }}
      >
        {buttonState === 'current' ? (
          <>
            <LegendItem
              style={{ border: '1px solid #08B3FF' }}
              color='white'
              label='INCOMPLETE RENEWALS'
            />
            <LegendItem color='#08B3FF' label='COMPLETED RENEWALS' />
            <LegendItem color='#051F34' label='UPGRADES' />
          </>
        ) : (
          <LegendItem color='#08B3FF' label='TOTAL ANNUAL' />
        )}
      </Grid>
    </Card>
  );
};

const LegendItem = props => {
  const { style, color, label } = props;
  return (
    <Grid item xs={12} md={4} lg={3} xl={2}>
      <Grid
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            ...style,
            backgroundColor: color,
            width: 30,
            height: 8,
            marginRight: 6,
          }}
        ></div>
        <Typography style={{ display: 'inline', cursor: 'default' }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DashboardBarGraph;
