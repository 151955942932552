import React from 'react';
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

function IconElement(props) {
  const {
    iconClass,
    iconComponent,
    valueClass,
    valueText,
    valueTitle,
    enableZero,
    iconElementClass,
  } = props;

  let createWhitespace = false;
  if (valueText || (enableZero && valueText === 0)) {
    if (valueText.length <= 0) {
      createWhitespace = true;
    }
  } else {
    createWhitespace = true;
  }
  return (
    <Tooltip title={valueTitle || ''} placement='top'>
      <Box
        display='flex'
        alignContent='center'
        justifyContent='center'
        flexDirection='column'
        className={iconClass}
      >
        <Box
          className={iconElementClass}
          display='flex'
          justifyContent='center'
        >
          {iconComponent}
        </Box>
        <Box display='flex' justifyContent='center'>
          <Typography
            className={valueClass}
            style={{
              whiteSpace:
                valueText === 'Very High Light' ? 'pre-wrap' : 'nowrap',
              paddingTop: createWhitespace ? null : 4,
              textAlign: 'center',
            }}
          >
            {createWhitespace ? (
              <span style={{ marginTop: 45 }}></span>
            ) : (
              valueText
            )}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}

/**
 * Component that show horizontal list of
 * Icons and info for the cards components
 * @param {Object} props
 *
 * @props
 * items: Object Array [{ icon: React Component , value: String }]
 */
export default function CardInfoIcons(props) {
  const log = window.log('CardInfoIcons');

  const {
    items, // array or dictionary of items
    isBold, // boolean if the value to show is in bold
    wrapItems,
    isDict = false, // boolean if the array of items is a dictionary
    showOnlyItems = [],
    displayDivider,
    buttons,
    enableZero, // boolean - show 0 value text
    gatewayCard,
    sensorCard,
    singleGatewayCard,
    singleAlertCard,
    locationCard,
    singleLocationCard,
    isSmall,
    deviceCard,
  } = props;

  const useStyles = makeStyles(theme => ({
    container: {
      padding: theme.spacing(1, 0),
      justifyContent: 'flex-end',
      maxWidth: sensorCard && '100%',

      [theme.breakpoints.down('sm')]: {
        justifyContent:
          gatewayCard || singleLocationCard ? 'flex-end' : 'center',
        flexWrap: wrapItems,
        maxWidth: sensorCard && '100%',
        transform: sensorCard && 'translate(-39%, 20%)',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        transform: sensorCard && 'translate(0)',
        flexWrap: sensorCard && 'wrap',
        maxWidth: sensorCard && '100%',
      },
    },
    divider: {
      backgroundColor: theme.palette.type === 'light' ? '#CCC' : '#FFF',
      height: gatewayCard ? 65 : 70,
      marginRight: gatewayCard && !singleGatewayCard && 20,
      [theme.breakpoints.down('sm')]: {
        display: displayDivider,
        marginRight: sensorCard ? 8 : (gatewayCard || singleLocationCard) && 2,
        marginLeft: sensorCard && 8,
      },
      [theme.breakpoints.down('xs')]: {
        display: (singleGatewayCard || singleAlertCard) && 'none',
        transform: singleLocationCard && 'translate(-58px, 98px)',
        marginBottom: sensorCard && 12,
      },
      [theme.breakpoints.down('1130')]: {
        display: gatewayCard && 'none',
      },
      [theme.breakpoints.down('960')]: {
        display: gatewayCard && 'block !important',
      },
      [theme.breakpoints.down('460')]: {
        display: gatewayCard && 'block !important',
      },
      [theme.breakpoints.down('380')]: {
        transform: singleLocationCard && 'translate(-31px, 98px)',
      },
    },
    buttonDivider: {
      height: gatewayCard ? 65 : singleLocationCard && 70,
      marginRight:
        (singleGatewayCard || singleLocationCard || singleAlertCard) && 30,
      marginBottom:
        singleGatewayCard || singleLocationCard ? 24 : singleAlertCard && 2,
      backgroundColor: theme.palette.type === 'light' ? '#CCC' : '#FFF',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    buttonContainer: {
      marginBottom: 1,
    },
    buttons: {
      transform:
        (singleGatewayCard || singleLocationCard) && 'translateY(-14px)',
      [theme.breakpoints.down('xs')]: {
        transform: singleAlertCard
          ? 'translate(1%, -52%)'
          : singleGatewayCard
          ? 'translate(1%, -32%)'
          : singleLocationCard && 'translate(-140%, -52%)',
      },
      [theme.breakpoints.down('490')]: {
        transform: singleGatewayCard
          ? 'translate(-72%, 168%)'
          : singleAlertCard && 'translate(-72%, 204%)',
      },
      [theme.breakpoints.down('380')]: {
        transform: singleLocationCard && 'translate(-181%, -52%)',
      },
      [theme.breakpoints.down('320')]: {
        transform: singleAlertCard && 'translate(-100%, 204%)',
      },
    },
    iconContainer: {
      position: 'relative',
      width: '105px',
      minWidth: sensorCard && '80px',
      [theme.breakpoints.down('1200')]: {
        minWidth: sensorCard && '60px',
      },
      [theme.breakpoints.down('1120')]: {
        width: sensorCard && 105,
        minWidth: sensorCard && 'inherit',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: sensorCard && '10vw',
        width: sensorCard && '12vh',
      },
      transform:
        singleGatewayCard || singleLocationCard
          ? 'translateY(-8px)'
          : singleAlertCard && 'translateY(3px)',

      [theme.breakpoints.down('xs')]: {
        transform: singleGatewayCard
          ? 'translate(-96%, 113%)'
          : singleLocationCard
          ? 'translate(-68%, 141%)'
          : singleAlertCard && 'translate(-93%, 138%)',
      },
      [theme.breakpoints.down('490')]: {
        transform: singleGatewayCard
          ? 'translate(-147%, 105%)'
          : singleAlertCard && 'translate(-147%, 147%)',
      },
      [theme.breakpoints.down('380')]: {
        transform: singleLocationCard && 'translate(-35%, 142%)',
      },
    },
    valueText: {
      fontSize: sensorCard ? 16 : 20,
      letterSpacing: sensorCard && -1.4,
      fontWeight: isBold ? 600 : '',
      marginTop: sensorCard ? -2 : singleAlertCard ? 0 : locationCard ? 0 : 10,
      color: theme.palette.type === 'light' ? grey[700] : grey[300],
      transform: (gatewayCard || singleLocationCard) && 'translateY(-8px)',
    },
    icon: {
      transform: (gatewayCard || singleLocationCard) && 'translateY(4px)',
    },
  }));
  const classes = useStyles();
  const theme = useTheme();

  const maxLength = useMediaQuery(theme.breakpoints.down('388')) ? 3 : 3;
  let result = []; // array of objects that will render
  let index = 0;
  let arrayLength;

  if (isDict) {
    arrayLength = Object.keys(items).length;
  } else {
    arrayLength = items.length;
  }

  log('CardInfoIcons: ' + Object.keys(items));
  if (isSmall) {
    result.push(
      <Divider
        className={classes.divider}
        key={index+'i'}
        orientation='vertical'
        flexItem
      />,
    );
  }
  if (isDict && showOnlyItems.length > 0 && isSmall && deviceCard) {
    let actualRow = [];
    for (const key of showOnlyItems) {
      actualRow.push(
        <IconElement
          key={key+items[key].title}
          iconElementClass={classes.icon}
          iconClass={classes.iconContainer}
          iconComponent={items[key].icon}
          valueClass={classes.valueText}
          valueText={items[key].value}
          valueTitle={items[key].title}
          enableZero={enableZero}
          gatewayCard={gatewayCard}
        />,
      );
      log('Index validatro', index, showOnlyItems.length);
      if (
        index++ === showOnlyItems.length - 1 ||
        actualRow.length === maxLength
      ) {
        log('actualRow length:', actualRow.length);
        switch (actualRow.length) {
          case 1:
            result = [
              ...result,
              <IconElement
                key={key+'j'}
                iconElementClass={classes.icon}
                iconClass={classes.iconContainer}
                iconComponent={''}
                valueClass={classes.valueText}
                valueText={undefined}
                valueTitle={undefined}
                enableZero={enableZero}
                gatewayCard={gatewayCard}
              />,
              <Divider
                className={classes.divider}
                key={index+'a'}
                orientation='vertical'
                flexItem
              />,
              actualRow[0],
              <Divider
                className={classes.divider}
                key={index+'b'}
                orientation='vertical'
                flexItem
              />,
              <IconElement
                key={key+'c'}
                iconElementClass={classes.icon}
                iconClass={classes.iconContainer}
                iconComponent={''}
                valueClass={classes.valueText}
                valueText={undefined}
                valueTitle={undefined}
                enableZero={enableZero}
                gatewayCard={gatewayCard}
              />,
            ];
            break;
          case 2:
            result = [
              ...result,
              actualRow[0],
              <Divider
                className={classes.divider}
                key={index+'d'}
                orientation='vertical'
                flexItem
              />,
              actualRow[1],
            ];
            break;
          default:
            result = [
              ...result,
              actualRow[0],
              <Divider
                className={classes.divider}
                key={index+'e'}
                orientation='vertical'
                flexItem
              />,
              actualRow[1],
              <Divider
                className={classes.divider}
                key={index+'f'}
                orientation='vertical'
                flexItem
              />,
              actualRow[2],
            ];
        }
        actualRow = [];
      }
    }
  } else if (isDict && showOnlyItems.length > 0) {
    let counter = 0
    for (const key of showOnlyItems) {
      counter +=1
      if (items[key]) {
        result.push(
          <IconElement
            key={key+items[key].title}
            iconElementClass={classes.icon}
            iconClass={classes.iconContainer}
            iconComponent={items[key].icon}
            valueClass={classes.valueText}
            valueText={items[key].value}
            valueTitle={items[key].title}
            enableZero={enableZero}
            gatewayCard={gatewayCard}
          />,
        );

        if (counter !== showOnlyItems.length - 1) {
          result.push(
            <Divider
              className={classes.divider}
              key={index+'g'+items[key].title}
              orientation='vertical'
              flexItem
            />,
          );
        }
      }
    }
  } else {
    for (const i in items) {
      result.push(
        <IconElement
          gatewayCard={gatewayCard}
          // key={i}
          key={i+items[i].title}

          iconElementClass={classes.icon}
          iconClass={classes.iconContainer}
          iconComponent={items[i].icon}
          valueClass={classes.valueText}
          valueText={items[i].value}
          valueTitle={items[i].title}
          enableZero={enableZero}
        />,
      );

      if (index++ < items.length - 1 && !(isSmall && gatewayCard)) {
        result.push(
          <Divider
            className={classes.divider}
            key={`diveider-${index}h`}
            orientation='vertical'
            flexItem
          />,
        );
      }
    }
  }
// result.pop()
  return (
    <Box
      width='100%'
      display='flex'
      alignItems='center'
      className={classes.container}
    >
      {result}
      {buttons && (
        <Divider
          className={classes.buttonDivider}
          orientation='vertical'
          flexItem
        />
      )}
      <Box className={classes.buttons}>{buttons}</Box>
    </Box>
  );
}
