import grey from '@material-ui/core/colors/grey';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState, useContext, useRef } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import {
  Grid,
  useTheme,
  TextField,
  InputLabel,
  makeStyles,
  FormControl,
  // Checkbox,
  // FormControlLabel,
} from '@material-ui/core';

import { AppContext } from '../../../AppContext';
import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  inputNormal: {
    height: '50px',
    marginTop: '50px',
    background: 'white',
    border: '1px solid grey',
    padding: '4px',
    borderRadius: '5px',
    paddingLeft: '15px',
  },
  inputFocus: {
    height: '50px',
    marginTop: '50px',
    background: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '4px',
    borderRadius: '5px',
    paddingLeft: '15px',
    width: '100%',
    color: theme.palette.type === 'light' ? grey[600] : grey[400],
  },
  checkBox: {
    paddingLeft: 8,
  },
}));

/**
 * Add credit card info
 * @param {Object} props
 */
export function PaymentInfo(props) {
  const log = window.log('PaymentInfo');

  const { snack } = useContext(AppContext);
  const {
    errorState,
    clientToken,
    handleChange,
    onboardObject,
    setErrorState,
    setTokenizeFunc,
    setOnboardObject,
  } = props;

  const theme = useTheme();
  const classes = useStyles();

  const [focusedFieldName, setFocusedField] = useState('');
  const numberField = useRef();
  const cvvField = useRef({ field: 'CVC' });
  const postalCodeField = useRef();

  const [isBtReady, setIsBtReady] = useState(false);

  const onAuthorizationSuccess = () => {
    setIsBtReady(true);
    numberField.current.focus();
  };

  const onDataCollectorInstanceReady = collector => {
    // DO SOMETHING with Braintree collector as needed
    log('COLLECTOR', collector);
  };

  const handleError = (error = {}) => {
    log('Handling braintree error: ', error);
    let fieldError = {
      number: false,
      expirationDate: false,
      cvv: false,
      postalCode: false,
    };
    if (error.details && error.details.invalidFieldKeys) {
      error.details.invalidFieldKeys.forEach(field => {
        fieldError[field] = true;
      });
    } else if (
      error.message ===
        'All fields are empty. Cannot tokenize empty card fields.' &&
      error.code === 'HOSTED_FIELDS_FIELDS_EMPTY'
    ) {
      let fields = ['number', 'expirationDate', 'cvv', 'postalCode'];
      fields.forEach(field => {
        fieldError[field] = true;
      });
    }

    setErrorState(prevState => {
      return {
        ...prevState,
        ...fieldError,
        firstName: !onboardObject.firstName,
        lastName: !onboardObject.lastName,
      };
    });
    snack('Something went wrong. Please double check all fields.', 'error');
  };

  const onFieldBlur = () => setFocusedField('');
  const onFieldFocus = (field, event) => setFocusedField(event.emittedBy);

  const onCardTypeChange = ({ cards }) => {
    let cardCode = 'CVV';
    if (cards.length === 1) {
      const [card] = cards;
      if (card.code && card.code.name) {
        cardCode = card.code.name;
      }
    }
    setOnboardObject(prevState => {
      return { ...prevState, cardCVC: cardCode };
    });
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header='Enter payment information'
        desc='Your MiSensors Account requires a valid credit card.  The card will be used for any future annual renewals and any in app upgrade purchases.  To Disable Auto-Renew: In the application, click on the GEAR ICON in the upper right corner, select Profile.  Then EDIT your billing settings.'
      />
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          color:
            theme.palette.type === 'light'
              ? theme.palette.grey[600]
              : theme.palette.text.primary,
        }}
      >
        Your card will not be charged at this time.
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name='firstName'
          value={onboardObject.firstName}
          className={classes.textField}
          onChange={handleChange}
          fullWidth
          variant='outlined'
          placeholder='First name'
          error={errorState.firstName}
          inputRef={numberField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name='lastName'
          value={onboardObject.lastName}
          className={classes.textField}
          onChange={handleChange}
          fullWidth
          variant='outlined'
          placeholder='Last name'
          error={errorState.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPhoneNumber
          autoComplete='off'
          className={classes.textField}
          fullWidth
          variant='outlined'
          value={onboardObject.phoneNum}
          placeholder='Phone Number'
          label='Phone Number'
          defaultCountry={'us'}
          preferredCountries={['us']}
          disableAreaCodes={true}
          countryCodeEditable={false}
          autoFormat={true}
          onChange={e => setOnboardObject({ ...onboardObject, phoneNum: e })}
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Braintree
          className={isBtReady ? '' : 'disabled'}
          authorization={clientToken}
          onAuthorizationSuccess={onAuthorizationSuccess}
          onDataCollectorInstanceReady={onDataCollectorInstanceReady}
          onError={handleError}
          onCardTypeChange={onCardTypeChange}
          getTokenRef={ref => setTokenizeFunc(() => ref)}
          styles={{
            input: {
              'font-size': 'inherit',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor='credit-card-number'>
                  Card Number
                </InputLabel>
                <HostedField
                  type='number'
                  className={`${
                    focusedFieldName === 'number'
                      ? classes.inputFocus
                      : classes.inputNormal
                  } ${errorState.number ? classes.error : ''} `}
                  placeholder='xxxx xxxx xxxx xxxx'
                  onBlur={onFieldBlur}
                  onFocus={onFieldFocus}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='expirationDate'>
                  Expiration date
                </InputLabel>
                <HostedField
                  type='expirationDate'
                  placeholder='MM/YY'
                  onBlur={onFieldBlur}
                  onFocus={onFieldFocus}
                  className={`${
                    focusedFieldName === 'expirationDate'
                      ? classes.inputFocus
                      : classes.inputNormal
                  } ${errorState.expirationDate ? classes.error : ''}`}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='cvv'>Security Code</InputLabel>
                <HostedField
                  type='cvv'
                  ref={cvvField}
                  onBlur={onFieldBlur}
                  onFocus={onFieldFocus}
                  className={`${
                    focusedFieldName === 'cvv'
                      ? classes.inputFocus
                      : classes.inputNormal
                  } ${errorState.cvv ? classes.error : ''}`}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor='expirationDate'>Zip Code</InputLabel>
                <HostedField
                  type='postalCode'
                  placeholder='Zip Code'
                  ref={postalCodeField}
                  onBlur={onFieldBlur}
                  onFocus={onFieldFocus}
                  onValidityChange={e => log(e)}
                  className={`${
                    focusedFieldName === 'postalCode'
                      ? classes.inputFocus
                      : classes.inputNormal
                  } ${errorState.postalCode ? classes.error : ''}`}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Braintree>
      </Grid>
    </Grid>
  );
}
