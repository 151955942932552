import _ from 'lodash';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import green from '@material-ui/core/colors/green';
import SnoozeIcon from '@material-ui/icons/Snooze';
import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  Avatar,
  Button,
  Dialog,
  Select,
  MenuItem,
  withWidth,
  makeStyles,
  Typography,
  FormControl,
  DialogContent,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { AppContext } from './../../AppContext';
import RangeAlertDisplay from './RangeAlertDisplay';
import SensorImage from '../../img/icons/sensor.svg';
import { convertToF, convertToPSI } from '../../conversions';
import { determineSimpleLuxNum } from '../common/helpers/calculateLuxDisplay';

const ResolveAlertModal = ({
  width,
  device,
  maxRange,
  minRange,
  maxValue,
  minValue,
  alertIcon,
  alertName,
  alertType,
  openState,
  rangeType,
  handleClose,
  sensorValue,
  currentAlert,
  measurementUnit,
  updateAlertPage,
  messageReceivedDate,
}) => {
  const log = window.log('ResolveAlertModal');
  const isSmall = _width => {
    switch (_width) {
      case 'sm':
        return true;
      case 'xs':
        return true;
      default:
        return false;
    }
  };

  log('device', device);

  const useStyles = makeStyles(theme => ({
    titleContainer: {
      padding: theme.spacing(1),
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.background.paper
          : theme.palette.background.paper,
      height: '60px',
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    dialog: {
      width: '100%',
      height: '100%',
      padding: '0 !important',
      [theme.breakpoints.up('sm')]: {
        width: '50vw',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    deviceContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
    },
    deviceInfo: {
      marginLeft: theme.spacing(2),
      color: 'white',
      fontSize: 14,
    },
    rangeDisplayContainer: {
      padding: theme.spacing(3, 4, 4, 2),
      overflow: 'auto',
    },
    subsectionContainer: {
      padding: theme.spacing(3),
      backgroundColor:
        theme.palette.type === 'light'
          ? '#F4F5F5'
          : theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 140px - 116px - 60px)',
      },
    },
    subsectionText: {
      fontWeight: 'bold',
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
    },
    optionButtonsContainer: {
      padding: theme.spacing(3),
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      width: '100%',
    },
    selectContainer: {
      padding: theme.spacing(0, 3),
    },
    actionButtonsContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      padddingLeft: 0,
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    actionButtons: {
      padding: theme.spacing(1, 5),
      fontWeight: 'bold',
      margin: theme.spacing(0),
      width: '10vw',
    },
    actionButtonsSnooze: {
      width: '100%',
      padding: theme.spacing(0, 5),
    },
    optionButtons: {
      padding: theme.spacing(1, 5),
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(1, 0),
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      width: '100%',
    },
    snoozeSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: 'white',
    },
    snoozeIcon: {
      fontSize: 32,
      color: theme.palette.warning.main,
    },
    snoozeSelectedIcon: {
      color: 'white',
    },
    checkIcon: {
      fontSize: 32,
      color: green[500],
    },
    avatarLarge: {
      width: '15%',
      height: 'auto',
      backgroundColor: theme.palette.background.paper,
    },
    select: {
      backgroundColor: theme.palette.background.paper,
    },
    formSelect: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    dialogContent: {
      inlineSize: 'fit-content',
    },
    cardContainer: {
      width: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    doorOrPanicText: {
      fontWeight: 'bold',
      color: '#F44336',
      fontSize: 20,
    },
    avatarImage: {
      objectFit: !device.deviceImageUrl && 'none',
    },
  }));
  log('device', device);

  log(device.deviceImageUrl);
  const classes = useStyles();

  const {
    snack,
    appState,
    appState: {
      userSettings: { tempType, pressType, advancedLux },
    },
    getAlertCount,
    setLogoutState,
  } = useContext(AppContext);

  if (alertType === 'temp_environment') {
    //Update the temperature value based on user preference
    minRange = convertToF(minRange, tempType);
    maxRange = convertToF(maxRange, tempType);
    minValue = convertToF(minValue, tempType);
    maxValue = convertToF(maxValue, tempType);
  } else if (alertType === 'pressure') {
    minRange = convertToPSI(minRange, pressType);
    maxRange = convertToPSI(maxRange, pressType);
  } else if (alertType === 'light') {
    if (!advancedLux) {
      minValue = 0;
      maxValue = 3;
      minRange = determineSimpleLuxNum(minRange);
      maxRange = determineSimpleLuxNum(maxRange);
      sensorValue = determineSimpleLuxNum(sensorValue);
      measurementUnit = '';
    }
  }

  const snoozeOptions = [
    {
      label: '5 minutes',
      value: '5',
    },
    {
      label: '15 minutes',
      value: '15',
    },
    {
      label: '30 minutes',
      value: '30',
    },
    {
      label: '45 minutes',
      value: '45',
    },
    {
      label: '1 Hour',
      value: '60',
    },
    {
      label: '2 Hour',
      value: '120',
    },
    {
      label: '6 Hour',
      value: '360',
    },
    {
      label: '12 Hour',
      value: '720',
    },
    {
      label: '1 Day',
      value: '1440',
    },
    {
      label: '2 Day',
      value: '2880',
    },
    {
      label: '5 Day',
      value: '7200',
    },
    {
      label: '7 Day',
      value: '10080',
    },
  ];

  const snoozeOptionsButtons = [
    {
      label: '5 min',
      value: '5',
    },
    {
      label: '1 Hour',
      value: '60',
    },
    {
      label: '1 Day',
      value: '1440',
    },
    {
      label: '15 min',
      value: '15',
    },
    {
      label: '2 Hour',
      value: '120',
    },
    {
      label: '2 Day',
      value: '2880',
    },
    {
      label: '30 min',
      value: '30',
    },
    {
      label: '6 Hour',
      value: '360',
    },
    {
      label: '5 Day',
      value: '7200',
    },
    {
      label: '45 min',
      value: '45',
    },
    {
      label: '12 Hour',
      value: '720',
    },
    {
      label: '7 Day',
      value: '10080',
    },
  ];

  // const [snoozeMenuAnchor, setSnoozeMenuAnchor] = useState(null);

  const [showSnoozeSelect, setShowSnoozeSelect] = useState(true);

  const [validateSelector, setValidateSelector] = useState(true);

  const [selectedSnoozeTime, setSelectedSnoozeTime] = useState(
    snoozeOptions[1].value,
  );

  const onClickSnooze = event => {
    setShowSnoozeSelect(!showSnoozeSelect);
  };

  // const onCloseSnooze = () => {
  //   setSnoozeMenuAnchor(null);
  // };

  const handleSnoozeSelectChange = event => {
    const value = event.target.value;
    setSelectedSnoozeTime(value);
  };
  // Handle Snooze Select from new buttons
  const handleSnoozeSelectChangeButtons = value => {
    setSelectedSnoozeTime(value);
  };

  const barSize = () => {
    if (isSmall(width)) {
      return 300;
    } else {
      return 450;
    }
  };

  const resolveAlert = async () => {
    log('Resolve alert clicked');
    log('Looking at the app state\n', appState);
    let data = {
      userId: appState.auth.userInfo.id,
      currentAlertId: currentAlert.info.id,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/resolveAlert',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      log('Looking at the response ', response);
      const json = await response.json();
      log('Looking at json ', json);
      if (json.success) {
        snack('Alert acknowledged', 'success');
        getAlertCount();
        updateAlertPage && updateAlertPage();
        setSelectedSnoozeTime(snoozeOptions[1].value);
        handleClose();
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
          if (error.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const snoozeAlert = async () => {
    log('Snooze alert clicked with ' + selectedSnoozeTime);
    log('Looking at the app state\n', appState);

    let data = {
      userId: appState.auth.userInfo.id,
      currentAlertId: currentAlert.info.id,
      selectedSnoozeTime: selectedSnoozeTime,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/snoozeAlert',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        },
      );
      log('Looking at the response ', response);
      let json = await response.json();
      log('Looking at json ', json);
      if (json.success) {
        snack('Alert snoozed', 'success');
        getAlertCount();
        updateAlertPage && updateAlertPage();
        handleClose();
      } else {
        let errorObj = {};
        json.errors.forEach(error => {
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, 'error');
          if (error.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * If values are for Farenheit temp, converts temp
   * @param {number} value
   * @returns
   */
  const checkValueToConvert = value => {
    let newValue = _.clone(value);
    if (measurementUnit?.includes('F')) {
      newValue = convertToF(newValue, 'F');
    }
    return newValue;
  };
  return (
    <Dialog
      open={openState}
      onClose={handleClose}
      aria-labelledby='resolve-alert-modal-title'
      aria-describedby='resolve-alert-modal-description'
      fullScreen={isSmall(width)}
      fullWidth={false}
      maxWidth={'md'}
    >
      <DialogContent
        classes={{ root: classes.dialogContent }}
        className={classes.dialog}
      >
        <Grid className={classes.cardContainer} container alignItems='stretch'>
          <Grid
            item
            container
            xs={12}
            justify='center'
            alignItems='center'
            className={classes.titleContainer}
          >
            <Box>{alertIcon}</Box>
            <Box>
              <Typography className={classes.title}>{alertName}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify='center'
            alignItems='center'
            className={classes.deviceContainer}
          >
            <Avatar
              classes={{ img: classes.avatarImage }}
              alt='avatar'
              src={device.deviceImageUrl ? device.deviceImageUrl : SensorImage}
              className={classes.avatarLarge}
            />
            <Box className={classes.deviceInfo}>
              <Box fontWeight='bold'>{device.deviceName}</Box>
              <Box>{`Sensor ID: ${device.sensorId}`}</Box>
              <Box>{device.location}</Box>
              <Box>
                {appState.auth.userInfo &&
                appState.userSettings?.timeFormat === '12hr'
                  ? `Date Received: ${moment(messageReceivedDate).format(
                      'MM/DD/YYYY hh:mm a',
                    )}`
                  : `Date Received: ${moment(messageReceivedDate).format(
                      'MM/DD/YYYY kk:mm',
                    )}`}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify='center'
            alignItems='center'
            className={classes.rangeDisplayContainer}
          >
            {alertType === 'door' ||
            alertType === 'panic' ||
            alertType === 'water' ||
            alertType === 'motion' ? (
              <Typography className={classes.doorOrPanicText}>
                {currentAlert.info.lastSensorValue}
              </Typography>
            ) : (
              <RangeAlertDisplay
                openState={openState}
                minRange={checkValueToConvert(minRange)}
                maxRange={checkValueToConvert(maxRange)}
                alertValue={checkValueToConvert(sensorValue)}
                barWidth={barSize()}
                alertType={alertType}
                rangeType={rangeType}
                alertSetting={appState?.userSettings?.advancedLux}
                measurementUnit={measurementUnit}
              />
            )}
          </Grid>
          <Grid item container xs={12} className={classes.subsectionContainer}>
            <Grid item container xs={12} justify='center'>
              <Box className={classes.subsectionText}>
                Would you like to Snooze or mark this alert as Resolved?
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems='center'
              className={classes.optionButtonsContainer}
            >
              <Button
                variant='contained'
                aria-controls='snooze-menu'
                aria-haspopup='true'
                className={`${classes.optionButtons} ${
                  showSnoozeSelect ? classes.snoozeSelected : ''
                }`}
                startIcon={
                  <SnoozeIcon
                    className={`${classes.snoozeIcon}  ${
                      showSnoozeSelect ? classes.snoozeSelectedIcon : ''
                    }`}
                  />
                }
                onClick={onClickSnooze}
              >
                Snooze Alert
              </Button>
              <Button
                variant='contained'
                className={classes.optionButtons}
                startIcon={<CheckIcon className={classes.checkIcon} />}
                onClick={() => resolveAlert()}
              >
                Resolve Alert
              </Button>
            </Grid>
            {showSnoozeSelect && isMobileSize ? (
              <Grid
                item
                container
                xs={12}
                alignItems='center'
                justify='center'
                className={classes.selectContainer}
              >
                <FormControl variant='outlined' className={classes.formSelect}>
                  <Select
                    className={classes.select}
                    value={selectedSnoozeTime}
                    onChange={handleSnoozeSelectChange}
                    onOpen={() => setValidateSelector(false)}
                    onClose={() => setValidateSelector(true)}
                  >
                    {snoozeOptions.map(({ label, value }, index) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {showSnoozeSelect && !isMobileSize ? (
              <Grid
                item
                container
                xs={12}
                alignItems='center'
                justify='center'
                className={classes.selectContainer}
                spacing={2}
              >
                {snoozeOptionsButtons.map(({ label, value }, index) => {
                  return (
                    <Grid item xs={12} sm={4} key={index}>
                      <Button
                        className={classes.actionButtonsSnooze}
                        variant={
                          selectedSnoozeTime === value
                            ? 'contained'
                            : 'outlined'
                        }
                        color='primary'
                        size='large'
                        onClick={() => {
                          handleSnoozeSelectChangeButtons(value);
                        }}
                      >
                        {label}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              alignItems='center'
              className={classes.actionButtonsContainer}
            >
              <Button
                className={classes.actionButtons}
                variant='outlined'
                color='primary'
                size={isMobileSize ? 'medium' : 'large'}
                onClick={handleClose}
                style={{
                  marginLeft: isMobileSize || showSnoozeSelect ? 0 : 62,
                  width: '40%',
                }}
              >
                Exit
              </Button>
              {showSnoozeSelect ? (
                <Button
                  className={classes.actionButtons}
                  variant='contained'
                  color='primary'
                  size={isMobileSize ? 'medium' : 'large'}
                  onClick={() => snoozeAlert()}
                  style={{ marginRight: isMobileSize ? 0 : 16, width: '40%' }}
                >
                  Snooze
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withWidth()(ResolveAlertModal);
