import _ from 'lodash';
import localforage from 'localforage';
import { navigate } from 'hookrouter';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getDashApi } from './api/api';


import getSubscriptionInfo from './components/common/getSubscriptionInfo';

export const AppContext = React.createContext({});

export const AppProvider = props => {
  const initState = {
    pageView: {
      sensors: null,
      gateways: null,
      subUsers: null,
      locations: null,
    },
    userSettings: {
      tempType: 'F',
      darkMode: false,
      pressType: 'PSI',
      advancedLux: false,
      timeFormat: '12hr',
      weeklyReports: false,
      nightlyReports: false,
      primaryColor: '#08B3FF',
    },
    // init subFeatures is plan is BASIC MSLIC0606
    subFeatures: {
      name: 'basic',
      noOfLocations: 1,
      noOfGateways: 2,
      noOfSensors: 6,
      noOfUsers: 2,
      cellularMode: null,
      alertDefinitions: true,
      smsNotifications: true,
      emailNotifications: true,
      alertAutoResolve: true,
      alertResolveDesc: false,
      alertResolveDescAndWorkflow: false,
      alertNotificationSched: false,
      nightlyReports: true,
      easyReports: 7,
      customReports: 0,
      customReportsEmailed: 0,
      advEdgeAlerts: false,
      resellerWebAccess: false,
      customGatewayTypes: false,
      customerSensorTypes: false,
      dataStorare: 30,
    },
    auth: {
      loggedIn: null,
      token: null,
      isDirect: false,
      userInfo: {
        profile: {
          eulaConfirmed: null,
          profileImageLocation: null,
          sendPromotions: true,
        },
        themePreferences: {
          platformImageLocation: null,
          faviconImageLocation: null,
        },
      },
    },
  };

  const [state, setState] = useState(initState);

  // loggedin state needs to change separately from state to allow app.js to execute logout()
  const [logoutState, setLogoutState] = useState(false);

  // settings ModalChart
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openOnboardModal, setOpenOnboardModal] = useState(false);
  // edit alert warning modal
  const [openAlertWarning, setOpenAlertWarning] = useState(false);
  //Used for adding entities
  const [openGatewayModal, setGatewayModalState] = useState(false);
  const [openSensorModal, setSensorModalState] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openUserModal, setUserModalState] = useState(false);
  const [openLocationModal, setLocationModalState] = useState(false);
  const [openAddingModal, setOpenAddingModal] = useState(false);
  const [openResellerModal, setOpenResellerModal] = useState(false);

  //Used for editing entities
  const [openEditGatewayModal, setOpenEditGatewayModal] = useState(false);
  const [openEditSensorModal, setOpenEditSensorModal] = useState(false);
  const [openEditAlertModal, setOpenEditAlertModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [openEditResellerModal, setOpenEditResellerModal] = useState(false);

  //Used for deleting entities
  const [openDeleteGatewayModal, setOpenDeleteGatewayModal] = useState(false);
  const [openDeleteSensorModal, setOpenDeleteSensorModal] = useState(false);
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState(false);

  // Snapshot Dialog for devices
  const [openSnapshotModal, setOpenSnapshotModal] = useState(false);
  // Settings for alerts
  const [alertCount, setAlertCount] = useState(0);
  const [alertsArray, setAlertsArray] = useState([]);

  const [contextAlertCount, setContextAlertCount] = useState(0);
  const [contextLocationCount, setContextLocationCount] = useState(0);
  const [contextGatewayCount, setContextGatewayCount] = useState(0);
  const [contextSensorCount, setContextSensorCount] = useState(0);
  const [featureCount, setFeatureCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);

  //  Global variables for numberOfEntities
  const [locations, setLocations] = useState([]);
  // Entities
  const [contextOfflineSensor, setContextOfflineSensor] = useState();
  const [contextResolvedAlerts, setContextResolvedAlerts] = useState();
  const [gateways, setGateways] = useState()
  const [fullSensorList, setFullSensorList] = useState()
  const [fullGatewayList, setFullGatewayList] = useState()

  const log = window.log('App Provider');

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant, action, preventDuplicate = true) =>
    enqueueSnackbar(msg, {
      action,
      variant,
      preventDuplicate,
      autoHideDuration: 5000,
    });

  const setAppState = async newState => {
    const auxStateObj = {
      pageView: initState.pageView,
      userSettings: initState.userSettings,
      subFeatures: initState.subFeatures,
    };

    if (newState?.auth?.userInfo?.profile?.pageView) {
      auxStateObj.pageView = newState.auth.userInfo.profile.pageView;
    }

    if (newState?.auth?.userInfo?.profile?.userSettings) {
      auxStateObj.userSettings = newState.auth.userInfo.profile.userSettings;
    }

    if (newState?.auth?.userInfo?.subFeatures) {
      auxStateObj.subFeatures = newState.auth.userInfo.subFeatures;
    }

    const mergedState = _.merge(newState, auxStateObj);

    try {
      await localforage.setItem('appState', mergedState); // Save current state to local storage.
    } catch (err) {
      console.error(err);
    }

    setState(mergedState); // Update current state.
  };

  /**
   * updates user db with new params
   * @param {string} profileKey
   * @param {*} profileValue
   */
  const updateUserProfile = async (profileKey, profileValue) => {
    //  Wrap up all the user profile info into one object
    if (state.auth.loggedIn) {
      const { profile } = state.auth.userInfo;
      profile.pageView = state.pageView;
      profile.userSettings = state.userSettings;

      let changedProfile = { [profileKey]: profileValue };

      if (profileKey.includes('.')) {
        const profileKeys = profileKey.split('.');
        let profileObj = {};

        if (profile && profile[profileKeys[0]]) {
          profileObj = profile[profileKeys[0]];
        }

        if (profileKey === 'pageView.all') {
          if (typeof profileValue === 'boolean') {
            for (let page in initState.pageView) {
              profileObj[page] = profileValue;
            }
          } else {
            profileObj = profileValue;
          }
        } else {
          profileObj[profileKeys[1]] = profileValue;
        }
        changedProfile = { [profileKeys[0]]: { ...profileObj } };
      }

      const userProfile = {
        ...profile,
        ...changedProfile,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/editUserProfile`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              authorization: state.auth.token,
            },
            body: JSON.stringify({
              userId: state.auth.userInfo.id,
              userProfile,
            }),
          },
        );
        const res = await response.json();
        if (res.success && res.data) {
          // Break profile into individual elements
          const clonedState = _.cloneDeep(state);
          clonedState.auth.userInfo.profile = res.data;
          clonedState.pageView = res.data.pageView;
          clonedState.userSettings = res.data.userSettings;
          setAppState(clonedState);
        }
      } catch (err) {
        snack('Network Error', 'error');
      }
    }
  };

  const getProfileSetting = setting => {
    let profileSetting = false;
    if (!state?.auth?.userInfo?.profile) {
      return false;
    }
    let { profile } = state.auth.userInfo;

    if (setting.includes('.')) {
      const settingArr = setting.split('.');
      if (profile[settingArr[0]] && profile[settingArr[0]][settingArr[1]]) {
        profileSetting = profile[settingArr[0]][settingArr[1]];
      }
    } else {
      if (profile[setting]) {
        profileSetting = profile[setting];
      }
    }
    return profileSetting;
  };

  // If a modal open
  const modalOpenState = () => {
    const settingsModal = openOnboardModal || openSettingsModal;
    const addBoolean =
      openGatewayModal ||
      openSensorModal ||
      openAlertModal ||
      openUserModal ||
      openLocationModal ||
      openResellerModal ||
      openAddingModal;
    const editBoolean =
      openEditGatewayModal ||
      openEditSensorModal ||
      openEditAlertModal ||
      openEditUserModal ||
      openEditLocationModal;
    const deleteBoolean =
      openDeleteGatewayModal ||
      openDeleteSensorModal ||
      openDeleteAlertModal ||
      openDeleteUserModal ||
      openDeleteLocationModal;
    return (
      openAlertWarning ||
      settingsModal ||
      addBoolean ||
      editBoolean ||
      deleteBoolean
    );
  };

  const limitReachedSnackbar = type => {
    return snack(
      '',
      'error',
      () => (
        <div
          onClick={() => navigate('/current/user/billing')}
          style={{ cursor: 'pointer' }}
        >
          <Typography variant='subtitle2'>
            Limit reached. Click here to upgrade subscription and add more{' '}
            {type}.
          </Typography>
        </div>
      ),
      false,
    );
  };

  /**
   * Handles opening and closing of sensor modal
   * @param {boolean} open - Sensor modal state
   * @param {boolean} isEdit - Set to true if this is an edit modal
   */
  const setOpenSensorModal = async (open, isEdit) => {
    if (open && !isEdit) {
      const json = await checkLimitRequest('sensor');
      if (!json.success) return limitReachedSnackbar('sensors');
    }
    setSensorModalState(open);
  };

  /**
   * Handles opening and closing of user modal
   * @param {boolean} open - user modal state
   * @param {boolean} isEdit - Set to true if this is an edit modal
   */
  const setOpenUserModal = async (open, isEdit) => {
    if (open && !isEdit) {
      const json = await checkLimitRequest('user');
      if (!json.success) return limitReachedSnackbar('users');
    }
    setUserModalState(open);
  };

  /**
   * Handles opening and closing of gateway modal
   * @param {boolean} open - gateway modal state
   * @param {boolean} isEdit - Set to true if this is an edit modal
   */
  const setOpenGatewayModal = async (open, isEdit) => {
    if (open && !isEdit) {
      const json = await checkLimitRequest('gateway');
      if (!json.success) return limitReachedSnackbar('gateways');
    }
    setGatewayModalState(open);
  };

  /**
   * Handles opening and closing of location modal
   * @param {boolean} open - location modal state
   * @param {boolean} isEdit - Set to true if this is an edit modal
   */
  const setOpenLocationModal = async (open, isEdit) => {
    // NOTE: currently being ignored, locations need gateways, so this is controlled by that number
    // if (open && !isEdit) {
    //   const json = await checkLimitRequest('location');
    //   if (!json.success) return limitReachedSnackbar('locations');
    // }
    setLocationModalState(open);
  };

  const checkLimitRequest = async type => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${type}/checkLimit`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: state.auth.token,
          },
        },
      );
      const json = await response.json();
      return json;
    } catch (err) {
      console.log('Network Error');
    }
    return { success: true };
  };

  //  Intervals
  const [notificationInterval, setNotificationInterval] = useState(null);
  const [locationsInterval, setLocationsInterval] = useState(null);
  const [companyCountInterval, setcompanyCountInterval] = useState(null);

  useEffect(() => {
    // On initial run, state of loggedIn is null
    if (state.auth.loggedIn === null) return;
    getGateways()
    getSensorHardwareProfiles()
    getGatewayHardwareProfiles()
    if (!notificationInterval && state.auth.loggedIn) {
      setNotificationInterval(
        setInterval(() => {
          getAlertCount();
        }, 20000),
      );
    }

    if (!companyCountInterval && state.auth.loggedIn) {
      setcompanyCountInterval(
        setInterval(() => {
          fetchCompanyCounts();
          getLocations();
        }, 60000),
      );
    }

    if (notificationInterval && !state.auth.loggedIn) {
      clearInterval(notificationInterval);
      setNotificationInterval(null);
    }
    if (companyCountInterval && !state.auth.loggedIn) {
      clearInterval(companyCountInterval);
      setcompanyCountInterval(null);
      clearInterval(locationsInterval);
      setLocationsInterval(null);
    }
  }, [state.auth.loggedIn]);

  const getAlertCount = async () => {
    log('Get alert count called');
    if (!state.auth.loggedIn) return false;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/alert/allPage',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: state.auth.token,
          },
        },
      );
      const json = await response.json();
      // log("Alert count json ", json);
      if (json.success) {
        setAlertCount(json.data.activeAlertCount);
        setAlertsArray(json.data.alertsArray);
        // log("The notification count " + json.data.notificationCount);
      } else {
        setAppState({
          auth: {
            loggedIn: false,
          },
        });
        navigate('/');
      }
    } catch (err) {
      log('Failed to get alert count.');
    }
  };

  const versionNumber = 'v1.1.0';
  const getLocations = async () => {
    log('Get locations called');
    if (!state.auth.loggedIn) return false;
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/location/get`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: state.auth.token,
          },
        },
      );

      const json = await request.json();
      if (json.success) {
        setLocations(json.data.locations);
      } else {
        setLocations([]);
        console.log('errors', json.errors)
        var errorCheck = ''
        json.errors.forEach(err => {
          if (err.type === 'token' && errorCheck !== 'token') {
            console.log(1)
            errorCheck = 'token'
            snack('Please Login', 'error')
            setLogoutState(true);
          } else if(err.type !== 'token') {
            snack(err.msg, 'error');
          }
          // if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }
  };

  const fetchCompanyCounts = async () => {
    log('Fetching company count');
    if (!state.auth.loggedIn) return false;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/company/get',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: state.auth.token,
          },
        },
      );

      const json = await response.json();
      if (json.success) {
        const {
          locationCount,
          gatewayCount,
          sensorCount,
          activeAlertCount,
          resolvedAlerts,
          offlineSensors,
        } = json.data;

        setContextLocationCount(locationCount);
        setContextGatewayCount(gatewayCount);
        setContextSensorCount(sensorCount);
        setContextAlertCount(activeAlertCount);
        setContextResolvedAlerts(resolvedAlerts);
        setContextOfflineSensor(offlineSensors);

        if (json?.data?.company[0]?.plan_id !== state?.auth?.userInfo?.planId) {
          setState(prevState => {
            return {
              ...prevState,
              subFeatures: json.data.subFeatures,
            };
          });
        }
      } else {
        json.errors.forEach(err => {
          // snack(err.msg, 'error');
          if (err.type === 'token') setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
      setState({
        loading: false,
        networkErr: true,
        auth: {
          loggedIn: false,
        },
      });
    }
  };

  const getGateways = async () => {
    let resp = await getDashApi('/gateway/get', state.auth.token);
    if (resp.success) {
      setGateways(resp.data.gateways)
    }
  };

  const getSensorHardwareProfiles = async () => {
    let resp = await getDashApi('/sensor/getHardwareProfiles', state.auth.token);
    if (resp.success) {
      setFullSensorList(resp.data.sensorProfiles)
    }
  };

  const getGatewayHardwareProfiles = async () => {
    let resp = await getDashApi('/gateway/getHardwareProfiles', state.auth.token)
    if(resp.success) {
      setFullGatewayList(resp.data.gatewayProfiles)
    }
  }

  const darkModeContainerColor = () => {
    const { profile } = state.auth.userInfo;
    return profile.userSettings?.darkMode;
  };
  return (
    <AppContext.Provider
      value={{
        appState: state,
        setAppState,
        logoutState,
        setLogoutState,
        openGatewayModal,
        setOpenGatewayModal,
        openSensorModal,
        setOpenSensorModal,
        openEditSensorModal,
        setOpenEditSensorModal,
        openDeleteSensorModal,
        setOpenDeleteSensorModal,
        openAlertModal,
        setOpenAlertModal,
        openUserModal,
        setOpenUserModal,
        openEditUserModal,
        setOpenEditUserModal,
        openDeleteUserModal,
        setOpenDeleteUserModal,
        openLocationModal,
        setOpenLocationModal,
        openEditLocationModal,
        setOpenEditLocationModal,
        openDeleteLocationModal,
        setOpenDeleteLocationModal,
        openEditGatewayModal,
        setOpenEditGatewayModal,
        openEditAlertModal,
        setOpenEditAlertModal,
        openDeleteGatewayModal,
        setOpenDeleteGatewayModal,
        openDeleteAlertModal,
        setOpenDeleteAlertModal,
        alertCount,
        setAlertCount,
        getAlertCount,
        contextAlertCount,
        setContextAlertCount,
        contextLocationCount,
        setContextLocationCount,
        contextGatewayCount,
        setContextGatewayCount,
        contextSensorCount,
        setContextSensorCount,
        featureCount,
        setFeatureCount,
        openSettingsModal,
        setOpenSettingsModal,
        setOpenAlertWarning,
        modalOpenState,
        openOnboardModal,
        setOpenOnboardModal,
        openAddingModal,
        setOpenAddingModal,
        openResellerModal,
        setOpenResellerModal,
        openEditResellerModal,
        setOpenEditResellerModal,
        snack,
        updateUserProfile,
        getProfileSetting,
        initState,
        alertsArray,
        setAlertsArray,
        // Snapshot Dialog
        openSnapshotModal,
        setOpenSnapshotModal,
        allUsers,
        setAllUsers,
        versionNumber,
        // Entities
        locations,
        setLocations,
        getLocations,
        fetchCompanyCounts,
        contextOfflineSensor,
        contextResolvedAlerts,
        limitReachedSnackbar,
        darkModeContainerColor,
        gateways,
        fullSensorList,
        fullGatewayList
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
