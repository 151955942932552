/* eslint-disable react-hooks/exhaustive-deps*/
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  Tooltip,
  Checkbox,
  FormGroup,
  TextField,
  makeStyles,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Popover,
  Typography
} from '@material-ui/core';

//  Icons
import CheckIcon from '@material-ui/icons/Check';
import Search from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';

//  Colors
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles(theme => ({
  tableSelection: {
    paddingTop: theme.spacing(3),
  },
  tableBody: {
    maxHeight: 350,
    minHeight: 350,
    borderRadius: 4,
  },
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  controlLabel: {
    marginLeft: 0,
    marginRight: 0,
    display: 'block',
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    height: 55,
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 200,
    fontSize: 13,
  },
}));

/**
 * Select Baseline Sensor
 * @param {*} props
 */
export default function SelectSensor(props) {
  const classes = useStyles();
  const {
    data,
    desc,
    header,
    columns,
    options,
    baselineSensorSel = null,
    handleSearchSelection,
  } = props;

  const [inputValue, setInputValue] = useState('');

  // We want to hijack the table for baseline sensors and rewrite the text that shows when a sensor is selected
  const sensorTableRef = useRef(null);
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const selectSensorTableOptions = {
    rowsPerPage: 6,
    pagination: false,
    rowsPerPageOptions: [],
    selectableRowsOnClick: true,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    selectToolbarPlacement: 'above',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    fixedHeader: false,
    search: false,
    textLabels: {
      body: {
        noMatch: 'No matching sensors',
      },
    },
    selectableRowsHeader: false,
  };

  const sensorTableColumns = [
    {
      name: 'sensorName',
      label: 'Device Name',
    },
    {
      name: 'sensorLocation',
      label: 'Location',
    },
    /// Column to show specific sensor warning if necessary
    {
      name: 'warning',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => 
        value ? (
            <div>
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <WarningIcon style={{ color: orange[400] }} />
            </Typography>
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>This sensor has already been assigned to an alert.</Typography>
            </Popover>
            </div>
          ) : (
            ''
          ),
      },
    },
  ];

  const usersTableColumns = [
    {
      name: 'fname',
      label: 'First Name',
    },
    {
      name: 'lname',
      label: 'Last Name',
    },
  ];

  const baselineSensorSelName = baselineSensorSel?.sensorName;

  useEffect(() => {
    if (
      sensorTableRef?.current?.tableContent?.current?.firstChild?.firstChild
        ?.firstChild?.innerHTML &&
      baselineSensorSelName
    ) {
      sensorTableRef.current.tableContent.current.firstChild.firstChild.firstChild.innerHTML = `${baselineSensorSelName} Selected`;
    }
  }, [baselineSensorSel]);

  const CustomCheckbox = props => {
    let newProps = Object.assign({}, props);
    const useStyles = makeStyles(theme => ({
      checkIcon: {
        height: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        border: newProps.checked
          ? `2px solid ${theme.palette.primary.main}`
          : '1px solid #9D9D9D',
        borderRadius: '6px',
      },

      icon: {
        fontSize: 22,
        color: theme.palette.primary.main,
      },
    }));
    const iconClasses = useStyles();
    return (
      <FormControl component='fieldset' className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            className={classes.controlLabel}
            control={
              <Checkbox
                name='selected'
                checked={newProps.checked}
                icon={
                  <div>
                    <div className={iconClasses.checkIcon} />
                  </div>
                }
                checkedIcon={
                  <div>
                    <div className={iconClasses.checkIcon}>
                      <CheckIcon className={iconClasses.icon} />
                    </div>
                  </div>
                }
                value='checkedI'
              />
            }
          />
        </FormGroup>
      </FormControl>
    );
  };

  const attrForSearch = columns === 'users' ? 'fname' : 'sensorName';

  return (
    <Grid item xs={12} ref={containerRef}>
      <ModalFormHeader header={header} desc={desc} />
      <Grid item xs={12} className={classes.tableSelection}>
        <Autocomplete
          value={''}
          onChange={(event, newValue) => {
            const index = data.indexOf(newValue);
            handleSearchSelection([
              {
                dataIndex: index,
                index: index,
              },
            ]);
            setInputValue('');
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={data}
          getOptionLabel={option =>
            option[attrForSearch] ? option[attrForSearch] : ''
          }
          renderOption={option => (
            <React.Fragment>{option[attrForSearch]}</React.Fragment>
          )}
          renderInput={params => {
            params.InputProps.startAdornment = (
              <InputAdornment position='start'>
                <Search style={{ color: grey[500] }} />
              </InputAdornment>
            );
            params.InputProps.endAdornment = undefined;
            return (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Search'
                className={classes.textField}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.tableSelection}>
        <MUIDataTable
          classes={{ responsiveBase: classes.tableBody }}
          options={{
            ...selectSensorTableOptions,
            ...options,
          }}
          columns={columns === 'users' ? usersTableColumns : sensorTableColumns}
          data={data}
          ref={sensorTableRef}
          components={{
            Checkbox: CustomCheckbox,
          }}
        />
      </Grid>
    </Grid>
  );
}
