import { navigate } from 'hookrouter';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Component imports
import {
  Box,
  Card,
  Grid,
  Link,
  Button,
  Switch,
  Tooltip,
  IconButton,
  Typography,
  CardContent,
  Paper
} from '@material-ui/core';

// Generic Imports
import { AppContext } from '../../AppContext';
import BreadCrumb from '../Generic/BreadCrumb';
import { permissions } from '../../permissions';
import SkeletonForm from '../common/SkeletonForm';
import ChangeSubscription from './ChangeSubscription';
import LoadingSpinner from '../Generic/LoadingSpinner';
import { listOfPlans } from './ChangeSubscriptionComponents/ListOfPlans';

// Icon import
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { formatBraintreeDate } from '../helper/formatBraintreeDate';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    width: '100%',
    height: '45vh',
    margin: theme.spacing(2, 0),
    overflowX: 'auto',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.75, 4),
  },
  card: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  cardSubtitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '17px',
  },
  cardElement: {
    minWidth: '100px',
  },
  table: {
    boxShadow: 'none !important',
    margin: theme.spacing(2, 0),
    '& tr:nth-of-type(odd)': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    '& tr:nth-of-type(even)': {
      backgroundColor:
        theme.palette.type === 'light' ? 'white' : theme.palette.grey[800],
    }
  },
}));

const SquareSwitch = withStyles(theme => ({
  root: {
    width: 52,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 8,
    marginTop: '2px',
  },
  track: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  edgeStart: {},
  edgeEnd: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={props.disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        edgeStart: classes.edgeStart,
        edgeEnd: classes.edgeEnd,
      }}
      {...props}
    />
  );
});

const AccountBilling = (props) => {
  const log = window.log('AccountBilling');

  const classes = useStyles();
  const { appState, setAppState, setLogoutState, snack } = useContext(
    AppContext,
  );

  const [
    changeSubscriptionOpenState,
    setchangeSubscriptionOpenState,
  ] = useState(false);
  const [autoRenew, setAutoRenew] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [subscriptionInfoLoadState, setSubscriptionInfoLoadState] = useState(
    false,
  );

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const openChangeSubscriptionModal = () => {
    setchangeSubscriptionOpenState(true);
  };

  const closeChangeSubscriptionModal = () => {
    setchangeSubscriptionOpenState(false);
  };

  /**
   * table options
   */
  const tableOptions = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: 'none',
    print: false,
    download: true,
    responsive: 'vertical',
    onDownload: (buildHead, buildBody, columns, data) => {
      let head = [];
      for (const header of columns) {
        if (header.label === 'Document Download') {
          continue;
        }
        head.push(header);
      }

      let body = [];
      for (const row of data) {
        row.data.pop();
        body.push(row);
      }

      return buildHead(head) + buildBody(body);
    },
    downloadOptions: {
      filename: 'misensors_invoice.csv',
      separator: ',',
    },
  };

  const tableColumns = [
    {
      name: 'invoiceNumber',
      label: 'Invoice Number',
      options: {
        filter: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'purchaseDate',
      label: 'Purchase Date',
      options: {
        filter: true,
      },
    },
    {
      name: 'invoiceTotal',
      label: 'Invoice Total',
      options: {
        filter: true,
      },
    },
    {
      name: 'documentDownload',
      label: 'Document Download',
      options: {
        filter: false,
        customBodyRender: value => {
          return (
            <Box
              display='flex'
              alignItems='center'
              style={{
                margin: -10,
              }}
            >
              <IconButton
                aria-label='download'
                color='primary'
                component={Link}
                href={value.downloadLink}
                download={'MiSensors Invoice #' + value.label + '.csv'}
              >
                <CloudDownloadIcon />
              </IconButton>

              <Typography>{`${value.label}`}</Typography>
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    reloadPageData();
  }, []);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    const userPerm = appState?.auth?.userInfo?.perm;
    if (!permissions[userPerm]?.profile?.addBillingButton) {
      navigate('/current/user/configuration');
    } else {
      reloadPageData();
    }
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  //Load or Reload Subscription info on load and after updating subscription plan
  const reloadPageData = async () => {
    await getSubscriptionInfo();
    await getTransactions();
    setInitialPageLoad(false);
  };

  const getSubscriptionInfo = async () => {
    setDisableSwitch(true);
    setSubscriptionInfoLoadState(true);
    try {
      const fetchSubscription = await fetch(
        process.env.REACT_APP_API_URL + '/payment/get-subscription',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await fetchSubscription.json();
      if (json.success) {
        setSubscriptionInfo(json.data);
        if (json.data.pendingSubscription) {
          setAutoRenew(true);
        } else {
          setAutoRenew(json.data.neverExpires);
        }
      } else {
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      snack(
        'Unable to find subscription info at this time. Please refresh the page and try again.',
        'error',
      );
    }
    setDisableSwitch(false);
    setSubscriptionInfoLoadState(false);
  };

  const getTransactions = async () => {
    try {
      const fetchTransactions = await fetch(
        process.env.REACT_APP_API_URL + '/transaction/list',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );
      const json = await fetchTransactions.json();
      if (json.success) {
        const planPrices = listOfPlans.map(plan => plan.price);
        const transactionList = json.data.transactions.map(transaction => {
          let type = null;
          if (planPrices.includes(Number(transaction.amount))) {
            type = 'Annual';
          } else {
            type = 'Upgrade';
          }

          let csvRows = [
            ['Invoice Number', 'Type', 'Purchase Date', 'Invoice Total'],
          ];
          csvRows.push([
            transaction.id,
            type,
            new Date(transaction.createdAt).toDateString(),
            '$' + transaction.amount,
          ]);
          let csvContent = 'data:text/csv;charset=utf-8,\uFEFF';
          csvRows.forEach(rowArray => {
            let row = rowArray.join(',');
            csvContent += row + '\n';
          });

          const encodedUri = encodeURI(csvContent);

          return {
            invoiceNumber: transaction.id,
            purchaseDate: new Date(transaction.createdAt).toLocaleDateString(
              undefined,
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            ),
            invoiceTotal: '$' + transaction.amount,
            type,
            documentDownload: {
              label: transaction.id,
              downloadLink: encodedUri,
            },
          };
        });
        setTransactions(transactionList);
      } else {
        json.errors.forEach(err => {
          if (err.type === 'token') {
            snack(err.msg, 'error');
            setLogoutState(true);
          }
        });
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      log(err);
      snack(err.message, 'error');
    }
  };

  const handleAutoRenew = async () => {
    if (!subscriptionInfo.planInfo) {
      snack(
        'Could not find your subscription info. Unable to process request at this time.',
        'error',
      );
      return;
    }
    setDisableSwitch(true);
    try {
      const autoRenewResponse = await fetch(
        process.env.REACT_APP_API_URL + '/payment/auto-renew',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            status: !autoRenew,
          }),
        },
      );

      const json = await autoRenewResponse.json();

      if (json.success) {
        if (!json.data.renewStatus) {
          getSubscriptionInfo();
        }
        setAutoRenew(json.data.renewStatus);
        snack('Successfully updated the subscription', 'success');
      } else {
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      log(err);
      snack('Unable to process request at this time.', 'error');
    }
    setDisableSwitch(false);
  };

  const renderSubscriptionEndDate = () => {
    if (autoRenew) {
      if (!subscriptionInfo.nextBillingDate) return 'N/A';
      let formattedDate = formatBraintreeDate(subscriptionInfo.nextBillingDate);
      if (formattedDate === 'N/A') return formattedDate;
      formattedDate = new Date(formattedDate).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      let billingAmount = subscriptionInfo.nextBillAmount;
      if (
        subscriptionInfo.pendingSubscription &&
        subscriptionInfo.pendingSubscription.price
      ) {
        billingAmount = subscriptionInfo.pendingSubscription.price;
      }
      return `${formattedDate} ($${billingAmount})`;
    } else {
      if (!subscriptionInfo.paidThroughDate) return 'N/A';
      let formattedDate = formatBraintreeDate(subscriptionInfo.nextBillingDate);
      if (formattedDate === 'N/A') return formattedDate;
      return new Date(formattedDate).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const renderPendingSubscriptionInfo = () => {
    if (!subscriptionInfo.pendingSubscription) return;

    let pendingPlan = null;
    for (const plan of listOfPlans) {
      if (plan.id === subscriptionInfo.pendingSubscription.planId) {
        pendingPlan = plan;
        break;
      }
    }

    if (!pendingPlan) return;
    return <Typography>{pendingPlan.text}</Typography>;
  };

  
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <React.Fragment>
      <ChangeSubscription
        reload={reloadPageData}
        token={appState.auth.token}
        currentSubscription={subscriptionInfo}
        openState={changeSubscriptionOpenState}
        handleClose={closeChangeSubscriptionModal}
      />
      {/* <RenewSubscription /> */}
      <div className={classes.topBar}>
        <BreadCrumb
          title='Profile - Billing'
          leadingIcon={
            <AccountBoxIcon style={{ fontSize: 32 }} color='primary' />
          }
        />

        {!isMobileSize ? (<Button
          variant='contained'
          color='primary'
          onClick={openChangeSubscriptionModal}
          className={classes.button}
        >
          CHANGE SUBSCRIPTION
        </Button>) : null}

      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 15}}>

        {isMobileSize ? (<Button
          variant='contained'
          color='primary'
          onClick={openChangeSubscriptionModal}
          className={classes.button}
        >
          CHANGE SUBSCRIPTION
        </Button>) : null}
      </div>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            data-testid='billing_card'
            container
            direction='row'
            justify='space-between'
          >
            <Grid
              data-testid='billing_card_subLevel'
              item
              className={classes.cardElement}
            >
              <Typography className={classes.cardSubtitle}>
                Subscription
              </Typography>
              {subscriptionInfo.planInfo && !subscriptionInfoLoadState ? (
                <Typography>
                  {subscriptionInfo.planInfo.description || 'N/A'}
                </Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: '5px 0 10px' }} />
              )}
              {/* <Typography>
                  1 Year
                </Typography> */}
            </Grid>
            <Grid
              data-testid='billing_card_noOfSensors'
              item
              className={classes.cardElement}
            >
              <Typography className={classes.cardSubtitle}>
                Number Of Sensors
              </Typography>
              {subscriptionInfo.numOfSensors ||
                (subscriptionInfo.numOfSensors === 0 &&
                  !subscriptionInfoLoadState) ? (
                <Typography>
                  {subscriptionInfo.numOfSensors ||
                    subscriptionInfo.numOfSensors === 0
                    ? subscriptionInfo.numOfSensors
                    : 'N/A'}
                </Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: '5px 0 10px' }} />
              )}
            </Grid>
            <Grid
              data-testid='billing_card_nextBill'
              item
              className={classes.cardElement}
            >
              <Typography className={classes.cardSubtitle}>
                {autoRenew ? 'Next Billing' : 'Expires'}
              </Typography>
              {subscriptionInfo.nextBillingDate &&
                !subscriptionInfoLoadState ? (
                <Typography>{renderSubscriptionEndDate()}</Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: '5px 0 10px' }} />
              )}
              {subscriptionInfo.pendingSubscription && autoRenew
                ? renderPendingSubscriptionInfo()
                : null}
            </Grid>
            <Grid
              data-testid='billing_card_autoRenew'
              item
              className={classes.cardElement}
            >
              <Grid style={{ display: 'flex' }}>
                <Typography className={classes.cardSubtitle}>
                  Auto-Renew
                </Typography>
                <Tooltip
                  placement='top'
                  title='Your subscription automatically renews. To avoid being charged, you must cancel at least a day before next billing date.'
                >
                  <HelpOutlineIcon style={{ marginLeft: 5 }} />
                </Tooltip>
              </Grid>
              <SquareSwitch
                data-testid='billing_card_autoRenew_switch'
                checked={autoRenew}
                onChange={handleAutoRenew}
                name='autoRenew'
                disabled={disableSwitch}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Paper className={ isMobileSize ? classes.paperContainer : null} >
      <MUIDataTable
        className={classes.table}
        options={tableOptions}
        data={transactions}
        columns={tableColumns}
        title={
          <Grid container>
            <Typography style={{ fontWeight: 'bold' }}>
              YOUR INVOICES
            </Typography>
          </Grid>
        }
      />
      </Paper>
    </React.Fragment>
  );
};

export default AccountBilling;
