import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './../../AppContext';

import _ from 'lodash';
import moment from 'moment-timezone';
import { navigate } from 'hookrouter';

import MUIDataTable from 'mui-datatables';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Grid, Typography } from '@material-ui/core';

import { convertToF, convertToPSI } from '../../conversions';
import { calculateLuxDisplay } from '../common/helpers/calculateLuxDisplay.js';

//COMPONENT
import DeviceCard from './DeviceCard';
import HeaderBar from '../Generic/HeaderBar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { defaultSensorProfile } from '../common/ModalComponents/DefaultSensorProfile';
import Pagination from '@material-ui/lab/Pagination';

// mobile components
import MobileDeviceCard from './MobileDeviceCard';
import { MobileTabsContainer } from '../Generic/Mobile/MobileTabs';

import MobileSearchBar from '../Generic/Mobile/MobileSearchBar';

import MobileBottomButton, {
  MobileBottomButtonSpacer,
} from '../Generic/Mobile/MobileBottomButton';
import {
  FullViewContainer,
  ListViewContainer,
} from '../Generic/Mobile/MobileContainers';

//SVG ICONS
import SensorIcon from '../../img/icons/SensorIcon';
import SensorImage from '../../img/icons/sensor.svg';

//Socket IO
import socketIOClient from 'socket.io-client';
import { socketURL } from '../helper/socketHelpers'
import LoadingSpinner from '../Generic/LoadingSpinner';
import { sensorProfileMap } from '../helper/profileHelpers';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mobileListContainer: {
    marginTop: 10,
  },
  mobileBottomButton: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: 45,
    borderRadius: '15px 15px 0px 0px',
  },
  mobileSpacer: {
    content: '',
    height: '65px',
    width: '100%',
  },
  impactFont: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

const AllDevices = props => {
  const log = window.log('AllDevices');

  const theme = useTheme();
  const classes = useStyles();

  const {
    snack,
    appState,
    setAppState,
    openUserModal,
    openAlertModal,
    setLogoutState,
    openSensorModal,
    openGatewayModal,
    openLocationModal,
    getProfileSetting,
    setOpenAddingModal,
    darkModeContainerColor,
  } = useContext(AppContext);

  const { advancedLux } = appState.userSettings;
  let sockets = [];

  const [state, setState] = useState([]);
  const [reload, setReload] = useState([]);
  const [gateways, setGateways] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filterColumns] = useState(['sensorName', 'sensorId']);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  /**
   * Mobile view variables
   */
  const isMobileSize = isWidthDown('xs', props.width);

  /**
   * End of mobile view variables
   */

  const getDevices = async switchedAccount => {
    if (
      !openLocationModal &&
      !openGatewayModal &&
      !openSensorModal &&
      !openAlertModal &&
      !openUserModal
    ) {
      setOpenBackdrop(true);
    }

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: appState.auth.token,
          },
        },
      );

      const json = await request.json();

      log('Looking at json ', json.data);
      if (json.success) {
        setOriginalData(json.data);

        let parsedProfiles = json.data.sensors.map(sensor => {
          return {
            ...sensor,
            sensorProfile: JSON.parse(sensor.sensorProfile).SensorProfile,
          };
        });
        for (let i = 0; i < parsedProfiles.length; i++) {
          //Convert from milli Gs to Gs
          parsedProfiles[i].lastSensorMessage.acceleration /= 1000;
        }
        setState(parsedProfiles);
        setGateways(json.data.sensorGateways);
        log('Looking at the state ', parsedProfiles);
      } else {
        if (switchedAccount) setState([]);
        try {
          json.errors.forEach(err => {
            snack(err.msg, 'error');
            if (err.type === 'token') setLogoutState(true);
          });
        } catch (err) {
          snack('Network Error', 'error');
        }
      }
    } catch (err) {
      snack(
        'Unable to grab information. Please refresh the page and try again.',
        'error',
      );
    }
    if (
      !openLocationModal &&
      !openGatewayModal &&
      !openSensorModal &&
      !openAlertModal &&
      !openUserModal
    ) {
      setOpenBackdrop(false);
    }

    setInitialPageLoad(false);
    setReload(!reload);
  };

  useEffect(() => {
    getDevices();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  //After DCS Admin switches account, fetch that account's data
  useEffect(() => {
    if (!appState.isSwitched) return;
    getDevices(true);
    setAppState({ ...appState, isSwitched: false });
  }, [appState.isSwitched]);

  useEffect(() => {
    if (state.length === 0) return;
    //Set up the live message traffic
    //For every gateway MAC create a socket
    log('Looking at the state ' + state);
    for (let i = 0; i < state.length; i++) {
      log(state[i]);
      log(state[i].sensorId);
      const socket = socketIOClient(socketURL(state[i].brand), {
        upgrade: false,
        transports: ['websocket'],
        query: {
          sensorMAC: state[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      log('The id is ' + state[i].sensorId);
      socket.on(state[i].sensorId.toLowerCase(), data => {
        log('Sensor data: ' + state[i].sensorId + ':' + data);
        log('There are ' + state.length + ' state objects');
        let newState = [];
        for (let j = 0; j < state.length; j++) {
          if (state[i].sensorId === state[j].sensorId) {
            let stateObj = state[i];
            stateObj.lastMessageTime = new Date().getTime() / 1000;
            //convert from milli g's to g's
            data.acceleration = data.acceleration / 1000;
            stateObj.lastSensorMessage = data;
            //This sets the last message time on the screen
            stateObj.lastMessageTime = new Date().getTime();
            newState.push(stateObj);
          } else {
            newState.push(state[j]);
          }
        }
        log('Looking at the new state ', newState);

        setState(prevState => {
          let updatedState = [];
          for (let i = 0; i < prevState.length; i++) {
            for (let j = 0; j < newState.length; j++) {
              if (prevState[i].sensorId === newState[j].sensorId) {
                log('Setting state for ' + prevState[i].sensorId);
                let stateObj = prevState[i];
                stateObj.lastSensorMessage.temperature =
                  newState[j].lastSensorMessage.temperature;
                stateObj.lastSensorMessage.humidity =
                  newState[j].lastSensorMessage.humidity;
                stateObj.lastSensorMessage.pressure =
                  newState[j].lastSensorMessage.pressure;
                stateObj.lastSensorMessage.acceleration =
                  newState[j].lastSensorMessage.acceleration;
                stateObj.lastSensorMessage.currentDoorState =
                  newState[j].lastSensorMessage.currentDoorState;
                stateObj.lastSensorMessage.currentPanicState =
                  newState[j].lastSensorMessage.currentPanicState;
                stateObj.lastSensorMessage.currentMotionState =
                  newState[j].lastSensorMessage.currentMotionState;
                stateObj.lastSensorMessage.rssi =
                  newState[j].lastSensorMessage.rssi;
                stateObj.lastSensorMessage.batteryVoltage =
                  newState[j].lastSensorMessage.batteryVoltage;
                stateObj.lastSensorMessage.tempPCB =
                  newState[j].lastSensorMessage.tempPCB || newState[j].lastSensorMessage.temp_pcb;
                //This controls the last message time shown on screen
                stateObj.lastMessageTime = newState[j].lastMessageTime;
                updatedState.push(stateObj);
              }
            }
          }
          return updatedState;
        });
      });
    }

    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log('Leaving page');
        sockets[i].disconnect();
      }
    };
  }, [gateways]);

  const convertTempForUserPref = value => {
    let unitType = ' °C';
    //Convert the Fahrenheit
    if (appState.userSettings.measurement === 'imperial') {
      value = value * (9 / 5) + 32;
      unitType = ' °F';
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const scanDevicesHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('scanDevices');
    }
  };

  const updateSensorProfile = device => {
    const {
      light,
      humidity,
      pressure,
      temperature,
      batteryVoltage,
      currentDoorState,
      currentPanicState,
      currentMotionState,
      eco2,
      VOC,
      IAQ,
      tempPCB,
      currentWaterState,
      temp_pcb
    } = device.lastSensorMessage;

    const sensorProfile = [
      {
        type: 'Temperature',
        value:
          temperature || temperature === 0
            ? convertToF(temperature, appState.userSettings.tempType, true)
            : '-',
      },
      {
        type: 'Battery',
        value:
          batteryVoltage || batteryVoltage === 0 ? `${batteryVoltage}v` : '-',
      },
      {
        type: 'Humidity',
        value: humidity || humidity === 0 ? humidity.toFixed(1) + ' %' : '-',
      },
      {
        type: 'Pressure',
        value:
          pressure || pressure === 0
            ? convertToPSI(pressure, appState.userSettings.pressType, true)
            : '-',
      },
      {
        type: 'Impact Detection',
        value:
          currentMotionState === 1 ? (
            <Typography component={'span'} className={classes.impactFont}>Detected</Typography>
          ) : (
            <Typography component={'span'} className={classes.impactFont}>Not Detected</Typography>
          ),
      },
      {
        type: 'Light Detection',
        value:
          light || light === 0
            ? advancedLux
              ? Number(light).toFixed(0) + ' lux'
              : calculateLuxDisplay(Number(light).toFixed(0))
            : '-'
      },
      {
        type: 'Multi-Function Button',
        value: currentPanicState ? 'Active' : 'Inactive'
      },
      {
        type: 'Door Open/Close',
        value: currentDoorState ? 'Open' : 'Closed'
      },
      {
        type: 'Proximity',
        value: '-'
      },
      {
        type: 'eco2',
        value: eco2 || (eco2 === 0) ? eco2.toString() : '-'
      },
      {
        type: 'voc',
        value: VOC || (VOC === 0) ? VOC.toString() : '-'
      },
      {
        type: 'iaq',
        value: IAQ || (IAQ === 0) ? IAQ.toString() : '-'
      },
      {
        type: 'temp_pcb',
        value:
        (tempPCB || tempPCB === 0) || (temp_pcb || temp_pcb === 0)
          ? convertToF(tempPCB || temp_pcb, appState.userSettings.tempType, true)
          : '-',
      },
      {
        type: 'water',
        value:
        currentWaterState === 1 ? (
          <Typography className={classes.impactFont}>Detected</Typography>
        ) : (
          <Typography className={classes.impactFont}>Not Detected</Typography>
        ),
      },
    ];

    const sensorsObj = _.keyBy(
      defaultSensorProfile(theme, sensorProfile, false, 28),
      o => {
        let key = _.camelCase(o.name);
        if (key === 'doorOpenClose') key = 'doorOpen';
        if (key === 'lightDetection') key = 'light';
        if (key === 'tempEnvironment') key = 'temp_environment';
        if (key === 'tempPcb') key = 'temp_pcb';
        if(key !== 'battery'){
          return key;
        }
      },
    );
    return sensorsObj;
  };

  const searchCallback = value => {
    let filteredArray = [];
    //Iterate over every sensor object
    for (var i = 0; i < originalData['sensors'].length; i++) {
      let match = false;
      //Iterate over every filter condition
      for (var j = 0; j < filterColumns.length; j++) {
        //Check for match
        if (
          originalData['sensors'][i][filterColumns[j]]
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          match = true;
        }
      }
      //If match add to filtered array
      if (match || value === '') {
        filteredArray.push(originalData['sensors'][i]);
      }
    }
    setState(prevState => {
      let newState = [];
      for (let i = 0; i < filteredArray.length; i++) {
        newState.push(filteredArray[i]);
      }
      log('search Looking at the new State', newState);
      return newState;
    });
    // setState(filteredArray);
  };

  const options = {
    onRowClick: selectedRow => {
      navigate(`/device/${selectedRow[0]}`);
    },
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    fixedHeader: false,
  };

  const columns = [
    {
      name: 'sensorId',
      label: 'Sensor ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sensorName',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sensorLocation',
      label: 'Location',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastSensorMessageTemperature',
      label: 'Temperature',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.temperature || valueSensor?.temperature === 0
            ? convertToF(
                valueSensor.temperature,
                appState.userSettings.tempType,
                true,
              )
            : '-';
        },
      },
    },
    {
      name: 'lastSensorMessageHumidity',
      label: 'Humidity',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.humidity || valueSensor?.humidity === 0
            ? valueSensor.humidity.toFixed(1) + '%'
            : '-';
        },
      },
    },
    {
      name: 'lastSensorMessagePressure',
      label: 'Pressure',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.pressure || valueSensor?.pressure === 0
            ? convertToPSI(
                valueSensor.pressure,
                appState.userSettings.pressType,
                true,
              )
            : '-';
        },
      },
    },
    {
      name: 'lastSensorMessageAcceleration',
      label: 'Impact Detection',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.currentMotionState === 1
            ? 'Detected'
            : 'Not Detected';
        },
      },
    },
    {
      name: 'lastSensorMessageLight',
      label: 'Light',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.light || valueSensor?.light === 0
            ? advancedLux
              ? Number(valueSensor.light).toFixed(0) + ' lux'
              : calculateLuxDisplay(Number(valueSensor.light).toFixed(0))
            : '-';
        },
      },
    },
    {
      name: 'lastSensorMessageMultiFunction',
      label: 'Multi-Function',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor
            ? valueSensor?.currentPanicState === 1
              ? 'Active'
              : 'Inactive'
            : '-';
        },
      },
    },
    {
      name: 'lastSensorMessageDoor',
      label: 'Door',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor
            ? valueSensor?.currentDoorState === 1
              ? 'Open'
              : 'Close'
            : '-';
        },
      },
    },
    // {
    //   name: 'lastSensorMessageProximity',
    //   label: 'Proximity',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRenderLite: (value, tableMeta, updateValue) => {
    //       const valueSensor = state[tableMeta].lastSensorMessage;
    //       return valueSensor?.rssi || valueSensor?.rssi === 0
    //         ? valueSensor.rssi.toFixed(1) + ' dBm'
    //         : '-';
    //     },
    //   },
    // },
    {
      name: 'lastSensorMessageBattery',
      label: 'Battery',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          const valueSensor = state[tableMeta].lastSensorMessage;
          return valueSensor?.batteryVoltage ||
            valueSensor?.batteryVoltage === 0
            ? valueSensor.batteryVoltage.toFixed(2) + 'V'
            : '-';
        },
      },
    },
  ];

  const [listView, setListView] = useState(
    getProfileSetting('pageView.sensors') || false,
  );

  const sortButtonEvent = () => {
    // NOTE: This may comeback in future ui designs
    // updateUserProfile('pageView.sensors', !listView);
    setListView(!listView);
  };

  useEffect(() => {
    setListView(getProfileSetting('pageView.sensors'));
  }, [appState?.pageView?.sensors]);

  // Pagination
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div>
      {isMobileSize ? (
        <FullViewContainer>
          {/* Generic Search  Bar */}
          <MobileTabsContainer>
            <MobileSearchBar searchCallback={searchCallback} />
          </MobileTabsContainer>
          <ListViewContainer>
            <Grid container spacing={0} className={classes.mobileListContainer}>
              {state &&
                state.map((device, index) => {
                  log('++++++', device.lastSensorMessage);
                  return (
                    <Grid
                      item
                      xs={12}
                      key={device.sensorId}
                      className={classes.sensorCardContainer}
                    >
                      <MobileDeviceCard
                        isTileLayout={true}
                        backgroundColor={
                          darkModeContainerColor()
                            ? index % 2 === 0
                              ? '#212121'
                              : '#424242'
                            : index % 2 === 0
                            ? '#FFF'
                            : '#F4F5F5'
                        }
                        showAvatar={false}
                        deviceData={device}
                        index={index}
                        reloadSocket={reload}
                        sensorCard={true}
                        showStatus={true}
                        lastMessageTime={device.lastMessageTime}
                        onClick={() => {
                          navigate(`/device/${device.sensorId}`);
                        }}
                        name={`${device.sensorName.toUpperCase()}`}
                        subtitle={`ID: ${device.sensorId}`}
                        subtitle2={`Location: ${device.sensorLocation}`}
                        subtitle3={
                          device.lastMessageTime !== null
                            ? `${
                                appState.userSettings?.timeFormat === '12hr'
                                  ? `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY hh:mm a')}`
                                  : `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY kk:mm')}`
                              }`
                            : 'Last Reported: No messages'
                        }
                        defaultAvatar={
                          device.sensorImageLocation ? false : true
                        }
                        avatar={
                          device.sensorImageLocation
                            ? device.sensorImageLocation
                            : SensorImage
                        }
                        isItemsDict={true}
                        showOnlyItems={sensorProfileMap(device.sensorProfile)}
                        batteryVolt={device.lastSensorMessage.batteryVoltage}
                        sensorProfile={device.sensorProfile}
                        trailingItems={updateSensorProfile(device)}
                      />
                    </Grid>
                  );
                })}
              <MobileBottomButtonSpacer />
            </Grid>
          </ListViewContainer>
          <MobileBottomButton onClick={openAddModal} />
        </FullViewContainer>
      ) : (
        <div>
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>

          {/* Generic Search  Bar */}
          <HeaderBar
            leadingIcon={
              <SensorIcon style={{ fontSize: 32 }} color='primary' />
            }
            // breadcrumbs={breadcrumbs}
            title='Sensors'
            buttonAddTitle='ADD SENSOR'
            buttonAddEvent={openAddModal}
            searchCallback={searchCallback}
            buttonAux={window.ReactNativeWebView}
            buttonAuxTitle={'SCAN DEVICES'}
            buttonAuxEvent={scanDevicesHandler}
            sortActive={listView}
            sortButton={true}
            sortButtonEvent={sortButtonEvent}
          />
          <Grid container spacing={2}>
            {state && listView ? (
              <Grid style={{ cursor: "pointer" }} xs={12} item>
                <MUIDataTable
                  title={'Sensors'}
                  data={state}
                  columns={columns}
                  options={options}
                />
              </Grid>
            ) : (
              state
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((device, index) => {
                  log('++++++', device.lastSensorMessage);

                  return (
                    <Grid
                      item
                      xs={12}
                      key={device.sensorId}
                      className={classes.sensorCardContainer}
                    >
                      <DeviceCard
                        deviceData={device}
                        index={index}
                        reloadSocket={reload}
                        sensorCard={true}
                        showStatus={true}
                        lastMessageTime={device.lastMessageTime}
                        onClick={() => {
                          navigate(`/device/${device.sensorId}`);
                        }}
                        name={`${device.sensorName.toUpperCase()}`}
                        subtitle={`ID: ${device.sensorId}`}
                        subtitle2={`Location: ${device.sensorLocation}`}
                        subtitle3={
                          device.lastMessageTime !== null
                            ? `${
                                appState.userSettings?.timeFormat === '12hr'
                                  ? `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY hh:mm a')}`
                                  : `Last Reported: ${moment(
                                      device.lastMessageTime,
                                    ).format('MM/DD/YYYY kk:mm')}`
                              }`
                            : 'Last Reported: No messages'
                        }
                        defaultAvatar={
                          device.sensorImageLocation ? false : true
                        }
                        avatar={
                          device.sensorImageLocation
                            ? device.sensorImageLocation
                            : SensorImage
                        }
                        isItemsDict={true}
                        showOnlyItems={sensorProfileMap(device.sensorProfile)}
                        batteryVolt={device.lastSensorMessage.batteryVoltage}
                        sensorProfile={device.sensorProfile}
                        trailingItems={updateSensorProfile(device)}
                      />
                    </Grid>
                  );
                })
            )}
            {!listView && (
              <Grid item xs={12} container justify='center'>
                <Pagination
                  variant='outlined'
                  count={Math.ceil(state.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  defaultPage={1}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default withWidth()(AllDevices);
