import React from "react";
import { SvgIcon } from "@material-ui/core";

const WaterSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        width="163.784"
        height="227.538"
        viewBox="0 0 170 227.538"
        {...props}
        >
  <g id="Group_1972" data-name="Group 1972" transform="translate(-194.674 -135.567)">
    <g id="Drop" transform="translate(213.033 135.567)">
      <path id="Path_654" data-name="Path 654" d="M252.071,198.152a38.066,38.066,0,0,1-7.324,0,35.022,35.022,0,0,1-28.539-20.1,37.726,37.726,0,0,1-2.032-27.29l-4.325,6.464a37.74,37.74,0,0,0-2.549,37.066,35.022,35.022,0,0,0,28.539,20.1,38.065,38.065,0,0,0,7.324,0,35.014,35.014,0,0,0,28.539-20.1,37.8,37.8,0,0,0,2.544-6.9A34.994,34.994,0,0,1,252.071,198.152Z" transform="translate(-176.229 -72.988)" fill={filledcolor}/>
      <path id="Path_655" data-name="Path 655" d="M261.824,302.869a59.57,59.57,0,0,1-6.485,0A62.008,62.008,0,0,1,204.8,267.277a66.811,66.811,0,0,1,4.509-65.635l39.543-59.079a15.824,15.824,0,0,1,26.256,0l39.543,59.079a66.824,66.824,0,0,1,4.5,65.635,62,62,0,0,1-50.532,35.592A57.98,57.98,0,0,1,261.824,302.869Zm0-151.846L222.281,210.1a50.139,50.139,0,0,0-3.322,50.061,45.709,45.709,0,0,0,37.644,26.65,53.961,53.961,0,0,0,10.124,0,45.716,45.716,0,0,0,37.8-26.65,50.14,50.14,0,0,0-3.163-50.061Z" transform="translate(-198.261 -135.567)" fill={outlinecolor}/>
    </g>
    <path id="Path_656" data-name="Path 656" d="M356.957,178.387a5.112,5.112,0,0,0-7.237,0,16.624,16.624,0,0,1-23.472,0,24.911,24.911,0,0,0-8.21-5.538c-.225-.1-.43-.251-.66-.343A26.319,26.319,0,0,0,313,171.211c-.2-.046-.394-.138-.594-.174a26.493,26.493,0,0,0-3.184-.307c-.65-.046-1.295-.184-1.95-.184s-1.315.138-1.976.189a26,26,0,0,0-3.153.3c-.322.061-.63.2-.947.276a27.3,27.3,0,0,0-4.028,1.193c-.491.2-.942.517-1.423.747a25.742,25.742,0,0,0-3.24,1.7,26.816,26.816,0,0,0-4.207,3.429,16.362,16.362,0,0,1-2.6,2.119,16,16,0,0,1-2.232,1.172c-.22.1-.425.251-.655.343a16.273,16.273,0,0,1-2.426.722c-.22.051-.43.148-.65.189a16.8,16.8,0,0,1-1.9.184c-.425.031-.844.118-1.274.118s-.844-.087-1.269-.118a17,17,0,0,1-1.9-.184c-.22-.041-.425-.138-.645-.189a16.337,16.337,0,0,1-2.431-.722c-.225-.092-.43-.241-.655-.343a15.944,15.944,0,0,1-2.226-1.172,16.4,16.4,0,0,1-2.605-2.119,24.912,24.912,0,0,0-8.209-5.538c-.225-.1-.43-.251-.66-.343a26.321,26.321,0,0,0-4.376-1.295c-.2-.046-.394-.138-.594-.174a26.493,26.493,0,0,0-3.183-.307c-.65-.046-1.295-.184-1.95-.184s-1.315.138-1.976.189a26,26,0,0,0-3.153.3c-.322.061-.63.2-.947.276a27.309,27.309,0,0,0-4.028,1.193c-.491.2-.942.517-1.423.747a25.742,25.742,0,0,0-3.24,1.7,26.816,26.816,0,0,0-4.207,3.429,16.624,16.624,0,0,1-23.472,0,5.117,5.117,0,0,0-7.237,7.237,26.875,26.875,0,0,0,37.946,0,16.619,16.619,0,0,1,23.472,0,26.875,26.875,0,0,0,37.946,0,16.619,16.619,0,0,1,23.472,0,26.875,26.875,0,0,0,37.946,0A5.112,5.112,0,0,0,356.957,178.387Z" transform="translate(0 144.049)" fill={outlinecolor}/>
    <path id="Path_657" data-name="Path 657" d="M356.957,183.387a5.112,5.112,0,0,0-7.237,0,16.624,16.624,0,0,1-23.472,0,24.912,24.912,0,0,0-8.21-5.538c-.225-.1-.43-.251-.66-.343A26.319,26.319,0,0,0,313,176.211c-.2-.046-.394-.138-.594-.174a26.493,26.493,0,0,0-3.184-.307c-.65-.046-1.295-.184-1.95-.184s-1.315.138-1.976.189a26,26,0,0,0-3.153.3c-.322.061-.63.2-.947.276a27.3,27.3,0,0,0-4.028,1.193c-.491.2-.942.517-1.423.747a25.742,25.742,0,0,0-3.24,1.7,26.819,26.819,0,0,0-4.207,3.429,16.359,16.359,0,0,1-2.6,2.119,16.008,16.008,0,0,1-2.232,1.172c-.22.1-.425.251-.655.343a16.273,16.273,0,0,1-2.426.722c-.22.051-.43.148-.65.189a16.8,16.8,0,0,1-1.9.184c-.425.031-.844.118-1.274.118s-.844-.087-1.269-.118a17,17,0,0,1-1.9-.184c-.22-.041-.425-.138-.645-.189a16.337,16.337,0,0,1-2.431-.722c-.225-.092-.43-.241-.655-.343a15.95,15.95,0,0,1-2.226-1.172,16.4,16.4,0,0,1-2.605-2.119,24.913,24.913,0,0,0-8.209-5.538c-.225-.1-.43-.251-.66-.343a26.321,26.321,0,0,0-4.376-1.295c-.2-.046-.394-.138-.594-.174a26.493,26.493,0,0,0-3.183-.307c-.65-.046-1.295-.184-1.95-.184s-1.315.138-1.976.189a26,26,0,0,0-3.153.3c-.322.061-.63.2-.947.276a27.309,27.309,0,0,0-4.028,1.193c-.491.2-.942.517-1.423.747a25.742,25.742,0,0,0-3.24,1.7,26.819,26.819,0,0,0-4.207,3.429,16.624,16.624,0,0,1-23.472,0,5.117,5.117,0,0,0-7.237,7.237,26.875,26.875,0,0,0,37.946,0,16.619,16.619,0,0,1,23.472,0,26.875,26.875,0,0,0,37.946,0,16.619,16.619,0,0,1,23.472,0,26.875,26.875,0,0,0,37.946,0A5.112,5.112,0,0,0,356.957,183.387Z" transform="translate(0 164.64)" fill={outlinecolor}/>
  </g>
    </SvgIcon>
  );
};

export default WaterSVG;
