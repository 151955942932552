import React from "react";
import { SvgIcon } from "@material-ui/core";

const Place = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="21.978" height="21.977" viewBox="0 0 21.978 21.977"
      {...props}
    >
        <g transform="translate(-41.083 80.825)">
            <path d="M55.13-51.112a2.967,2.967,0,0,0-2.967,2.967c0,2.348,2.967,5.822,2.967,5.822S58.1-45.809,58.1-48.145A2.967,2.967,0,0,0,55.13-51.112Zm0,4.253a1.463,1.463,0,0,1-1.463-1.464,1.463,1.463,0,0,1,1.463-1.463,1.464,1.464,0,0,1,1.464,1.463A1.464,1.464,0,0,1,55.13-46.859Z" transform="translate(-7.2 -19.309)"/>
            <path d="M60.788-80.825H43.355a2.275,2.275,0,0,0-2.272,2.272V-61.12a2.275,2.275,0,0,0,2.272,2.272H60.788a2.275,2.275,0,0,0,2.272-2.272V-78.553A2.274,2.274,0,0,0,60.788-80.825ZM49.213-74.907l2.429-2.429,9.668,9.668v4.859Zm12.1-3.646v8.408l-8.43-8.429.5-.5h7.408A.522.522,0,0,1,61.31-78.553Zm-17.955-.521H50.9L45.626-73.8l-2.792-2.792v-1.965A.522.522,0,0,1,43.355-79.074Zm1.033,6.516L42.834-71v-3.108ZM43.355-60.6a.522.522,0,0,1-.521-.521v-7.409l5.141-5.141L61-60.644a.514.514,0,0,1-.212.046Z"/>
        </g>
    </SvgIcon>
  );
};

export default Place;
