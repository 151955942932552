import React, { useEffect, useState, useContext } from 'react';
import grey from '@material-ui/core/colors/grey';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NO_AVERAGE, TIME_IN_MILLI, SNAPSHOT_HIGH_LOW_WORD, ENTITY_KEY_MAP } from '../../constants';
import { getMinMaxValuesPerDiem } from '../common/helpers/analyticsHelpers'
import { AppContext } from './../../AppContext';
import moment from 'moment';
import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import _ from 'lodash'

import {
  MobileCustomTab,
  MobileCustomTabs,
  MobileSnapshotTab,
  MobileTabsContainer,
  MobileTabPanelContainer
} from "../Generic/Mobile/MobileTabs"

import { DeviceBarChart } from './DeviceSnapshotComponents/DeviceBarChart';
import { DeviceLineGraph } from './DeviceSnapshotComponents/DeviceLineGraph';
import { SnapShotGraphButtons } from './SnapShotGraphButtons';
import { calculateLuxDisplay } from '../common/helpers/calculateLuxDisplay.js';


export default function DeviceSnapshotDialog(props) {
  const {
    open, // boolean to open the dialog
    onClose, // on close modal method
    entity, // sensor device entity, holds the values of the sensor
    measurementUnit = '', // unit of measurement
    chartData = [], // chart data for both line and bar
    markers = [], // chart markers for line chart
    reportData, // weeks worth of data points
    currentValue,
    getReport,
    baseData,
    loading,
    active,
    lowestValue = '-', // lowest value of the snapshot and the chart
    highestValue = '-', // highest value of the snapshot and the chart
    messageKey,
    doorData,
    panicData,
    motionData
  } = props;
  const {
    appState
  } = useContext(AppContext);
  const theme = useTheme();
  const [lowestValueByFrame, setLowestValueByFrame] = useState(lowestValue)
  const [highestValueByFrame, setHighestValueByFrame] = useState(highestValue)

  const [impacts, setImpacts] = useState(0)

  const setHighLow = (data) => {
    if(data[0]){
      let values = getMinMaxValuesPerDiem('y', data)
      if (messageKey === 'light_detection' && !appState.userSettings.advancedLux) {
        setLowestValueByFrame(calculateLuxDisplay(values.min));
        setHighestValueByFrame(calculateLuxDisplay(values.max));
      } else {
        setLowestValueByFrame(values.min)
        setHighestValueByFrame(values.max)
      }
    }
  }

  useEffect(() => {
    const lastTime =
      reportData?.todayReportData[reportData?.todayReportData.length - 1]
        ?.dateReceived;
    if (reportData && lastTime) {
      let minDiff = moment().diff(lastTime, 'minutes');
      if (minDiff >= 1) {
        getReport();
      }
    }
    const currTime = moment();
    const diff = moment.duration(currTime.diff(active)).as('seconds');
    let timeout = 300;
    if (diff <= timeout) {
      setHasRecent(true);
    } else {
      setHasRecent(false);
    }
  }, [currentValue]);

  /**
   * Watches changes to reportData
   * Gets last message from device and determines which snapshot tabs to unlock
   */
  useEffect(() => {
    const currDate = moment();
    const lastRecord =
      reportData?.reportData[reportData?.reportData.length - 1]?.dateReceived;
    const diffFromLastMsg = currDate.diff(lastRecord);
    const milliToday = currDate.diff(moment().startOf('day'))

    if (Math.sign(diffFromLastMsg) === 1) {
      //checks to ensure that the diff is a postive number
      if (diffFromLastMsg < TIME_IN_MILLI.sevenDays) {
        setHasSevenDays(true);
      }
      if(diffFromLastMsg < milliToday){
        setHasOneDay(true);
      }
      if (diffFromLastMsg < TIME_IN_MILLI.oneHour) {
        setHasOneHour(true);
      }
    }
  }, [reportData]);

  const classes = useStyles();

  /* values used for the buttons of the chart
    are hour, day, week
    to determine which one is selected
  */
  const [chartTimeSelection, setChartTimeSelection] = useState('hour');

  // hooks for controlling the the graph views based on data
  const [hasSevenDays, setHasSevenDays] = useState(false);
  const [hasOneDay, setHasOneDay] = useState(false);
  const [hasOneHour, setHasOneHour] = useState(false);
  const [hasRecent, setHasRecent] = useState(false);

  const [realEvent, setRealEvent] = useState(false)

  //sets a default selection based on hook value for available data
  useEffect(()=>{
    if(hasOneHour && !realEvent){
      setChartTimeSelection('hour')
      return
    } else if(hasOneDay && !realEvent) {
      setChartTimeSelection('day')
      return
    } else if(hasSevenDays && !realEvent) {
      setChartTimeSelection('week')
      return
    }
  })

  const handleTimeButton = (selection) => {
    setChartTimeSelection(selection)
    setRealEvent(true)
  }

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fullWidth = false;
  const maxWidth = 'md';
  const singleValues = ['panic_button', 'motion_detection'];
  const isSingleValue = singleValues.includes(entity.name);
  const isBarChart =
    singleValues.includes(entity.name) || entity.name === 'door_open_close';

  let lowestValueTitle = '';
  let currentValueTitle = '';
  let highestValueTitle = '';

  if (entity.name === 'motion_detection') {
    currentValueTitle = SNAPSHOT_HIGH_LOW_WORD[chartTimeSelection] + ' impacts';
  } else if (entity.name === 'panic_button') {
    currentValueTitle = SNAPSHOT_HIGH_LOW_WORD[chartTimeSelection] + ' presses';
  } else if (entity.name === 'door_open_close') {
    lowestValueTitle = 'closed';
    currentValueTitle = 'Current status';
    highestValueTitle = 'open';
  } else {
    lowestValueTitle = SNAPSHOT_HIGH_LOW_WORD[chartTimeSelection] + ' low';
    currentValueTitle = 'current reading';
    highestValueTitle = SNAPSHOT_HIGH_LOW_WORD[chartTimeSelection] + ' high';
  }

  const handleClose = () => {
    onClose();
  };

  const getDate = date => {
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let minutes = date.getMinutes();
    const hour = date.getHours();
    let finalDate = '';
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (month < 10) {
      finalDate = `${year}-0${month}-${day} ${hour}:${minutes}`;
    } else {
      finalDate = `${year}-${month}-${day} ${hour}:${minutes}`;
    }
    return finalDate;
  };

  let chartDataTest = [
    {
      date: getDate(new Date()),
      activations: 197,
      deactivations: 143,
    },
  ];

  let barGraphKeysTest = ['deactivations', 'activations'];
  
  if (messageKey === 'door_open_close' && doorData) {
    const auxKey = 'currentDoorState';

    const isDate = (date, comparedDate) => {
      return (
        date.getDate() === comparedDate.getDate() &&
        date.getMonth() === comparedDate.getMonth() &&
        date.getFullYear() === comparedDate.getFullYear()
      );
    };

    const prepareWeekData = () => {
      const endDate = new Date();
      const value = 86400; // Seconds in a day
      const chartData = [];

      let initialDate = new Date(endDate - 6 * value * 1000);
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);
      let totalActivations = 0;
      let totalDeactivations = 0;

      for (let i = 0; i < 7; i++) {
        let activations = 0;
        let deactivations = 0;
        for (let j = 0; j < doorData.length; j++) {
          if (
            isDate(new Date(doorData[j].dateReceived), initialDate)
          ) {
            if (doorData[j][auxKey] === 1) {
              activations += 1;
            } else {
              deactivations += 1;
            }
          }
        }
        totalActivations += activations;
        totalDeactivations += deactivations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
          deactivations: deactivations,
        });

        initialDate = new Date(initialDate.getTime() + value * 1000);
      }
      if(lowestValueByFrame !== totalDeactivations){
        setLowestValueByFrame(totalDeactivations);
      }
      if(highestValueByFrame !== totalActivations ){
        setHighestValueByFrame(totalActivations);
      }
      return chartData;
    };
    const preparedDayData = () => {
      const endDate = new Date();
      const value = 3600; // Seconds on a hour
      let initialDate = new Date();
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);
      let totalActivations = 0;
      let totalDeactivations = 0;
      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        let deactivations = 0;
        for (let j = 0; j < doorData.length; j++) {
          const comparedDate = new Date(
            doorData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (doorData[j][auxKey] === 1) {
              activations += 1;
            } else {
              deactivations += 1;
            }
          }
        }
        totalActivations += activations;
        totalDeactivations += deactivations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
          deactivations: deactivations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }

      if(lowestValueByFrame !== totalDeactivations){
        setLowestValueByFrame(totalDeactivations);
      }
      if(highestValueByFrame !== totalActivations ){
        setHighestValueByFrame(totalActivations);
      }

      return chartData;
    };

    const preparedHourData = () => {
      const endDate = new Date();
      const value = 600; // Seconds on a day
      const chartData = [];
      let initialDate = new Date(endDate - 6000 * 1000);
      let totalActivations = 0;
      let totalDeactivations = 0;

      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        let deactivations = 0;
        for (let j = 0; j < doorData.length; j++) {
          const comparedDate = new Date(
            doorData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (doorData[j][auxKey] === 1) {
              activations += 1;
            } else {
              deactivations += 1;
            }
          }
        }

        totalActivations += activations;
        totalDeactivations += deactivations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
          deactivations: deactivations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }

      if(lowestValueByFrame !== totalDeactivations){
        setLowestValueByFrame(totalDeactivations);
      }
      if(highestValueByFrame !== totalActivations ){
        setHighestValueByFrame(totalActivations);
      }

      return chartData;
    };
    if (chartTimeSelection === 'hour') {
      chartDataTest = preparedHourData();
    } else if (chartTimeSelection == 'day') {
      chartDataTest = preparedDayData();
    } else {
      chartDataTest = prepareWeekData();
    }
  } else if (messageKey === 'panic_button' && panicData) {
    const auxKey = 'currentPanicState';

    const isDate = (date, comparedDate) => {
      return (
        date.getDate() === comparedDate.getDate() &&
        date.getMonth() === comparedDate.getMonth() &&
        date.getFullYear() === comparedDate.getFullYear()
      );
    };
    //TODO: Refactor these out and make them modular
    const prepareWeekData = () => {
      const endDate = new Date();
      const value = 86400; // Seconds on a day
      const chartData = [];

      let initialDate = new Date(endDate - 6 * value * 1000);
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);

      for (let i = 0; i < 7; i++) {
        let activations = 0;
        for (let j = 0; j < panicData.length; j++) {
          if (
            isDate(new Date(panicData[j].dateReceived), initialDate)
          ) {
            if (panicData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }

        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });

        initialDate = new Date(initialDate.getTime() + value * 1000);
      }

      return chartData;
    };
    //TODO: Refactor these out and make them modular
    const preparedDayData = () => {
      const endDate = new Date();
      const value = 3600; // Seconds on a hour
      let initialDate = new Date();
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);

      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        for (let j = 0; j < panicData.length; j++) {
          const comparedDate = new Date(
            panicData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (panicData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }

        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }

      return chartData;
    };
    //TODO: Refactor these out and make them modular
    const preparedHourData = () => {
      const endDate = new Date();
      const value = 600; // Seconds on a day
      const chartData = [];
      let initialDate = new Date(endDate - 6000 * 1000);

      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        for (let j = 0; j < panicData.length; j++) {
          const comparedDate = new Date(
            panicData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (panicData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }

        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }

      return chartData;
    };
    if (chartTimeSelection === 'hour') {
      chartDataTest = preparedHourData();
    } else if (chartTimeSelection == 'day') {
      chartDataTest = preparedDayData();
    } else {
      chartDataTest = prepareWeekData();
    }
    barGraphKeysTest = ['activations'];
  }else if (messageKey === 'motion_detection' && motionData) {
    const auxKey = 'motionAlert';

    const isDate = (date, comparedDate) => {
      return (
        date.getDate() === comparedDate.getDate() &&
        date.getMonth() === comparedDate.getMonth() &&
        date.getFullYear() === comparedDate.getFullYear()
      );
    };
    //TODO: Refactor these out and make them modular
    const prepareWeekData = () => {
      const endDate = new Date();
      const value = 86400; // Seconds on a day
      const chartData = [];

      let initialDate = new Date(endDate - 6 * value * 1000);
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);
      let totalActivations = 0;
      for (let i = 0; i < 7; i++) {
        let activations = 0;
        for (let j = 0; j < motionData.length; j++) {
          if (
            isDate(new Date(motionData[j].dateReceived), initialDate)
          ) {
            if (motionData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }
        totalActivations += activations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });

        initialDate = new Date(initialDate.getTime() + value * 1000);
      }
      if(impacts !== totalActivations){
        setImpacts(totalActivations)
      }
      return chartData;
    };
    //TODO: Refactor these out and make them modular
    const preparedDayData = () => {
      const endDate = new Date();
      const value = 3600; // Seconds on a hour
      let initialDate = new Date();
      initialDate.setHours(0);
      initialDate.setMinutes(0);
      initialDate.setSeconds(0);
      let totalActivations = 0;
      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        for (let j = 0; j < motionData.length; j++) {
          const comparedDate = new Date(
            motionData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (motionData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }
        totalActivations += activations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }
      if(impacts !== totalActivations){
        setImpacts(totalActivations)
      }
      return chartData;
    };
    //TODO: Refactor these out and make them modular
    const preparedHourData = () => {
      const endDate = new Date();
      const value = 600; // Seconds on a day
      const chartData = [];
      let initialDate = new Date(endDate - 6000 * 1000);
      let totalActivations = 0;
      while (initialDate.getTime() < endDate.getTime()) {
        let activations = 0;
        for (let j = 0; j < motionData.length; j++) {
          const comparedDate = new Date(
            motionData[j].dateReceived,
          );
          if (
            initialDate.getTime() <= comparedDate.getTime() &&
            comparedDate.getTime() < initialDate.getTime() + value * 1000
          ) {
            if (motionData[j][auxKey] === 1) {
              activations += 1;
            }
          }
        }

        totalActivations += activations;
        chartData.push({
          date: getDate(initialDate.getTime()),
          activations: activations,
        });
        initialDate = new Date(initialDate.getTime() + value * 1000);
      }
      if(impacts !== totalActivations){
        setImpacts(totalActivations)
      }
      return chartData;
    };
    if (chartTimeSelection === 'hour') {
      chartDataTest = preparedHourData();
    } else if (chartTimeSelection == 'day') {
      chartDataTest = preparedDayData();
    } else {
      chartDataTest = prepareWeekData();
    }
    barGraphKeysTest = ['activations'];
  }

  //This needs to be infile to have access to the states
  const SNAPSHOT_BUTTONS = {
    hour: { msg: 'last hour', state: hasOneHour },
    day: { msg: '1 day', state: hasOneDay },
    week: { msg: '7 day', state: hasSevenDays },
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.topContainer}>
        <MobileTabsContainer className={classes.titleContainer}>
          <MobileCustomTabs className={classes.title}
          centered
          indicatorColor="primary"
          textColor="primary"
          >
            <MobileSnapshotTab
              label={`${entity.title} snapshot`}
            />
          </MobileCustomTabs>
        </MobileTabsContainer>  
      </div>
      <DialogContent style={{ padding: 0 }}>
        <Grid container>
          <Grid
            container
            item
            justify='space-around'
            display='flex'
            className={classes.valuesRow}
            xs={12}
          >
            {!isSingleValue && (
              <Grid item>
                <Box className={classes.valueContainer}>
                  {`${lowestValueByFrame}${measurementUnit}`}
                </Box>
                <Box className={classes.valueSubtitles}>{lowestValueTitle}</Box>
              </Grid>
            )}
            {
              <Grid item>
                <Box className={classes.valueContainer}>
                  {messageKey === 'motion_detection' ? `${impacts}${measurementUnit}` : `${currentValue}${measurementUnit}`}
                </Box>
                <Box className={classes.valueSubtitles}>
                  {hasRecent ? currentValueTitle : 'Last Reading'}
                </Box>
              </Grid>
            }
            {!isSingleValue && (
              <Grid item>
                <Box
                  className={
                    messageKey !== 'door_open_close'
                      ? classes.valueContainer
                      : classes.valueContainerGreen
                  }
                >
                  {`${highestValueByFrame}${measurementUnit}`}
                </Box>
                <Box className={classes.valueSubtitles}>
                  {highestValueTitle}
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                height: '350px',
              }}
            >
              {!loading ? (
                reportData?.reportData.length !== 0 ?
                  isBarChart ? (
                    <DeviceBarChart
                      chartData={chartDataTest}
                      todayreportData={reportData}
                      barGraphKeys={barGraphKeysTest}
                      messageKey={messageKey}
                      chartTimeSelection={chartTimeSelection}
                      fullScreen={fullScreen}
                    />
                  ) : (
                    <DeviceLineGraph
                      entity={entity}
                      lowestValue={lowestValue}
                      markers={markers}
                      chartTimeSelection={chartTimeSelection}
                      reportData={baseData || reportData}
                      messageKey={ENTITY_KEY_MAP[messageKey]}
                      handleHighLow={setHighLow}
                      fullScreen={fullScreen}
                    />
                  ) :
                  <Grid
                  container
                  item
                  justify='center'
                  display='flex'
                  style={{
                    alignItems: 'center',
                    margin: 'auto',
                    height: '100%',
                  }}
                >
                  No Recent Data Found in the Last 7 Days
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  justify='center'
                  display='flex'
                  style={{
                    alignItems: 'center',
                    margin: 'auto',
                    height: '100%',
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            justify='center'
            display='flex'
            style={{
              paddingTop: '30px',
              paddingBottom: '30px',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <Grid
              container
              item
              xs={12}
              md={6}
              justify='space-around'
              display='flex'
            >
              {Object.keys(SNAPSHOT_BUTTONS).map((key, i) => (
                <SnapShotGraphButtons
                  loading={loading}
                  selection={key}
                  setSelection={handleTimeButton}
                  state={SNAPSHOT_BUTTONS[key].state}
                  description={SNAPSHOT_BUTTONS[key].msg}
                  className={classes.optionsButtons}
                  currState={chartTimeSelection}
                  sevenDays={hasSevenDays}
                  key={i}
                ></SnapShotGraphButtons>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify='center'
            style={{
              paddingBottom: '20px',
              borderBottom: `1px solid ${grey[300]}`,
            }}
          >
            {NO_AVERAGE[entity.name]
              ? ''
              : 'Snapshot data graphed using averages.'}
          </Grid>
          { !fullScreen && (<Grid
            item
            container
            xs={12}
            justify='center'
            className={classes.bottomSection}
          >
            <Button
              variant='outlined'
              size='large'
              color='primary'
              onClick={handleClose}
              className={classes.closeButton}
            >
              close
            </Button>
          </Grid>)}
          
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
// Get the height of the height of the window
const height = document.documentElement.clientHeight

const useStyles = makeStyles(theme => ({
  optionsButtons: {
    width: 100,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      fontSize: 10
    },
  },
  titleContainer: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px solid ${grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      borderBottomRightRadius: '15px',
      borderBottomLeftRadius: '15px',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.type === 'light' ? grey[600] : '#FFF',
  },
  valueContainer: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 600,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 4),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 12,
      margin: '5px',
      padding: theme.spacing(1, 0),
    },
  },
  valueContainerGreen: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 600,
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
    padding: theme.spacing(1, 4),
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 12,
      margin: '5px',
      padding: theme.spacing(1, 0),
    },
  },
  valueSubtitles: {
    textTransform: 'uppercase',
    color: theme.palette.type === 'light' ? grey[600] : '#FFF',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  topContainer: {
    backgroundColor: theme.palette.type === 'light' ? grey[100] : '',
    [theme.breakpoints.down('sm')]: {
      paddingTop: height > 600 ? '7vh' : '10vh' 
    }
  },
  valuesRow: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.type === 'light' ? grey[100] : '',
  },
  bottomSection: {
    padding: theme.spacing(2),
  },
  closeButton: {
    padding: theme.spacing(1, 9),
  },
}));
