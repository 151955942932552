let brandUrl = {
    'MiSensor': process.env.REACT_APP_SOCKET_URL, //sensor
    'DCS': process.env.REACT_APP_SOCKET_URL, //gateway
    'tabs': process.env.REACT_APP_SOCKET_URL_BROWAN, //sensor
    'Browan': process.env.REACT_APP_SOCKET_URL_BROWAN //gateway
}

const socketURL = (brand) =>{
    return brandUrl[brand]
}

module.exports = {
    socketURL
}