import _ from 'lodash';
import { convertToPSI } from '../../../conversions';

/**
 * combines range settings with sensor profile
 * @param {[Object]} sensorProfile
 * @param {Object} baselineSensorSel
 * @param {string} measurement
 * @returns {Object}
 */
export function rangeSettings(
  sensorProfile,
  baselineSensorSel = undefined,
  tempType,
  pressType,
  advancedLux = false,
) {
  let tempUnit = `°${tempType}`;
  let pressUnit = pressType;

  /**
   * determines what should be passed into the value keys in setRangeSettingsState
   * @param {integer || array} defaultValue
   */
  const determineRangeValues = (sensorType, defaultValue) => {
    let determined = defaultValue;
    //TODO:STUB
    // baselineSensorSel.hawrdwareProfile = [{"type": "temp_pcb", "range": {"max": 95, "min": -32}, "measuringUnits": "c"}, {"type": "humidity", "range": {"max": 100, "min": 0}, "measuringUnits": "percent"}, {"type": "eCO2", "range": {"max": "", "min": ""}, "measuringUnits": "ppm"}, {"type": "VOC", "range": {"max": 500, "min": 0}, "measuringUnits": "ppm"}, {"type": "IAQ", "range": {"goodHi": 100, "goodLo": 51, "excellentHi": 50, "excellentLo": 0, "heavilyPollutedHi": 250, "heavilyPollutedLo": 201, "lightlyPollutedHi": 150, "lightlyPollutedLo": 101, "severelyPollutedHi": 350, "severelyPollutedLo": 251, "extremelyPollutedLo": 351, "moderatelyPollutedHi": 200, "moderatelyPollutedLo": 151}, "measuringUnits": "iaq"}, {"type": "temp_environment", "range": {"max": 95, "min": -32}, "measuringUnits": "c"}]
    //END STUB
    if (_.findKey(baselineSensorSel, sensorType)) {
      // if not a number then equals array (or range type)
      if (typeof defaultValue === 'number') {
        determined = baselineSensorSel.lastSensorMessage[sensorType];
      } else {
        determined = [
          0,
          (determined = baselineSensorSel.lastSensorMessage[sensorType]),
        ];
      }
    }
    return determined;
  };

  const rangeSettingsObj = {
    temp_environment: {
      rangeType: 'none',
      rangeValues: determineRangeValues('temp_environment', 50),
      minimum: -40,
      maximum: tempType === 'F' ? 185 : 85,
      defaultRange: () => determineRangeValues('temp_environment', [20, 50]),
      defaultValue: () => determineRangeValues('temp_environment', 50),
      unit: tempUnit,
    },
    humidity: {
      rangeType: 'none',
      rangeValues: determineRangeValues('humidity', 50),
      minimum: 0,
      maximum: 100,
      defaultRange: () => determineRangeValues('humidity', [0, 50]),
      defaultValue: () => determineRangeValues('humidity', 50),
      unit: '%',
    },
    pressure: {
      rangeType: 'none',
      rangeValues: determineRangeValues('pressure', 400),
      minimum: 0,
      maximum: pressType === 'PSI' ? convertToPSI(2000, pressType) : 2000,
      defaultRange: () => determineRangeValues('pressure', [200, 500]),
      defaultValue: () => determineRangeValues('pressure', 500),
      unit: pressUnit,
    },
    light_detection: {
      rangeType: 'none',
      rangeValues: determineRangeValues(
        'light_detection',
        advancedLux ? 775 : 1,
      ),
      minimum: 0,
      maximum: advancedLux ? 64000 : 3,
      defaultRange: () =>
        determineRangeValues('light_detection', [0, advancedLux ? 64000 : 3]),
      defaultValue: () =>
        determineRangeValues('light_detection', advancedLux ? 775 : 1),
      unit: 'lux',
      marks: advancedLux
        ? null
        : [
            {
              value: 0,
              label: 'Off',
            },
            {
              value: 1,
              label: 'Low',
            },
            {
              value: 2,
              label: 'Med',
            },
            {
              value: 3,
              label: 'High',
            },
          ],
    },
    motion_detection: {
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_X: {
      extraDescription: 'for the X axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_Y: {
      extraDescription: 'for the Y axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    motion_detection_advanced_Z: {
      extraDescription: 'for the Z axis',
      rangeType: 'none',
      rangeValues: determineRangeValues('motionDetection', 25),
      minimum: -50,
      maximum: 50,
      defaultRange: () => determineRangeValues('motionDetection', [0, 25]),
      defaultValue: () => determineRangeValues('motionDetection', 25),
      unit: 'g',
    },
    temp_pcb: {
      rangeType: 'none',
      rangeValues: determineRangeValues('temp_pcb', 50),
      minimum: -40,
      maximum: tempType === 'F' ? 185 : 85,
      defaultRange: () => determineRangeValues('temp_pcb', [20, 50]),
      defaultValue: () => determineRangeValues('temp_pcb', 50),
      unit: tempUnit,
    },
    voc: {
      rangeType: 'none',
      rangeValues: determineRangeValues('ppm', 50),
      minimum: 0,
      maximum: 1000,
      defaultRange: () => determineRangeValues('ppm', [20, 50]),
      defaultValue: () => determineRangeValues('ppm', 50),
      unit: ' ppm',
    },
    iaq: {
      rangeType: 'none',
      rangeValues: determineRangeValues('ppm', 50),
      minimum: 0,
      maximum: 1000,
      defaultRange: () => determineRangeValues('ppm', [50, 100]),
      defaultValue: () => determineRangeValues('ppm', 100),
      unit: ' ppm',
    },
    eco2: {
      rangeType: 'none',
      rangeValues: determineRangeValues('ppm', 50),
      minimum: 0,
      maximum: 1000,
      defaultRange: () => determineRangeValues('ppm', [20, 50]),
      defaultValue: () => determineRangeValues('ppm', 50),
      unit: ' ppm',
    }
  };

  for (const setting in rangeSettingsObj) {
    const profile = _.find(sensorProfile, { name: setting });
    if (profile) {
      rangeSettingsObj[setting] = { ...rangeSettingsObj[setting], ...profile };
    }
  }

  return rangeSettingsObj;
}
