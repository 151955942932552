import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader'

//MUI
import { Grid, makeStyles } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    sensorSelectionButton: {
      width: "40%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: "1rem",
      },
    },
    listItems: {
        borderRadius: '5px',
        width: '100%',
        height: '120px'
      },
    list:{
        overflow: 'scroll',
    },
    sensorImage: {
        width: '25%'
    },
    deviceName : {
        fontWeight: 700
    },
    cardDivider: {
        height: '80%',
        marginTop: '2%'
    },
    selectFamily: {
        borderRadius: 4,
        width: '100%',
        backgroundColor:'#FFF',
        border: '1px solid #ced4da'
      },
  }));

export default function SelectGateway({ gateways, handleSelectGateway, selection }){

    const [filter, setFilter] = useState('')
    const [gates, setGates] = useState(gateways)
    const [gatewayGroups, setGatewayGroups] = useState([])
    const classes = useStyles();
    const handleChange = (event) => {
        setFilter(event.target.value)
    }

    useEffect(()=>{
        if(filter !== ''){
            let filtered = _.filter(gateways, (o)=>{return o.brand === filter})
            setGates(filtered)
        } else {
            setGates(gateways)
        }
    },[filter])

    useEffect(()=>{
        setGatewayGroups(_.uniq(_.map(gateways, 'brand')))
    }, [gateways])

    const deviceCard = (device) => {
        let isSelected = (selection === device)
        return (
            <ListItem
                button
                selected={isSelected}
                onClick={()=>{handleSelectGateway(device)}}
                className={classes.listItems , isSelected ? 'itemSelected' : '' }
            >
                <Card className={classes.root}>
                    <CardMedia
                        component="img"
                        className={classes.sensorImage}
                        image={device.image}
                        height='100%'
                    />
                    <Divider className={classes.cardDivider} orientation="vertical"  />
                    <CardContent>
                        <Typography className={classes.deviceName}>{device.name}</Typography>
                        <Typography>{device.company}</Typography>
                        <Typography>{device.model}</Typography>
                    </CardContent>
                </Card>
            </ListItem>
            )
    }

    return (
        <Grid item xs={12}>
        <ModalFormHeader
          header="Select Gateway Model"
          desc="Select the gateway model below to begin the process of adding it into the
          MiSensor Platform."
        />
        <Grid item xs={12}>
            <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={filter}
            onChange={handleChange}
            className={classes.selectFamily}
            >
            {gatewayGroups.map(item =>{
                return (
                    <MenuItem value={item}>{item}</MenuItem>
                )
            })}
            </Select>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 25 }}>
          <Grid>
            <List className={classes.list}>
                {gates.map(item => {
                    return deviceCard(item)
                })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    )
};