import React, { useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  IconButton
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ImageIcon from '@material-ui/icons/Image';
const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  gatewaySelect: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: '100%',
  },
  uploadImage: {
    width: '256px',
    height: '256px',
    background: '#EFEFEF 0% 0% no-repeat padding-box',
    border: '5px dashed #CCCCCC',
    opacity: 1,
    borderRadius: '256px',
  },
  centerElementColors: {
    color: '#9D9D9D',
    fontSize: '50px',
  },
  centerImageColor: {
    color: '#9D9D9D',
    fontSize: '50px',
  },
  centerTextColor: {
    color: '#9D9D9D',
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const EditSensorImage = props => {
  const log = window.log('EditSensorImage');

  const {
    openState,
    handleClose,
    handleSave,
    setImgLocation,
    imgLocation,
    handleRemoveImg,
    setFile,
    setImgFile,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState('sm');
  const classes = useStyles();

  const [upImg, setUpImg] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 1,
    height: 25,
    unit: '%',
    width: 25,
  });
  const [selectingFile, setSelectingFile] = useState(false);

  const [previewUrl, setPreviewUrl] = useState();

  const onSelectFile = e => {
    setSelectingFile(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          log(height, width);
          let widthMaxValue = width > height;
          const minValue = Math.min(height, width);
          const maxValue = Math.max(height, width);
          const auxPercentage = (minValue * 0.75) / maxValue;
          const finalHeight = widthMaxValue
            ? minValue * 0.75
            : maxValue * auxPercentage;
          const finalWidth = !widthMaxValue
            ? minValue * 0.75
            : maxValue * auxPercentage;

          const auxiliarHeight = document.documentElement.clientHeight * 0.7;
          setCrop({
            aspect: 1,
            height: finalHeight,
            unit: 'px',
            width: finalWidth,
            x: (width - finalWidth) / 2,
            y:
              ((height > auxiliarHeight ? auxiliarHeight : height) -
                finalHeight) /
              2,
          });
        };
      };
    }
  };

  const onLoad = useCallback(img => {
    setImgRef(img);
  }, []);

  const makeClientCrop = async crop => {
    if (imgRef && crop.width && crop.height) {
      createCropPreview(imgRef, crop, 'newFile.png');
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          setFile(blob);
          window.URL.revokeObjectURL(previewUrl);
          setPreviewUrl(window.URL.createObjectURL(blob));
        },
        'image/png',
        1,
      );
    });
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleRemoveSelectedFile = () => {
    setSelectingFile(false);
    setUpImg(null);
    setImgRef(null);
    setImgLocation('');
  };

  const handleSubmitImage = () => {
    setUpImg(null);
    setImgRef(null);
    setSelectingFile(false);
    setCrop({ unit: '%', aspect: 1 / 1 });
    log('CLICK!');
    handleSave();
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby='responsive-dialog-title'
    >
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography align='center'>Edit Sensor Pic</Typography>
              {upImg && (
                <Button onClick={handleRemoveSelectedFile}>
                  <HighlightOffIcon />
                </Button>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={{
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
              }}
              justify='center'
            >
              {imgLocation && !selectingFile ? (
                <>
                  <IconButton
                    aria-label='delete'
                    size='small'
                    onClick={handleRemoveImg}
                    style={{ marginRight: 'auto' }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                  <Grid container display='flex' justify='center' spacing={5}>
                    <Grid item>
                      <Button
                        style={{ borderRadius: '50%', padding: 0 }}
                        component='label'
                      >
                        <Box
                          className={classes.uploadImage}
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          flexDirection='column'
                        >
                          <Avatar
                            variant='circle'
                            alt='Img url location'
                            src={imgLocation}
                            className={classes.image}
                          />
                        </Box>
                        <input
                          type='file'
                          style={{ display: 'none' }}
                          onChange={onSelectFile}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div>
                  <ReactCrop
                    circularCrop={true}
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={c => setCrop(c)}
                    onComplete={makeClientCrop}
                    style={{ maxHeight: '70vh' }}
                    onCropChange={(crop, percentCrop) =>
                      setCrop({ crop: percentCrop, unit: '%' })
                    }
                  />
                  {!selectingFile && (
                    <Button
                      style={{ borderRadius: '50%', padding: 0 }}
                      component='label'
                    >
                      <Box
                        className={classes.uploadImage}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                      >
                        <ImageIcon
                          className={classes.centerImageColor}
                        ></ImageIcon>
                        <Box className={classes.centerTextColor}>
                          UPLOAD PHOTO
                        </Box>
                      </Box>
                      <input
                        type='file'
                        style={{ display: 'none' }}
                        onChange={onSelectFile}
                      />
                    </Button>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            style={{
              paddingLeft: theme.spacing(8),
              paddingRight: theme.spacing(8),
              marginBottom: theme.spacing(4),
            }}
          >
            <Grid item xs={6}>
              <Button
                onClick={handleCloseDialog}
                size='large'
                variant='outlined'
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} container justify='flex-end'>
              <Button
                onClick={handleSubmitImage}
                size='large'
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default EditSensorImage;
