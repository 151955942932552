import React from "react";
import { SvgIcon } from "@material-ui/core";

const VocSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
        xmlns="http://www.w3.org/2000/SVGIcon"
        width="224.784"
        height="143.555"
        viewBox="0 0 224 170"
        {...props}
    >
        <g id="Group_1951" data-name="Group 1951" transform="translate(71.963 71.158)">
            <path id="Path_636" data-name="Path 636" d="M245.266,132.66A25.95,25.95,0,0,1,220.215,100a25.892,25.892,0,1,1,25.051,32.657ZM245.2,88.9a17.849,17.849,0,1,0,17.256,22.495h0A17.837,17.837,0,0,0,249.87,89.518,18.007,18.007,0,0,0,245.2,88.9Z" transform="translate(-219.321 -80.831)" fill={filledcolor}/>
        </g>
        <g id="Group_1952" data-name="Group 1952" transform="translate(15.553 110.879)">
            <circle id="Ellipse_31" data-name="Ellipse 31" cx="10.361" cy="10.361" r="10.361" fill={filledcolor}/>
        </g>
        <g id="Group_1953" data-name="Group 1953" transform="translate(126.199 162.121)">
            <circle id="Ellipse_32" data-name="Ellipse 32" cx="10.361" cy="10.361" r="10.361" fill={filledcolor}/>
        </g>
        <g id="Group_1954" data-name="Group 1954" transform="translate(113.244 15.553)">
            <circle id="Ellipse_33" data-name="Ellipse 33" cx="10.361" cy="10.361" r="10.361" fill={filledcolor}/>
        </g>
        <g id="Group_1955" data-name="Group 1955" transform="translate(45.449 100.209)">
            <rect id="Rectangle_946" data-name="Rectangle 946" width="34.592" height="8.058" transform="translate(0 11.93) rotate(-20.174)" fill={filledcolor}/>
        </g>
        <g id="Group_1956" data-name="Group 1956" transform="translate(100.596 45.754)">
            <rect id="Rectangle_947" data-name="Rectangle 947" width="34.065" height="8.06" transform="translate(0 31.986) rotate(-69.882)" fill={filledcolor}/>
        </g>
        <g id="Group_1957" data-name="Group 1957" transform="translate(105.527 114.691)">
            <rect id="Rectangle_948" data-name="Rectangle 948" width="8.06" height="39.659" transform="translate(0 3.363) rotate(-24.659)" fill={filledcolor}/>
        </g>
        <g id="Group_1958" data-name="Group 1958" transform="translate(0 95.332)">
            <path id="Path_637" data-name="Path 637" d="M228.837,138.177a26.1,26.1,0,0,1-6.788-.906,25.733,25.733,0,1,1,6.788.906Zm-.044-43.76a17.843,17.843,0,1,0,17.226,22.49h0a17.849,17.849,0,0,0-17.226-22.49Z" transform="translate(-202.877 -86.355)" fill={outlinecolor}/>
        </g>
        <g id="Group_1959" data-name="Group 1959" transform="translate(97.693)">
            <path id="Path_638" data-name="Path 638" d="M251.154,116.4a26.028,26.028,0,0,1-6.779-.9,25.773,25.773,0,1,1,6.779.9Zm-.07-43.764a17.887,17.887,0,1,0,4.674.621A18.008,18.008,0,0,0,251.084,72.634Z" transform="translate(-225.2 -64.572)" fill={outlinecolor}/>
        </g>
        <g id="Group_1960" data-name="Group 1960" transform="translate(110.654 146.578)">
            <path id="Path_639" data-name="Path 639" d="M254.059,149.875a25.9,25.9,0,1,1,25.016-19.164h0A25.946,25.946,0,0,1,254.059,149.875Zm.013-43.751a17.844,17.844,0,1,0,17.226,22.49h0a17.839,17.839,0,0,0-17.226-22.49Z" transform="translate(-228.162 -98.064)" fill={outlinecolor}/>
        </g>
    </SvgIcon>
  );
};

export default VocSVG;
