import _ from 'lodash';
import React, { Fragment } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Grid,
  Button,
  Divider,
  TextField,
  makeStyles,
} from '@material-ui/core';

import ModalFormHeader from '../../common/ModalComponents/ModalFormHeader';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    height: 55,
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Set Alert Name
 * @param {customRecipients: Array, customRecipientsCallback: ()=>void } props
 */
export default function AlertCustomRecipients(props) {
  const classes = useStyles();
  const { customRecipients = [], customRecipientsCallback } = props;
  /**
   *  Update the attr of the custom recipient
   * @param {value: String, key: String, index: integer}
   */
  const updateItem = (value, key, index) => {
    const clonedCR = _.clone(customRecipients);
    clonedCR[index][key] = value;
    customRecipientsCallback(clonedCR);
  };

  const addCustomRecipForm = () => {
    const clonedCR = _.clone(customRecipients);
    clonedCR.push({
      name: '',
      phone: '',
      email: '',
    });
    customRecipientsCallback(clonedCR);
  };

  const theme = useTheme();

  return (
    <Grid item xs={12} style={{ height: '100%' }}>
      <ModalFormHeader
        header={'Custom Recipients'}
        desc='Add one or multiple custom recipients to receive the alert notification. Maximum 5'
      />
      <Grid container item xs={12} spacing={2} style={{ marginTop: 20 }}>
        {customRecipients.map((item, index) => {
          return (
            <Fragment key={index}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  value={item.name}
                  onChange={event => {
                    updateItem(event.target.value, 'name', index);
                  }}
                  variant='outlined'
                  placeholder='Name'
                  style={{
                    width: '105%',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPhoneNumber
                  autoComplete='off'
                  className={classes.textField}
                  fullWidth
                  variant='outlined'
                  value={item.phone}
                  placeholder='Phone Number'
                  label='Phone Number'
                  defaultCountry={'us'}
                  preferredCountries={['us']}
                  disableAreaCodes={true}
                  countryCodeEditable={false}
                  autoFormat={true}
                  onChange={event => updateItem(event, 'phone', index)}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  style={{
                    width: '105%',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant='outlined'
                  placeholder='Email'
                  value={item.email}
                  onChange={event => {
                    updateItem(event.target.value, 'email', index);
                  }}
                  style={{
                    width: '105%',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider
                  style={{
                    width: '105%',
                  }}
                  variant='fullWidth'
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      {customRecipients.length < 5 && (
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Button
            variant='outlined'
            color='primary'
            size='large'
            onClick={addCustomRecipForm}
          >
            ADD ADDITIONAL RECIPIENTS
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
