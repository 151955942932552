import _ from 'lodash';
import { Edit } from '@material-ui/icons';
import grey from '@material-ui/core/colors/grey';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';

import CardInfoIcons from '../Generic/CardInfoIcons';
import GenericListCard from '../Generic/GenericListCard';
import { DeviceAvatar } from './DeviceCardComponents/DeviceAvatar';

/**
 * Device Card
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * name: String,
 * sensorId: String,
 * locationName: String
 */
export default function GatewayCard(props) {
  const {
    name, // Name of the sensor (Title of the Card)
    wrap,
    avatar,
    resize,
    buttons,
    subtitle,
    subtitle2,
    subtitle3,
    enableZero,
    sensorCard, // if for sensors
    showStatus,
    gatewayCard, // if for gateways
    batteryVolt,
    defaultAvatar,
    trailingItems, // Trailing Items for the CardInfoIcons
    displayDivider,
    handleEditImage,
    lastMessageTime,
    singleAlertCard,
    showAvatar = true,
    singleGatewayCard,
    onClick = () => {},
    showOnlyItems = [],
    singleLocationCard,
    isItemsDict = false,
    deviceData
  } = props;

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles(
    theme,
    sensorCard,
    gatewayCard,
    singleAlertCard,
    singleGatewayCard,
    singleLocationCard,
  );

  return (
    <GenericListCard sensorCard={sensorCard}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          onClick={onClick}
          spacing={3}
          style={{ cursor: 'pointer', marginLeft: 8 }}
          data-testid={`sensor_card_${name}`}
        >
          {showAvatar && showStatus ? (
            <Grid item xs={3} sm={gatewayCard ? 5 : 5} md={2} lg={1}>
              <DeviceAvatar
                avatar={avatar}
                sensorCard={sensorCard}
                showStatus={showStatus}
                batteryVolt={batteryVolt}
                gatewayCard={gatewayCard}
                defaultAvatar={defaultAvatar}
                lastMessageTime={lastMessageTime}
                reportInterval={deviceData.reportingInterval}
              />
              {!singleGatewayCard && !sensorCard && (
                <Grid
                  item
                  xs={12}
                  display='flex'
                  className={classes.editImageButtonContainer}
                  style={{
                    marginTop: -10,
                    backgroundColor: grey,
                    textAlign: 'center',
                  }}
                >
                  <IconButton
                    style={{ color: '#ffffff', backgroundColor: grey[400] }}
                    size='small'
                    className={classes.editImageButton}
                    onClick={handleEditImage}
                  >
                    <Edit></Edit>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ) : showAvatar ? (
            <Grid item xs={4} sm={gatewayCard ? 12 : 5} md={2} lg={1}>
              <DeviceAvatar
                avatar={avatar}
                sensorCard={sensorCard}
                showStatus={showStatus}
                batteryVolt={batteryVolt}
                gatewayCard={gatewayCard}
                defaultAvatar={defaultAvatar}
                lastMessageTime={lastMessageTime}
              />
            </Grid>
          ) : null}
          {/* name and sensors container */}
          <Grid
            item
            container
            xs={9}
            sm={gatewayCard || singleLocationCard ? 12 : 7}
            md={showAvatar ? 10 : 12}
            lg={showAvatar ? 11 : 12}
            style={{
              flexWrap: (gatewayCard || singleLocationCard) && 'nowrap',
              paddingLeft: smallDevice && 0,
            }}
          >
            <Grid
              item
              container
              direction='column'
              justify='center'
              xs={gatewayCard || singleLocationCard ? 12 : 12}
              sm={gatewayCard || singleLocationCard ? 12 : 12}
              md={resize ? 9 : 3}
              className={classes.cardContentTextContainer}
            >
              <Box component='div' textOverflow='ellipsis' overflow='hidden'>
                <Box
                  component='div'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  className={classes.title}
                >
                  {name}
                </Box>
                <Box
                  component='div'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  className={classes.subTitle}
                >
                  {subtitle}
                </Box>
                <Box
                  component='div'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  className={classes.subTitle}
                >
                  {subtitle2}
                </Box>
                <Box
                  component='div'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  className={classes.subTitle}
                >
                  {subtitle3}
                </Box>
              </Box>
            </Grid>
            {smallDevice ? null : (
              <Grid
                item
                container
                xs={gatewayCard || singleLocationCard ? 5 : 12}
                sm={gatewayCard || singleLocationCard ? 5 : 12}
                md={resize ? 3 : 9}
                direction='row'
                className={classes.cardInfoIcons}
              >
                <CardInfoIcons
                  textFontSize={18}
                  singleLocationCard={singleLocationCard}
                  singleAlertCard={singleAlertCard}
                  singleGatewayCard={singleGatewayCard}
                  sensorCard={sensorCard}
                  gatewayCard={gatewayCard}
                  wrapItems={wrap}
                  displayDivider={displayDivider}
                  isDict={isItemsDict}
                  items={trailingItems}
                  showOnlyItems={showOnlyItems}
                  buttons={buttons}
                  enableZero={enableZero}
                />
              </Grid>
            )}
          </Grid>
          {smallDevice ? (
            <Grid item xs={12}>
              <CardInfoIcons
                singleLocationCard={singleLocationCard}
                singleAlertCard={singleAlertCard}
                singleGatewayCard={singleGatewayCard}
                sensorCard={sensorCard}
                gatewayCard={gatewayCard}
                wrapItems={wrap}
                displayDivider={displayDivider}
                isDict={isItemsDict}
                items={trailingItems}
                showOnlyItems={showOnlyItems}
                buttons={buttons}
                enableZero={enableZero}
                isSmall={smallDevice}
                deviceCard={true}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </GenericListCard>
  );
}

const useStyles = makeStyles(
  (
    theme,
    sensorCard,
    gatewayCard,
    singleAlertCard,
    singleGatewayCard,
    singleLocationCard,
  ) => ({
    cardInfoIcons: {
      maxWidth: sensorCard && '72%',
      transform: gatewayCard && 'translateY(-4px)',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        position: (gatewayCard || singleLocationCard) && 'absolute',
        right: gatewayCard ? 15 : singleLocationCard && 43,
        transform: singleAlertCard ? 'translate(85%, -86%)' : '',
        maxWidth: sensorCard && '100%',
      },
      [theme.breakpoints.down('815')]: {
        transform: singleAlertCard && 'translate(80%, -86%)',
      },
      [theme.breakpoints.down('705')]: {
        transform: singleAlertCard && 'translate(74%, -86%)',
      },
      [theme.breakpoints.down('xs')]: {
        position: (gatewayCard || singleLocationCard) && 'absolute',
        transform: singleAlertCard ? 'translate(16%, -86%)' : '',
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'wrap',
    },
    subTitle: {
      fontSize: 12,
      color: theme.palette.type === 'light' ? '#747474' : '#FFF',
      whiteSpace: 'wrap',
    },
    cardContentTextContainer: {
      marginLeft: gatewayCard ? 37 : singleGatewayCard ? 14 : sensorCard && 15,
      marginTop: gatewayCard && 5,
      height: (gatewayCard || singleLocationCard) && 'fit-content',

      [theme.breakpoints.down('md')]: {
        transform: sensorCard ? 'translateX(-64px)' : '',
      },

      [theme.breakpoints.down('sm')]: {
        display: sensorCard && 'grid',
        marginLeft: gatewayCard && 0,
      },
      [theme.breakpoints.down('xs')]: {
        transform: sensorCard && 'translateX(-40px)',
        display: sensorCard && 'grid',
        width: 180,
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('380')]: {
        transform: 'translateX(-5px)',
        display: 'grid',
        width: 145,
        whiteSpace: 'nowrap',
      },
    },
    editImageButton: {
      '& svg': {
        fontSize: 14,
      },
    },
    editImageButtonContainer: {
      [theme.breakpoints.down('md')]: {
        // transform: singleGatewayCard && "translateX(-40px)",
      },
      [theme.breakpoints.down('sm')]: {
        // transform: singleGatewayCard && "translateX(0px)",
      },
    },
  }),
);